::selection {
    background: rgba(#c39f42, .2);
    color: var(--color-noir);
}

::-moz-selection {
    background: rgba(#c39f42, .2);
    color: var(--color-noir);
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6{
    color: var(--color-noir);
    font-family: var(--typo-font-text);
    font-weight: var(--webapp-font-weight-semibold);
    line-height: 1.5;
}


hr{
    @include bloc;
    height: 1px;
    float: left;
    margin: 20px 0 0;
    border: none;
    background: rgba(#000,.25);
}


.c-inherit{
    color: inherit !important;
}
.bg-transparent{
    background-color: transparent !important;
}
.bg-currentColor{
    background-color: currentColor !important;
}

a,
.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullet{
    outline: 0 !important;
    text-decoration: none !important;
    @include box-shadow(none !important);
    @include transition;

    &.noir{
        color: var(--color-noir);

        @include responsive(no-touch){
            &:hover{
                opacity: .5;
            }
        }
    }
}

a[data-fancybox]{
    @include bloc;
    float: left;

    .BC_visuel{
        overflow: hidden;
        background: var(--color-noir);

        img, .js-background{
            @include transition(all .5s ease);
        }

        @include responsive(no-touch){
            &:hover{

                img, .js-background{
                    @include scale(1.1,1.1);
                    @include transition(all 2s ease);
                }
            }
        }
    }
}

#cta-terms-and-conditions-0{
    color: var(--color-noir);
    text-decoration: underline !important;
}

.transition{
    @include transition;
}

.link-absolute{
    @include blocAbsolute(5);
}

.-status-off{
    display: inherit !important;
}
.-status-on{
    display: none !important;
}
.-status-active{
    .-status-off{
        display: none !important;
    }
    .-status-on{
        display: inherit !important;
    }
}

.-has-border{
    border: 1px solid var(--color-beige);
}

[class*="mask-"]{
    @include blocAbsolute(-1);
    pointer-events: none !important;
}

.mask-gradient{
    z-index: 1;
    background: transparent no-repeat left bottom;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='3000' height='2000' viewBox='0 0 3000 2000'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23fff'/%3E%3Cstop offset='0.473' stop-color='%23fff'/%3E%3Cstop offset='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect width='3000' height='2000' opacity='0.51' fill='url(%23linear-gradient)' style='mix-blend-mode: multiply;isolation: isolate'/%3E%3C/svg%3E%0A");
}


svg{
    @include bloc;
    height: auto;
}

.licon-svg,
.licon{
    @include inlinebloc;
    line-height: 1;
    background: transparent no-repeat center center;
    @include transition;

    // Licon 20x20
    &.licon-plus{
        @include licon(--licon-plus, 20px);
    }
    &.licon-minus{
        @include licon(--licon-minus, 20px);
    }
    &.licon-caret{
        @include licon(--licon-caret, 20px);
    }
    &.licon-close{
        @include licon(--licon-close, 20px);
    }
    &.licon-reset{
        @include licon(--licon-reset, 20px);
    }

    &.licon-pencil,
    &.licon-edit{
    }
    &.licon-star-full{
    }
    &.licon-star{
    }

    // Licon 32x32
    &.licon-search{
        @include licon(--licon-search);
    }
    &.licon-bag{
        @include licon(--licon-bag);
    }
    &.licon-bag-full{
        @include licon(--licon-bag-full);
    }
    &.licon-bag-add{
        @include licon(--licon-bag-add, 32px !important);
    }
    &.licon-account{
        @include licon(--licon-account);
    }
    &.licon-account-full{
        @include licon(--licon-account-full);
    }
    &.licon-prev{
        @include licon(--licon-prev);
    }
    &.licon-next{
        @include licon(--licon-next);
    }
    &.licon-check{
        @include licon(--licon-check);
    }
    &.licon-chat{
        @include licon(--licon-chat);
    }
    &.licon-heart,
    &.licon-whishlist,
    &.licon-whish{
        @include licon(--licon-heart);
    }
    &.licon-heart-full,
    &.licon-whishlist-full,
    &.licon-whish-full{
        @include licon(--licon-heart-full);
    }
    &.licon-heart-remove,
    &.licon-whishlist-remove,
    &.licon-whish-remove{
        @include licon(--licon-heart-remove);
    }
    &.licon-marker,
    &.licon-map{
        @include licon(--licon-marker);
    }
    &.licon-phone{
        @include licon(--licon-phone);
    }
    &.licon-enveloppe,
    &.licon-email{
        @include licon(--licon-email);
    }

    &.licon-patere{
        @include licon(--licon-patere);
    }
    &.licon-corde{
        @include licon(--licon-corde);
    }
    &.licon-douille{
        @include licon(--licon-douille);
    }
    &.licon-ampoule{
        @include licon(--licon-ampoule);
    }

    &.licon-projet{
        @include licon(--licon-projet, 32px, currentColor);
    }

    &.licon-reassurance-1{
        width: 60px;
        height: 60px;
        background-color: var(--color-noir);
        -webkit-mask: var(--licon-reassurances-1);
        mask: var(--licon-reassurances-1);
    }
    &.licon-reassurance-2{
        width: 60px;
        height: 60px;
        background-color: var(--color-noir);
        -webkit-mask: var(--licon-reassurances-2);
        mask: var(--licon-reassurances-2);
    }
    &.licon-reassurance-3{
        width: 60px;
        height: 60px;
        background-color: var(--color-noir);
        -webkit-mask: var(--licon-reassurances-3);
        mask: var(--licon-reassurances-3);
    }
    &.licon-reassurance-4{
        width: 60px;
        height: 60px;
        background-color: var(--color-noir);
        -webkit-mask: var(--licon-reassurances-4);
        mask: var(--licon-reassurances-4);
    }

    &.licon-moon{
        width: 32px !important;
        height: 32px !important;
        background-color: var(--color-noir);
        -webkit-mask: var(--licon-moon);
        mask: var(--licon-moon);
    }
    &.licon-sun{
        width: 32px !important;
        height: 32px !important;
        background-color: var(--color-noir);
        -webkit-mask: var(--licon-sun);
        mask: var(--licon-sun);
    }
    &.licon-paint{
        width: 32px !important;
        height: 32px !important;
        background-color: var(--color-noir);
        -webkit-mask: var(--licon-paint);
        mask: var(--licon-paint);
    }

    &.blanc{
        background-color: var(--color-blanc);
    }
    &.main{
        background-color: var(--color-main);
    }
}

.ornement{
    z-index: 0;
    display: inline-block;
    pointer-events: none !important;

    &.ornement-logo{
        width: 384px;
        height: 384px;
        margin: -192px 0 0 -192px;
        background: transparent var(--ornement-lem-noir) no-repeat center center;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation: rotate 10s linear infinite;
        animation: rotate 10s linear infinite;

        [data-theme="dark"] &{
            background-image: var(--ornement-lem-blanc);
        }
    }

    &.ornement-cacahuete1,
    &.ornement-cacahuete2,
    &.ornement-cacahuete3,
    &.ornement-cacahuete4{
        width: 500px;
        height: 500px;
        @include translate(-50%,-50%);
        background-color: var(--color-beige-light);

        @include responsive(mobile){
            -webkit-transform: scale(.6) translate(-50%,-50%);
            transform: scale(.6) translate(-50%,-50%);
        }
    }
    &.ornement-cacahuete1{
        -webkit-mask: var(--ornement-cacahuete1);
        mask: var(--ornement-cacahuete1);
    }
    &.ornement-cacahuete2{
        -webkit-mask: var(--ornement-cacahuete2);
        mask: var(--ornement-cacahuete2);
    }
    &.ornement-cacahuete3{
        -webkit-mask: var(--ornement-cacahuete3);
        mask: var(--ornement-cacahuete3);
    }
    &.ornement-cacahuete4{
        -webkit-mask: var(--ornement-cacahuete4);
        mask: var(--ornement-cacahuete4);
    }
}

.embed-responsive{


    &.embed-responsive-bandeau-home{
        @include embed-calc(1090,560);

        @include responsive(at-sm){
            @include embed-calc(1089,560);
        }
    }

    &.embed-responsive-bandeau-page{
        @include embed-calc(1089,560);

        @include responsive(at-sm){
            @include embed-calc(1089,560);
        }
    }

    &.embed-responsive-product{
        @include embed-calc(267,218);
    }

    &.embed-responsive-showroom-small{
        @include embed-calc(267,178);
    }
    &.embed-responsive-showroom-large{
        @include embed-calc(860,575);
    }

    &.embed-responsive-465x600{
        @include embed-calc(465,600);
    }
    &.embed-responsive-267x209{
        @include embed-calc(267,209);
    }
}


.tag,
.BC_meta.tag{
    @include inlinebloc;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    padding: 0 10px;
    background: var(--color-main);
    color: var(--color-blanc);
    @include font-size(1);
}


.btn,
.btn-pill,
.btn-inline{
    position: relative;
    display: inline-block;
    width: auto;
    min-width: 1px;
    float: none;
    vertical-align: middle;
    margin: 0;
    @include transition;

    .licon, .text, .textbefore, .textafter, .ghosttext{
        @include inlinebloc;
        line-height: 1;
        @include transition;
    }

    .text{
        z-index: 1;
        width: auto !important;
        color: inherit;
        font-size: inherit;
    }
}

.btn,
.page-content.page-cms .btn,
.btn.btn-primary{
    height: 42px !important;
    line-height: 40px !important;
    padding: 0 21px;
    border-radius: 21px;
    border: 1px solid var(--color-noir);
    background: var(--color-noir);
    cursor: pointer !important;
    overflow: hidden;
    color: var(--color-blanc);
    @include font-size(1.4);
    font-family: var(--typo-font-text);
    font-weight: var(--webapp-font-weight-semibold);
    text-align: center;
    text-transform: inherit;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    @include transition;

    .text{
        // top: -2px;
    }

    .hover-text{
        display: none;
    }

    .ghosttext{
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 100%;
        padding: 0 21px;
        transform: translate(0, 200%);
        text-align: center;
        @include transition;
    }

    &.btn-cart{
        padding: 0 !important;
        border-color: var(--color-main);
        background: var(--color-main);
        color: var(--color-blanc);

        .licon{
            position: absolute;
            top: 50%;
            left: 10px;
            background-color: var(--color-blanc);
            -webkit-transform: translate(0, -50%) !important;
            transform: translate(0, -50%) !important;
        }

        .text,
        .ghosttext{
            padding: 0 20px 0 45px;
        }
    }
    &.btn-main{
        border-color: var(--color-main);
        background: var(--color-main);
        color: var(--color-blanc);

        .licon{
            background-color: var(--color-blanc);
        }
    }

    &.-ghost{
        background: transparent;
    }

    &.-small{
        height: 25px;
        line-height: 23px;
        padding: 0 20px;
    }

    @include hover{
        opacity: .85;

        .licon{
            @include translate(-5px, 0);
        }

        &.-has-ghosttext{

            .text{
                @include translate(0,-50px);
            }
            .ghosttext{
                @include translate(0,-50%);
            }
        }
    }
}

.btn.button.button-tntofficiel-small,
.btn.button.button-tntofficiel-medium{
    padding: 0 21px;
    border-radius: 21px;
    border: 1px solid #FF6600;
    background-color: #FF6600;
    cursor: pointer;
    color: var(--color-blanc);
    font-size: 14px;
    font-family: var(--typo-font-text);
    font-weight: var(--webapp-font-weight-semibold);

    span{
        display: inline-block;
        margin: 0;
        padding: 0;
        float: none;
        vertical-align: middle;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        text-shadow: none;
        cursor: pointer;
        border-radius: 0;
        pointer-events: none;
    }
}

.btn-pill{
    cursor: pointer !important;
    overflow: hidden;
    @include font-size(1.4);
    font-family: var(--typo-font-text);
    font-weight: var(--webapp-font-weight-semibold);
    @include letter-spacing(20);
    text-align: center;
    @include transition;

    .pill{
        @include inlinebloc;
        width: 38px;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        background-color: var(--color-noir);

        .licon{
            background-color: var(--color-blanc);
        }
    }
    .text{
        @include inlinebloc;
        line-height: 1.25;
        padding: 0 19px;
        border-radius: 19px;
        color: var(--color-blanc);
    }

    &.-block{

        .text{
            display: block;
            margin: 5px 0 0;
        }
    }

    &.-invert{

        .pill{
            background-color: var(--color-blanc);

            .licon{
                background-color: var(--color-noir);
            }
        }
        .text{
            color: var(--color-noir);
        }
    }

    @include hover{
        opacity: .85;
    }
}

.btn-inline{
    color: currentColor;
    @include font-size(1.5);
    font-family: var(--typo-font-text);
    font-weight: var(--webapp-font-weight-bold);
    text-align: center;

    @include hover{
        color: var(--color-main-active);
    }
}

.btn-scroll{
    @include bloc;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
    @include transition;

    &::before{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        border-radius: 50%;
        border: 2px solid currentColor;
        @include transition;
    }

    .licon-svg{
        @include translate(0,0);
    }

    @include responsive(no-touch){
        &:hover{

            &::before{
                top: -7px;
                bottom: -7px;
                left: -7px;
                right: -7px;
            }

            .licon-svg{
                @include translate(0,3px);
            }
        }
    }
}


.btn-filter{
    @include inlinebloc;
    height: 80px;
    line-height: 80px;
    padding: 0 20px;
    border-radius: 10px;
    border: 1px solid var(--color-beige);
    color: var(--color-main);
    @include font-size(2);
    font-weight: var(--webapp-font-weight-medium);

    .icon{
        @include inlinebloc;
    }
    .text{
        @include inlinebloc;
        max-width: 80px;
        line-height: 1;
    }

    @include responsive(no-touch){
        &:hover{

        }
    }
    &.is-active{
        color: var(--color-blanc);
        background: var(--color-main);
    }
}


.btn-close{
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
    padding: 0;
    background: transparent !important;
    color: var(--color-blanc) !important;
    font-size: var(--typo-size-text);
    font-family: var(--typo-font-text);
    text-align: center;
    @include transition;

    .fa{
        font-size: 18px;
    }
}


.btn svg,
.btn-inline svg,
.btn-circle svg,
.btn-pill svg,
.btn-scroll svg,
.btn-filter svg,
.btn-close svg{
    @include inlinebloc;
    width: 32px;
    height: inherit;
}


.img-responsive{
    @include bloc;
    max-width: 100%;
    height: auto;
}
.-img-same-height{

    .img-responsive{
        width: auto;
        max-width: inherit;
        height: inherit;
    }
}


.js-background{
    @include blocAbsolute;
    background: var(--color-blanc) no-repeat center center;
    @include background-size(cover !important);
    color: var(--color-blanc);

    &.background-mask{

        &::before{
            content: "";
            @include blocAbsolute(1);
        }
    }
}

.embed-responsive{

    &.-size-contain .js-background{
        @include background-size(contain !important);
    }
    &.-size-100-auto .js-background{
        @include background-size(100% auto !important);
    }
    &.-size-auto-100 .js-background{
        @include background-size(auto 100% !important);
    }
}


@include keyframes(rotate){
    from{
        @include rotate(0deg);
    }
    to{
        @include rotate(360deg);
    }
}