// =============================================================================
// Payment Cards Secure
// =============================================================================
.bt-paymentcards{
    @include bloc;
    margin: 0 0 10px;

    @include responsive(at-sm){
        margin: 0;
    }

    .text{
        @include bloc;
    }

    .paymentcard{
        display: inline-block;
        width: 50px;
        height: 34px;
        float: none;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        background: transparent no-repeat center center;
        @include background-size(100% auto);

        &.paymentcard-cb{
            background-image: url('../images/payment/payment-card-CB.svg');
        }
        &.paymentcard-visa{
            background-image: url('../images/payment/payment-card-visa.svg');
        }
        &.paymentcard-mastercard{
            background-image: url('../images/payment/payment-card-mastercard.svg');
        }
        &.paymentcard-american-express{
            background-image: url('../images/payment/payment-card-american-express.svg');
        }
        &.paymentcard-apple-pay{
            background-image: url('../images/payment/payment-card-apple-pay.svg');
        }
        &.paymentcard-google-pay{
            background-image: url('../images/payment/payment-card-google-pay.svg');
        }
        &.paymentcard-sepa{
            background-image: url('../images/payment/payment-card-sepa.svg');
        }
        &.paymentcard-sofort{
            background-image: url('../images/payment/payment-card-sofort.svg');
        }
        &.paymentcard-giropay{
            background-image: url('../images/payment/payment-card-giropay.svg');
        }
    }
}


body #checkout-payment-step{

    #payment-form[action*="module/sogecommerce"]{

        .custom-radio{
            display: none;
        }
    }
}
