.header-nav #prestablog_displayslider{
    display: none !important;
    visibility: hidden !important;
}



.blog-listing{
    @include bloc;

}




.blog-single{
    @include bloc;

    .blog-header{
        @include bloc;

    }

    .blog-container{
        @include bloc;

    }

    // Social
    .prestablogExtra{
        @include bloc;

        .prestablogtitle{
            @include bloc;
            margin: 0 0 15px;
            color: var(--color-noir);
            @include font-size(3.2);
            font-family: var(--typo-font-text);
            font-weight: normal;
            line-height: 1.2;

            @include responsive(mobile-tablet){
                @include font-size(1.8);
            }
        }

        .soc-buttons{
            @include bloc;
            margin: 0;
            padding: 0;

            li{
                position: relative;
                display: inline-block;
                width: auto !important;
                line-height: 1;
                margin: 0 10px 10px 0;
                padding: 0;

                a{
                    @include bloc;
                    width: 29px;
                    height: 29px;
                    line-height: 29px;
                    margin: 0;
                    padding: 0;
                    border-radius: 50%;
                    background: var(--color-noir) !important;
                    color: var(--color-blanc) !important;
                    text-align: center;
                    @include transition;

                    .soc-icon{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        margin: -7px 0 0 -7px;
                        padding: 0;

                        svg{
                            position: relative;
                            top: 0;
                            left: 0;
                            display: block;
                            width: 100%;
                            height: auto;
                        }
                    }

                    .soc-text{
                        display: none;
                    }
                }

                &:hover{

                    a{
                        opacity: .5;
                    }
                }
            }
        }
    }
}

