body#category{


    .bt-section{
        padding-top: 0;

        > .limiter > .limiter-inner > .row{

            > .col-md-3{
                display: none;
            }

            > .col-md-9{
                -webkit-box-flex: 0 !important;
                -ms-flex: 0 0 100% !important;
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }
    }


}



#js-product-list-header{
    @include bloc;
    float: left;
    padding: 40px 0;
}



.product-list-subcategories{
    @include bloc;
    float: left;
    padding: 0 0 20px 0;


    .col-custom{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        @include responsive(responsive){
            padding: 0 5px;
        }
        @include responsive(at-sm){
            -webkit-box-flex: 0;
            -ms-flex: 0 0 160px;
            flex: 0 0 160px;
            max-width: 160px;
        }
    }


    .BC_item{
        padding: 20px 0;
        @include transition;

        .visuel{
            @include bloc;
        }

        .title{
            @include bloc;
            padding: 0 20px;
        }

        .triangle{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 15px 15px;
            border-color: transparent transparent var(--color-blanc) transparent;
            @include transition;
        }

        &:hover{

            @include responsive(at-md){
                @include scale(1.1,1.1);
            }

            .triangle{
                border-width: 0 0 15px 15px;
            }
        }
    }
}



#js-product-list-filters{
    @include bloc;
    float: left;
}



// =============================================================================
// CATEGORIES Header
// =============================================================================
.header-category{


    .header-category-visuel{
        @include bloc;
    }

    .header-category-title,
    .header-category-content{
        @include bloc;
    }

    .header-category-title{

    }

    .header-category-content{
        margin: 25px 0 0;
    }
}



// =============================================================================
// CATEGORIES Filter 
// =============================================================================
.bt-categories{
    @include bloc;
    margin: 0 0 40px;
    padding: 15px;
    border: 1px solid $system-gris;

    display: none !important;
    visibility: hidden !important;

    .bt-categories-title{
        position: relative;
        display: inline-block !important;
        width: auto !important;
        float: left;
        margin: -30px 0 0;
        padding: 0 15px;
        background: var(--color-blanc);
        color: var(--color-main);
        @include font-size(1.8);
        font-family: var(--typo-font-text);
        @include letter-spacing(100);
        text-align: left;
        text-transform: capitalize;

        @include responsive(tablet){
            margin-left: -10px;
            padding: 0 10px;
            white-space: nowrap;
        }
    }

    @include responsive(mobile){
        display: none;
    }

    li{
        list-style: none;
        @include bloc;
    }

    .category-top-menu{
        @include bloc;
        margin: 0 0 0 -16px;
        padding: 0;

        > li > a{
            font-family: var(--typo-font-text);
            @include letter-spacing(100);
        }

        > li{
            list-style: none;
            @include bloc;
            margin: 0 0 15px;

            .navbar-toggler{
                position: absolute;
                top: 0;
                left: -26px;
                display: inline-block;
                width: 20px;
                height: 20px;
                background: var(--color-blanc);
                border-radius: 50%;
                border: 1px solid $system-gris;
                opacity: 1;
                text-align: center;

                @include responsive(tablet-xs){
                    top: -3px;
                }

                .licon{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -10px 0 0 -10px;
                }

                .add{
                    display: none;
                }
                .remove{
                    display: block;
                }

                &.collapsed{

                    .add{
                        display: block;
                    }
                    .remove{
                        display: none;
                    }
                }
            }

            a{
                @include bloc;
                color: var(--color-main);
                @include font-size(1.5);
                font-family: var(--typo-font-text);
                @include letter-spacing(80);
                line-height: 1.2;
                text-align: left;
                @include transition;

                &:hover{
                    color: var(--color-main);
                }
            }
        }
    }

    .category-sub-menu{
        @include bloc;
        margin: 0;
        padding: 0 0 0 15px;

        li{
            list-style: none;
            @include bloc;

            a{
                color: var(--color-noir);
                font-family: var(--typo-font-text);

                &:hover{
                    color: var(--color-main);
                }
            }

            ul{
                border: none;

                li {

                    a{
                        position: relative;
                        padding-left: 25px;

                        &::before{
                            content: "";
                            position: absolute;
                            top: 6px;
                            left: 0;
                            display: inline-block;
                            width: 9px;
                            height: 8px;
                            margin: 0 15px 0 0;
                        }
                    }

                }
            }
        }
    }
}



// =============================================================================
// CATEGORIES active Filters 
// =============================================================================
#js-active-search-filters{
    @include bloc;
    margin: 0 0 15px;
    text-align: left;

    .active-filter-title{
        position: relative;
        display: block;
        width: 100%;
        margin: 0 0 15px;
    }

    ul{
        @include bloc;
        vertical-align: middle;
        margin: 0;
        padding: 0;

        li{
            list-style: none;
            display: inline-block;
            width: auto;
            float: left;
            margin: 0 4px 4px 0;

            a{
                @include bloc;
                margin: 0;
                padding: 5px 10px;
                border-radius: var(--webapp-border-radius);
                background: var(--color-noir);
                color: var(--color-blanc);
                @include font-size(1.4);
                text-shadow: none;
                font-weight: inherit;
                line-height: inherit;
                opacity: 1;

                &:hover{
                    background: var(--color-main);
                }

                .fa{
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    text-shadow: none;
                    opacity: inherit;
                    margin: 0 0 0 5px;
                }
            }
        }
    }
}


// =============================================================================
// Category : Listing sort order
// =============================================================================
.products-selection{
    @include bloc;

    .products-selection-item{
        @include inlinebloc;

        &:last-child{
            margin-right: 0;
        }

        p{
            margin: 0;
        }
    }
}
