body{

    // =============================================================================
    // Global
    // =============================================================================
    .nxtal-productlist-icon{
        z-index: 5;

        span{
            @include transition;
        }
    }

    .nxtal-wishlist-cart .card-block,
    .nxtal-offer-card .card-block{
        padding: 0;
    }

    // =============================================================================
    // CTA
    // =============================================================================
    .bt-cta.wishlist{

        .nxtal-multiwishlist_selector{
            @include bloc;
            margin: 0;
            padding: 0;
        }

        .dropdown-menu{
            margin-top: 10px;
        }
    }

    .nxtal-wishlist-selector.dropdown-menu,
    .nxtal-wishlist-selector .dropdown-menu{
        left: inherit;
        right: -20px;
    }

    // =============================================================================
    // Dropdown add to list / Create
    // =============================================================================
    .nxtal-wishlist-product-selector-wrapper{
        z-index: 5;
        position: absolute;
        top: 10px;
        right: 10px;
        display: inline-block;
        width: auto;
        height: auto;
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;

        .nxtal-wishlist-dropdowntrigger{
            position: relative;
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50%;
            border: 1px solid var(--color-beige-light);
            background: var(--color-beige-light);

            .licon{
                background: var(--color-gris);
            }

            &.active{
                border-color: var(--color-beige);
                background: var(--color-blanc);

                .licon{
                    -webkit-mask: var(--licon-heart-full);
                    mask: var(--licon-heart-full);
                    background: var(--color-main);
                }
            }

        }

        /*.nxtal-wishlist-add-dropdown{
            width: 190px;
            margin: 0 0 0 -160px;
            padding: 0;

            li{
                @include bloc;
                margin: 0;
                padding: 0;
            }

            .dropdown-item{
                @include bloc;
                margin: 0;
                padding: 5px 10px;
                @include transition;
            }
        }*/
    }

    &#product{
        
        .product-single-detail{

            .nxtal-wishlist-product-selector-wrapper{
                top: 0;
                right: 0;
            }
        }
    }


    // =============================================================================
    // Page Catégorie
    // =============================================================================


    // =============================================================================
    // Page Produit
    // =============================================================================



    // =============================================================================
    // Page listes d'envies
    // =============================================================================
    .nxtal-wishlist-lists{
        @include bloc;
    }

    .nxtal-wishlist-form{
        @include bloc;
    }


    .nxtal-product-list .has-discount{

        .discount{
            position: absolute;
            top: 0;
            right: 0;
        }
    }


    .nxtal-wishlist-overview.home{
        @include bloc;

        .nxtal-product-list{
            @include bloc;
            padding: 60px 0 0;

            @include responsive(mobile){
                padding: 85px 0 0;
            }
        }

        li{
            @include bloc;
            height: 60px;
            padding: 10px 0;
            border-bottom: 1px solid rgba(0,0,0,.16);

            @include responsive(mobile){
                height: 85px;
            }

            &.list-default{
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .actions-wishlist .licon{
            margin: 0 !important;
        }

        .nxtal-check_box{
            display: inline-block;
            min-width: 20px;
            float: none;
            vertical-align: middle;
            margin: 0 10px 0 0;

            &.remove{

                @include responsive(mobile){
                    position: absolute;
                    top: -40px;
                    right: 0;
                }
            }
        }

        .product-line-actions{

            @include responsive(mobile){
                margin: -15px 0 0;
                float: left !important;
            }
        }
    }


    // =============================================================================
    // Page détail listes d'envies
    // =============================================================================
    .nxtal-wishlist-overview{
        @include bloc;
        margin: 0;
        padding: 0;
    }

    .nxtal-wishlist-cart,
    .nxtal-wishlist-cart.card{
        @include bloc;
        margin: 0;
        padding: 0;
        border-radius: 0 !important;
        border: none !important;
        background: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .nxtal-product-list{
        @include bloc;

        .nxtal-product{
            @include bloc;
        }

        .nxtal-product-remove{
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            width: auto;
        }

        .product-line-grid-body{
            min-height: 1px;
            margin: 0;
        }

        .product-line-grid-right{
            margin-top: 5px;
        }

        .product-line-info.product-price{


            .product-discount{
                @include bloc;
                float: left;

                .regular-price,
                .discount{
                    position: relative;
                    display: inline-block;
                    width: auto;
                    float: left;
                }
            }
            .current-price{
                @include bloc;
                float: left;
            }
        }

        .add{

            @include responsive(mobile){
                float: left !important;
            }
        }
    }


    .nxtal-share-list{
        @include bloc;
        margin: 15px 0;

        ul{

        }

        li{
            margin: 0;
            padding: 0;
        }

        @include responsive(mobile){

        }

        .share-item{
            @include bloc;
            color: var(--color-noir);

            &:hover{
                opacity: .5;
            }

            .share-wishlist{
                @include bloc;
                height: auto;
                line-height: 1.25;
                margin: 0;
                padding: 0;

                &::before{
                    display: none;
                }
            }
        }
    }
}
