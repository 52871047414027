/* *** Listing *** */
.featured-products{
    overflow: hidden;

    .limiter-max{

        #cart &{
            margin-top: 60px;
            border-top: 1px solid var(--color-gris);
            background: transparent !important;

            .limiter{
                @include responsive(mobile){
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
    }
}


body#product{

    .product-single{

        .featured-products.product-accessories{

            .limiter > div{
                border-bottom: 1px solid var(--color-beige-dark);
            }
        }
        .featured-products.crossselling{

            .limiter > div{
                border-top: 1px solid var(--color-beige-dark);
            }
        }
    }
}