.lucyan-table-container{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;

    @include responsive(tablet-xs){
        margin-top: 80px !important;
    }
    @include responsive(mobile-tablet-xs){
        margin: 25px -15px;
        padding: 0;
    }

    .lucyan-table{

        @include responsive(tablet-xs){
            padding-top: 80px !important;
        }
        @include responsive(mobile-tablet-xs){
            padding-left: 120px;
            overflow-x: scroll;
            overflow-y: visible;

            &::before{
                content: "";
                @include blocAbsolute(0);
                width: 120px;
                @include box-shadow(0 0 15px 0px rgba(0,0,0,.16));
            }
        }

        table{
            width: 100%;
            overflow: auto;
            border-collapse: collapse;
            border-spacing: 0;
            background: var(--color-beige);

            @include responsive(mobile-tablet-xs){
                min-width: 400px;
            }

            thead{

                tr{
                    background: var(--color-beige);
                }

                th{
                    width: inherit !important;
                    padding: 15px 10px;
                    vertical-align: middle;
                    color: var(--color-noir);
                    outline: none !important;
                    @include box-shadow(none !important);

                    &:first-child{
                        min-width: 120px !important;
                    }

                    svg{
                        position: relative;
                        top: 10px;
                        float: right;
                    }
                }
            }

            tbody{

                tr{
                    background: var(--color-beige);
                }


                td {
                    padding: 15px 10px;
                    vertical-align: middle;

                    @include responsive(mobile-tablet-xs){
                        height: 62px;
                        line-height: 62px;
                        padding: 0 10px;
                    }

                    > *{

                        @include responsive(mobile-tablet-xs){
                            position: relative;
                            display: inline-block;
                            width: 100%;
                            float: none;
                            vertical-align: middle;
                            line-height: 1.25;
                        }
                    }
                }
            }

            thead th:first-child, tbody td:first-child{
                text-align: left;

                @include responsive(mobile-tablet-xs){
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    white-space: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    width: 120px !important;
                    background-color: inherit;
                }
            }
        } 
    }
}
