.plus-minus-container{
    @include bloc;

    .plus-minus{
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        height: 100%;
        width: 24px;

        .plus, .minus{
            display: block;
            height: 50%;
            font-size: 24px;
            line-height: 24px;
            text-align: center;
            @include transition;
        }
        .plus{
            // border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: var(--color-main);
            color: var(--color-blanc);

            @include responsive(no-touch){
                &:hover{
                    background: var(--color-main-active);
                }
            }
        }
        .minus{
            // border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background: #D0D0D0;
            color: var(--color-blanc);

            @include responsive(no-touch){
                &:hover{
                    background: var(--color-gris);
                }
            }
        }
    }
}


form{


    br{
        display: none !important;
    }

    address, .address{
        
        br{
            display: inherit !important;
        }
        a{
            color: var(--color-noir);
        }
    }

    p{
        margin: 0;
    }

    input,
    textarea{
        @include bloc;
        margin: 0;
        padding: var(--webapp-input-padding-y) var(--webapp-input-padding-x);
        border: none;
        border-radius: var(--webapp-input-radius);
        border: var(--webapp-input-border);
        background: var(--webapp-input-background);
        color: var(--color-noir);
        text-indent: 0;
        outline: 0 !important;
        font-size: var(--webapp-input-font-size);
        -webkit-box-shadow: none;
        box-shadow: none;
        @include transition;

        .bg-main &,
        .bg-noir &{
            border-color: var(--color-blanc);
            color: var(--color-blanc);
        }

        &.wpcf7-not-valid{
            border-color: crimson !important;
        }
    }

    input{
        height: var(--webapp-input-height);
        line-height: var(--webapp-input-height);

        &[type="text"],
        &[type="password"],
        &[type="number"]{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        &[type="checkbox"],
        &[type="radio"]{
            display: inline-block;
            width: auto;
        }

        &[type="hidden"]{

        }
    }
    textarea {
        height: 150px;
        line-height: 1.25;
        padding: var(--webapp-textarea-padding-y) var(--webapp-textarea-padding-x);
        resize: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    select{
        @include bloc;
        height: var(--webapp-input-height);
        line-height: var(--webapp-input-height);
        padding: 0 40px 0 20px !important;
        border-radius: var(--webapp-input-radius);
        border: var(--webapp-input-border);
        background: var(--color-blanc) url('../images/licon-select.svg') right 15px center no-repeat !important;
        color: var(--color-noir) !important;
        font-size: var(--webapp-p-font-size);
        -webkit-appearance: none;
        appearance: none;
        outline: 0 !important;
    }

    button{
        -webkit-appearance: none;
        appearance: none;
    }

    .form-control-label.required::after,
    label.required_label:after{
        content: " *" !important;
        position: relative;
        display: inline !important;
        color: crimson !important;
        font-size: 14px !important;
        line-height: 1 !important;
    }

    .obgl{
        color: crimson;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    fieldset legend{
        position: relative;
        display: block;
        width: 100% !important;
        font-size: initial !important;
        background: transparent !important;
    }


    .form-group.input_box,
    .label-container,
    .form-group{
        @include bloc;
        float: left;
        margin-bottom: 15px !important;

        &.fileupload_box{
            margin-bottom: 0 !important;
        }

        strong,
        > label{
            @include bloc;
            margin: 3px 0 7px;
            @include font-size(1.4);
            @include transition;
        }

        .form-control-comment{
            position: absolute;
            top: 0;
            right: 0;
            opacity: .5;
        }
        .form-control-content .form-control-comment{
            position: relative;
            display: block;
            margin: 5px 0 0;
        }

        @include responsive(at-sm){
            &.-side{
                padding-left: 195px;

                strong{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    display: inline-block;
                    width: auto;
                    margin: 0;
                    @include translate(0,-50%);
                }
            }
        }
    }


    .input-group.js-parent-focus{
        @include bloc;

        > .input-group-btn{
            z-index: 3;
            position: absolute;
            top: 50%;
            right: 0;
            display: inline-block;
            width: auto;
            @include translate(0,-50%);
        }
    }
}

.js-select{

}
.js-select2{
    @include bloc;
    padding: 44px 0 0 0;
    @include transition;

    .trigger{
        z-index: 5;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 44px;
        line-height: 44px;
        @include transition;

        .icon{
            position: absolute;
            top: 22px;
            right: 22px;
            display: inline-block;
            width: 44px;
            height: 44px;
            line-height: 44px;
            margin: -22px -22px 0 0;
            text-align: center;
            background: transparent url('../images/licon-select2.svg') no-repeat center center;
            // @include rotate(0deg);
            @include transition;
            cursor: pointer;
        }
    }

    ul{
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        max-height: 44px;
        margin: 0;
        padding: 0;
        border-radius: 22px;
        border: 1.5px solid rgba(10, 8, 59, 0.1);
        overflow: hidden;
        @include transition;
    }

    li{
        list-style: none;
        @include bloc;
        margin: 0;
        padding: 0 18px;
        background: var(--color-blanc);
        @include transition;

        .option{
            @include bloc;
            height: 44px;
            line-height: 43px;
            padding: 0 5px;
            border-top: 1px solid var(--color-beige);
            color: var(--color-noir);
        }

        &:first-child{
            border-bottom: none;
        }

        @include responsive(no-touch){
            &:hover{
                background: var(--color-noir);

                .option{
                    color: var(--color-blanc);
                    border-top: none;
                }
            }
        }
        &.selected{
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;

            .option{
                border-top: none;
            }
        }
    }

    &.open{
        z-index: 25;

        .trigger{
            width: 40px;

            .icon{
                @include rotate(180deg);
            }
        }

        ul{
            max-height: 240px;
            overflow: auto;
            @include box-shadow(0px 6px 5px rgba(0,0,0,.12));
        }

        li{

            &.selected{
                position: relative;
                background: var(--color-noir);

                .option{
                    color: var(--color-blanc);
                }
            }
        }
    }
}

.input-group{

    &.bootstrap-filestyle{
        @include bloc;
        float: left;

        .input-group-btn{
            z-index: 3;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

// =========================================================================
// Checkbox
// =========================================================================
.custom-radio-label{
    @include inlinebloc;
    margin: 0 20px 0 0;
    padding-left: 30px;
}

.custom-radio,
.custom-checkbox{
    position: absolute;
    top: 50% !important;
    left: 0;
    display: inline-block;
    width: auto;
    margin: 0 !important;
    @include translate(0,-50%);

    input{
        position: absolute !important;
        opacity: 0 !important;
    }
    .material-icons{
        display: none !important;
        visibility: hidden !important;
    }

    span{
        display: inline-block;
        width: 23px;
        height: 23px;
        border-radius: var(--webapp-border-radius);
        border: 1px solid var(--color-gris-dark);
        background: transparent;
        @include transition;

        &:after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: -7px 0 0 -4px;
            border-radius: var(--webapp-border-radius);
            background: var(--color-noir);
            opacity: .5;
        }

        &.texture{
            background: transparent no-repeat center center;

            &::after{
                display: none;
            }
        }
    }
    @include hover{
        span{
            background: var(--color-gris-dark);

            &.texture{
                @include box-shadow(0 0 0 3px var(--color-blanc));
            }
        }
    }
    input:checked + span{
        background: var(--color-blanc);

        &::after{
            opacity: 1;
        }

        &.texture{
            @include box-shadow(0 0 0 3px var(--color-beige));
        }
    }
}

.custom-checkbox{

    span{

        &.color{
            border-radius: 50% !important;
            border: none;

            &[style*="#fff"]{
                @include box-shadow(0 0 0 1px #E0DCDC);
            }

            &::after{
                display: none;
            }
        }
    }
    @include hover{
        span{

            &.color{
                @include box-shadow(0 0 0 3px #E0DCDC);
            }
        }
    }
    input:checked + span{

        &.color{
            @include box-shadow(0 0 0 3px #E0DCDC);
        }
    }
}

.custom-radio{

    span{
        border-radius: 50%;

        &::after{
            border-radius: 50%;
        }
    }
}


// =========================================================================
// Submit
// =========================================================================
.bt-search-submit{
    position: absolute;
    top: 0 !important;
    right: 0 !important;
    display: inline-block;
    width: var(--webapp-input-height);
    height: var(--webapp-input-height);
    line-height: var(--webapp-input-height);
    outline: 0;
    border: none;
    background: transparent;

    .licon{
        position: relative !important;
        width: 32px !important;
        height: 32px !important;
    }
}


.screen-reader-response{
    display: none;
}



form .human-quiz-captcha #quiz-captcha{

    .bg-noir &,
    .bg-main &{
        border-color: var(--color-blanc);
    }
}
