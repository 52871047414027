.inspiration-listing-container{
    @include bloc;
    float: left;

    .inspiration-wrapper{
        @include bloc;
        float: left;


        .square{

            @include responsive(mobile){
                width: 150px;
                height: 150px;
                padding: 0;
                margin: 0 0 30px;
            }
        }

        .inspiration-item{
            @include bloc;
            float: left;

            .inner{
                @include bloc;
            }

            .visuel{
                @include bloc;
                height: 0;
                padding: 0 0 100% 0;

                a{
                    @include blocAbsolute(0);
                }
            }

            @include responsive(at-sm){

                &.item-1{

                    .visuel{
                        padding: 0 0 90.92% 0;
                    }
                }
                &.item-9{

                    .visuel{
                        padding: 0 0 90.91% 0;
                    }
                }
            }

            .link{
                z-index: 3;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                width: 100%;
                height: 100%;
                padding: 0;
                opacity: 0;
                background: rgba(0,0,0,0.25);
                @include transition;

                span{
                    width: 100%;
                    margin: auto;
                    padding: 20px;
                }
            }

            .title{
                @include bloc;
            }


            @include responsive(at-md){

                &:hover{

                    .link{
                        opacity: 1;
                    }
                }
            }
        }

        @include responsive(at-sm){

            &.item-4,
            &.item-5{
                margin-top: -50px;
            }

            .colitem-10,
            .colitem-11{
                padding-top: 136px;
            }

            &.item-7,
            &.item-9{
                margin-top: -200px;
            }
        }
    }
}


body.category-id-parent-78{
    overflow: hidden;

    #main{
        min-height: 100vh !important;
    }
}


// =============================================================================
// Page Popup detail
// =============================================================================
.inspiration-detail-container{
    @include blocAbsolute(9999);
    position: fixed;
    padding: 50px 0;
    background: rgba(255,255,255,.9);

    @include responsive(mobile){
        padding: 50px 0;
        overflow: auto;
    }

    .h-100{

        @include responsive(mobile){
            height: auto !important;
        }
    }

    .inspiration-wrapper{
        @include bloc;
    }

    .inspiration-gallery{
        @include bloc;
        padding: 50px 0;

        .inspiration-gallery-image{
            @include bloc;

            @include responsive(mobile){
                height: 350px !important;
            }
        }
    }

    .inspiration-products{
        z-index: 3;
        @include bloc;
        max-width: 100%;
        padding: 0 50px;
        overflow: auto;

        @include responsive(mobile-tablet){
            padding: 0 15px;
        }
    }

    #js-product-list,
    .products,
    .pagination{
        @include bloc;
        float: left;
    }

    .product-miniature{
        margin: 0 0 40px;
    }

    .inspiration-detail-navigation{
        z-index: 3;
        position: absolute;
        bottom: 70px;
        right: 20px;
        display: inline-block;
        width: auto;

        ul{
            display: inline-block;
            width: auto;
            margin: 0;
            padding: 0;

            li{
                list-style: none;
                display: inline-block;
                width: auto;
                float: none;
                vertical-align: middle;
                margin: 0 0 0 10px;
                padding: 0;
            }
        }

        a{
            @include bloc;
            width: 34px;
            height: 34px;
        }

        svg{
            @include bloc;
            height: auto;
        }
    }

    .inspiration-detail-close{
        z-index: 5;
        position: absolute;
        top: 50px;
        right: 0;
        display: inline-block;
        width: 48px;
        height: 48px;

        @include responsive(mobile){
            top: 25px;
        }

        svg{
            @include bloc;
            height: auto;
        }

        @include responsive(at-md){

            &:hover{
                @include rotate(90deg);
            }
        }
    }
    
    .inspiration-overlay-close{
        @include blocAbsolute(0);
    }
}