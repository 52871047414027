.orders{
    @include bloc;
}

.order{
    @include bloc;
    margin: 0 0 15px;
    padding: 15px;
    border: 1px solid var(--color-gris);
    opacity: 1;
    @include transition;

    a{
        color: var(--color-noir);
    }

    .order-again{
        @include bloc;
        margin: 15px 0 0;
    }
    .order-detail{
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .status{

        .label-pill{
            padding: 2px 7px;
            border-radius: 3px;
            color: var(--color-blanc);
        }
    }
}



#order-infos,
#order-history{
    @include bloc;
    margin: 0;
    padding: 0;

    a{
        color: var(--color-noir);
    }

    table {
        width: 100%;

        tr {
            height: 30px;

            td:last-child {
                text-align: right;
            }
        }
    }

    .label-pill{
        padding: 2px 7px;
        border-radius: 3px;
        color: var(--color-blanc);
    }
}

.order-items{
    @include bloc;

    &::before,
    &::after{
        content: "";
        display: block;
        position: absolute;
        top: -16px;
        left: 0;
        width: 100%;
        height: 16px;
        background: -webkit-linear-gradient(transparent 0,transparent 0),
            -webkit-linear-gradient(135deg,--color-beige 33.33%,transparent 33.33%),
            -webkit-linear-gradient(45deg,--color-beige 33.33%,transparent 33.33%);
        background-size: 0 100%, 16px 16px, 16px 16px !important;
    }
    &::after{
        top: auto;
        bottom: -16px;
        @include rotate(180deg);
    }

    .order-item{
        @include bloc;
        margin: 0 0 15px;
    }
}

#order-addresses{
    @include bloc;
}

.order-totals{
    @include bloc;
}

.order-message-form{
    @include bloc;
}

