// @charset 'UTF-8';

@import "base/functions";
@import "base/define";
@import "base/elements";

@import url(https://www.lucyan.fr/framework/dist/lucyan.css);

@import "swiper/css/bundle";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "base/fontawesome";

@import "base/global";
@import "base/typography";

@import "components/swiper";
@import "components/form";
@import "components/table";
@import "components/create";

@import "components/logo";
@import "components/head";
@import "components/menu";
@import "components/cta";
@import "components/social";
@import "components/searchbar";

@import "components/header";
@import "components/languages";
@import "components/footer";

@import "layouts/layout";

@import "prestashop/featured";
@import "prestashop/tabs";
@import "prestashop/cardbox";
@import "prestashop/product";
@import "prestashop/categories";
@import "prestashop/facets";

@import "prestashop/page";
@import "prestashop/modal";
@import "prestashop/alerts";
@import "prestashop/datatable";

@import "prestashop/bag";
@import "prestashop/cart";
@import "prestashop/tunnel";
@import "prestashop/account";
@import "prestashop/address";
@import "prestashop/order";
@import "prestashop/maintenance";

@import "page/home";
@import "page/blog";
@import "page/inspirations";
@import "page/contact";
@import "page/colissimo";
@import "page/animation";

@import "module/banner";
@import "module/customtext";
@import "module/keytext";
@import "module/slider";
@import "module/showroom";
@import "module/inspiration";
@import "module/prestablog";
@import "module/dynamicproduct";
@import "module/wishlist";
@import "module/reviews";
@import "module/carriers";
@import "module/payment";
@import "module/devis";
@import "module/gformbuilderpro";
