.colissimo-pickup-point-address{
    padding: 0 15px;
}



#colissimo-widget-container{
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;

    #colissimo-container{


        .colissimo_widget_topnav2{

            li{
                list-style: none;
            }
        }
    }
}

body #colissimo-pickup-point-address-selected{
    border: none !important;
}