$menu_desktop: at-md;

.bt-menu-trigger{
    z-index: 700;
    @include inlinebloc;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: -10px;
    color: var(--color-noir);
    cursor: pointer;
    @include transition;

    @include responsive($menu_desktop){
        .standard &{
        }
    }

    .inner{
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: 20px;
        height: 22px;
        margin: -11px 0 0 -10px;
    }

    .line {
        @include bloc;
        width: 20px;
        height: 2px;
        margin: 5px 0 0 0;
        border-radius: 1px;
        background: currentColor;
        @include transform-origin(center center);
        @include transition;
    }
    .line-top{
        margin-top: 0;
    }

    .text{
        display: none;
    }

    .bt-menu-open &{
        @include rotate(90deg);

        .line{
            -webkit-transition-delay: 500ms;
            transition-delay: 500ms;
        }
        .line-top{
            width: 16px;
        }
        .line-middle{
            width: 20px;
        }
        .line-bottom{
            width: 12px;
        }
    }
}


.nav--menu{
    @include bloc;
    margin: 0;
    padding: 0;

    .licon-toggle{
        z-index: 3;
        position: absolute;
        top: 10px;
        right: 20px;
        display: none;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 50%;
        color: var(--color-noir);
        cursor: pointer;
        @include transition;

        &::before{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: -10px 0 0 -10px;
            background-color: currentColor;
            -webkit-mask: var(--licon-arrow-bottom);
            mask: var(--licon-arrow-bottom);
            @include rotate(-90deg);
            @include transition;
        }
    }

    li{
        @include bloc;
        margin: 10px 0 0 0;
        padding: 0 0 0 15px;
        @include transition;

        @include responsive($menu_desktop){
            .standard &{
                margin: 0;
                padding: 0;
            }
        }

        a{
            @include bloc;
            @include bloc;
            color: var(--color-noir);
            @include font-size(1.3);
            font-weight: var(--webapp-font-weight-semibold);
            @include letter-spacing(20);
        }

        @include hover{

            > a{
                opacity: .65;
                @include translate(5px,0);
            }
        }
        &.current-menu-item,
        &.current-menu-ancestor,
        &.current-menu-parent{

            > a{
                // opacity: .5;
                opacity: 1;
                color: var(--color-main);
            }
        }
    }

    .menu-item-has-children{

        &.opened{

            > .licon-toggle{
                &::before{
                    @include rotate(0deg);
                }
            }
        }
    }

    &.-menu-main{
        a[href*="showroom"]{
            &::before{
                content: "";
                @include inlinebloc;
                width: 32px;
                height: 32px;
                margin-right: 5px;
                background-color: var(--color-noir);
                -webkit-mask: var(--licon-showroom);
                mask: var(--licon-showroom);
            }
        }
        a[href*="creation"]{
            &::before{
                content: "";
                @include inlinebloc;
                width: 32px;
                height: 32px;
                margin-right: 5px;
                background-color: var(--color-noir);
                -webkit-mask: var(--licon-creation);
                mask: var(--licon-creation);
            }
        }
        a[href*="projet"]{
            &::before{
                content: "";
                @include inlinebloc;
                width: 32px;
                height: 32px;
                margin-right: 5px;
                background-color: var(--color-noir);
                -webkit-mask: var(--licon-projet);
                mask: var(--licon-projet);
            }
        }
    }

    &.-menu-main2{

    }

    &.-menu-mentions{

        li{
            @include bloc;
            margin: 0 0 10px;
            padding: 0;

            @include responsive(at-sm){
                display: inline-block;
                width: auto;
                margin: 0;
                padding: 0;
                padding: 5px 20px;

                &:after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    display: block;
                    width: 1px;
                    height: 21px;
                    background: var(--color-noir);
                    opacity: .18;
                    @include translate(0,-50%);
                }

                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;

                    &:after{
                        display: none;
                    }
                }
            }
        }

        a{
            margin: 0;
            padding: 0;
            line-height: 1.15;
        }
    }


}


.bt-menu{
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 300px;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    @include translate(-200%,0);
    @include transition;

    @include responsive(at-xs-xl){
        max-width: 320px;
    }
    @include responsive(at-sm){
        max-width: 450px;
    }
    @include responsive(at-sm-xl){
        left: 50%;
        margin-left: -380px;
        max-width: 450px;
    }
    @include responsive(at-md){
        margin-left: -512px;
    }
    @include responsive(at-lg){
        margin-left: -615px;
    }
    @include responsive(at-xl){
        margin-left: -590px;
    }

    .bt-menu-open &{
        opacity: 1;
        visibility: inherit;
        @include translate(0,0);
    }

    .bt-menu-container{
        z-index: 3;
        @include bloc;
        height: 100%;
        float: left;
        background: var(--color-blanc);

        .menu-background{
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 1000px;
            height: 100%;
            background: var(--color-blanc);
        }
    }

    .bt-menu-wrapper{
        @include bloc;
        float: left;
        overflow: auto;
    }

    .menu-box{
        @include bloc;
        float: left;
    }

    .view-mobile{
        display: block;
        visibility: visible;
    }
    .view-desktop{
        display: none;
        visibility: hidden;
    }

    .menu-item-visuel{
        display: none !important;
    }

    .menu-box.-menu-main2{
        padding-left: 10px;

        li{
            margin: 0;
            padding: 0;

            a{
                margin: 0;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 40px;
            }
        }

        li.menu-item-0,
        li.menu-item-1{

            > a{
                border-top: 1px solid var(--color-beige-light);

                .licon-toggle{
                    display: inline-block;
                }
            }

            > ul{
                max-height: 0;
                overflow: hidden;
            }

            &.opened{
                > ul{
                    max-height: 1000px;
                    overflow: auto;
                    @include transition(all 1s ease);
                }
            }
        }

        li.menu-item-0{

            > a{
                padding-left: 0px;
                border-top: 1px solid var(--color-beige-dark);
            }
            &:first-child{
                > a{
                    border-top: none;
                }
            }
        }
        li.menu-item-1{

            > a{
                padding-left: 20px;
            }
        }
    }

    .menu-item-has-children{

        > .licon-toggle{
            display: inline-block;
        }
    }

    .sub-menu[data-depth="1"]{
        li:first-child{
            
            > .licon{
                display: none !important;
            }
            > ul{
                max-height: inherit !important;
                overflow: inherit !important;
            }
        }
    }
}

.bt-menu-overlay{
    @include blocAbsolute(499);
    position: fixed;
    color: var(--color-noir);
    @include animateHide;

    &::before{
        content: "";
        @include blocAbsolute(0);
        background: currentColor;
        opacity: .25;
    }

    .bt-menu-open &{
        @include animateShow;
    }

    @include responsive($menu_desktop){
        .standard &{
            @include animateHide;
        }
    }
}


.d-flex.-item-navigation{

    > div{
        margin-left: 10px;
    }

    > .-item-search{
        order: 1;
        flex: 1 1 auto !important;
    }
    > .-item-menu{
        order: 2;
        margin-left: 40px;
    }
    > .-item-contact{
        order: 3;
    }
    > .-item-wishlist{
        order: 4;
    }
    > .-item-customer{
        order: 5;
    }
    > .-item-panier{
        order: 6;
    }
}



.layout-head-nav .nav--menu{

    &.-menu-main{

        li{
            position: relative;
            margin: 0;
            padding: 0 15px;

            &::after{
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                display: inline-block;
                width: auto;
                float: none;
                vertical-align: middle;
                width: 1px;
                height: 20px;
                margin: -10px 0 0 0;
                background: var(--color-noir);
            }
            &:last-child{

                &::after{
                    display: none;
                }
            }

            > a{
                height: 42px;
                line-height: 42px;
                text-align: center;
                white-space: nowrap;
            }
        }

        @include responsive($menu_desktop){
            display: flex !important;

            > li{
                -ms-flex: 1 1 auto !important;
                flex: 1 1 auto !important;
                margin: 0 15px 0 0;
                padding: 0 15px 0 0;

                > a{
                    z-index: 5;
                }
            }
        }
    }
}

.layout-head-lowernav .nav--menu{

    &.-menu-main2{

        li.menu-item-0{

            > a{
                text-align: center;

                &::after{
                    content: "";
                    @include inlinebloc;
                    width: 10px;
                    height: 6px;
                    margin: 0 0 0 25px;
                    background: transparent no-repeat center center;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.547' height='6' viewBox='0 0 9.547 6'%3E%3Cpath id='Polygone_8' data-name='Polygone 8' d='M3.991.984a1,1,0,0,1,1.565,0l2.7,3.394A1,1,0,0,1,7.474,6h-5.4a1,1,0,0,1-.783-1.623Z' transform='translate(9.547 6) rotate(180)'/%3E%3C/svg%3E%0A");
                }
            }

            .sub-menu{

                li{
                    z-index: 1;
                }

            }

            &::after{
                content: "";
                z-index: 0;
                position: absolute;
                top: 59px;
                left: -200%;
                display: block;
                width: 400%;
                height: 0;
                background: var(--color-blanc);
                @include transition;
            }

            &.hovering{

                &::after{
                    height: 280px;
                }
            }
        }

        .mega-menu-visuels{
            position: absolute;
            top: 30px;
            left: 0;
            display: block;
            width: 260px;

            .menu-item-visuel{
                z-index: 1;
                @include bloc;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: var(--webapp-border-radius);
                overflow: hidden;
                opacity: 0;

                &:first-child{
                    position: relative;
                    opacity: 1;
                }

                &.active{
                    z-index: 3;
                    opacity: 1;
                }
            }
        }

        @include responsive($menu_desktop){
            display: flex !important;
            justify-content: center !important;

            > li{
                position: static;
                -ms-flex: 1 1 auto !important;
                flex: 1 1 auto !important;
                margin: 0 15px;

                > a{
                    z-index: 5;
                }
            }

            li.menu-item-0{
                -ms-flex: inherit !important;
                flex: inherit !important;
                padding: 0;

                > a{
                    height: 60px;
                    line-height: 60px;
                }

                > ul{
                    z-index: 3;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: block;
                    width: 100%;
                    margin: -1px 0 0 0;
                    background: var(--color-blanc);
                    padding: 0 0 0 320px;
                    max-height: 0;
                    overflow: hidden;
                    @include transition;

                    &::before{
                        content: "";
                        z-index: -1;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        display: block;
                        width: 1440px;
                        height: 100%;
                        margin-left: -720px;
                        background: var(--color-blanc);
                        border-top: 1px solid var(--color-noir-15);
                        border-bottom: 1px solid var(--color-noir-15);
                    }

                    li{
                        display: inline-block;
                        width: 50%;
                        float: left;
                        margin: 0 0 12px;
                    }

                    > li{
                        padding: 0 0 0 20px;
                        border-left: 1px solid var(--color-noir-15);

                        &:first-child{
                            border-left: none;
                        }
                    }
                }

                &.hovering{

                    > ul{
                        height: 280px;
                        max-height: 280px;
                        padding-top: 30px;
                        padding-bottom: 30px;
                    }
                }
            }

            li.menu-item-1{
                position: static;
                display: inline-block;
                width: 166px;
                float: none;
                vertical-align: top;
                padding: 0 20px 0 0;

                > a{
                    @include font-size(1.2);
                    font-weight: var(--webapp-font-weight-regular);
                    letter-spacing: 0;
                    margin-bottom: 25px;
                }
            }

            li.menu-item-2{

                > a{
                    letter-spacing: 0;
                }
            }

            #category-5{

                > ul{
                    display: flex !important;
                    justify-content: center !important;

                    li.menu-item-1{
                        position: static;
                        width: auto;
                        -ms-flex: 1 1 auto !important;
                        flex: 1 1 auto !important;
                    }
                }
            }
        }
    }
}

.nav--footer{
    @include bloc;
    margin: 0;
    padding: 0;

    ul{
        @include bloc;
        margin: 0;
        padding: 0;
    }

    li{
        @include bloc;
        margin: 0;
        padding: 0;

        a{
            @include bloc;
            margin: 10px 0 0;
            padding: 0;
            color: var(--color-noir);
            @include font-size(1.4);
            font-family: var(--typo-font);
            font-weight: var(--typo-regular);
        }

        @include hover{
            a{
                color: var(--color-main);
            }
        }
    }
}


.bt-menu-cta{
    @include bloc;

    .is-scrolling &{
        @include animateHide;
    }
}


.pagination {
    @include bloc;
    float: left;
    margin: 0;
    padding: 0;
    text-align: center;

    li{

        a,
        span {
            @include inlinebloc;
            color: var(--color-noir);
            @include font-size(1.4);
            @include letter-spacing(20);
            @include transition;

            &.prev, &.next{
                @include inlinebloc;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                border: 2px solid var(--color-beige-light);
                @include transition;

                &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin: -10px 0 0 -10px;
                    @include licon(--licon-caret, 20px, currentColor);
                }

                @include hover{
                    background: var(--color-noir);
                    color: var(--color-blanc);
                }
            }
            &.prev{
                @include rotate(90deg);
            }
            &.next{
                @include rotate(-90deg);
            }
        }

        @include hover{

            a{
                opacity: .6;

                &.prev, &.next{
                    border-color: var(--color-noir);
                    background: var(--color-noir);
                    opacity: 1;
                    color: var(--color-blanc);
                }
            }
        }
        &.current{

            a{
                opacity: 1;
                font-weight: var(--webapp-font-weight-semibold);
            }
        }
    }
}


.bt-theme-selector{
    z-index: 999;
    position: fixed;
    bottom: 10px;
    left: 10px;
    display: inline-block;

    a{
        @include inlinebloc;
        padding: 4px;
        background: var(--color-noir);
        border-radius: 20px;

        .licon-toggle{
            @include bloc;
            background-color: var(--color-blanc);
        }

        .licon-sun{
            opacity: 1;
        }
        .licon-moon{
            opacity: .35;
        }

        @include responsive(no-touch){
            &:hover{
                .licon-moon{
                    opacity: .65;
                }
            }
        }
    }
}
[data-theme="dark"]{
    .bt-theme-selector{
        a{
            @include inlinebloc;

            .licon-sun{
                opacity: .35;
            }
            .licon-moon{
                opacity: 1;
            }

            @include responsive(no-touch){
                &:hover{
                    .licon-sun{
                        opacity: .65;
                    }
                }
            }
        }
    }
}