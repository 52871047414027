*, 
*:after,
*:before {
    @include box-sizing(border-box);
} 

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    @include inline-block;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9.
 * Hide the `template` element in IE, Safari, and Firefox < 22.
 */

[hidden],
template {
    display: none;
}

/* ==========================================================================
   Base
   ========================================================================== */
html {
    font-family: sans-serif; /* 1 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
}

body {
    margin: 0;
}

*.no-margin{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

*.no-padding{
    padding: 0 !important;
}

.no-border{
    border: none !important;
}

.relative{
    position: relative;
}


/* ==========================================================================
   TYPOGRAPHY
   ========================================================================== */
p {
    margin-top: 0;
}

/* ==========================================================================
   Links
   ========================================================================== */

a:focus {
    outline: none;
}

a:hover,
a:active,
a:focus{
    outline: none;
    cursor: pointer;
}

a, button, input{

    &:hover, &:focus, &:active, &:visited{
        outline: none;
    }
}


/* ==========================================================================
   Liste
   ========================================================================== */  
ul, ol {
    margin: 0.67em 0 0.67em 25px;

    li { 
        margin: 10px 0;
    }
}   

ul{

    li{
        list-style-type: disc;
    }
}

ul.no-list-style li{ 
    list-style: none !important; 

    &::before{
        display: none;
    }
}

p {
    margin: 0;
    font-weight: normal;

    &:last-child {
        margin-bottom: 0;
    }
}

abbr[title] {
    border-bottom: 1px dotted;
}

em{
    font-family: var(--typo-font-text);
}

b, strong {
    font-family: var(--typo-font-text);
    font-weight: var(--webapp-font-weight-bold) !important;
}

dfn {
    font-style: italic;
}

code,
kbd,
pre,
samp {
    font-family: monospace, serif;
}

pre {
    position: relative;
    display: block;
    width: 100%;
    float: left;
    font-size: 10px;
    white-space: pre-wrap;
}

q {
    quotes: "\201C" "\201D" "\2018" "\2019";
}

small {
    font-size: 80%;
}

sub,
sup { 
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

/* ==========================================================================
   Embedded content
   ========================================================================== */

figure figcaption { 

}

img{   
    border: 0;
    @include rwdImg(100%);
}

iframe{
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
}

.img-responsive{
    display: block;
    width: 100%;
    height: auto;
    position: relative;
}

svg:not(:root) {
    overflow: hidden;
}

audio, canvas, video {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
}

/* ==========================================================================
   Figures
   ========================================================================== */
figure {
    margin: 0;
}

/* ==========================================================================
   Forms
   ========================================================================== */
fieldset {
    border: 1px solid;
    margin: 0 0 3em;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0; /* 1 */
    padding: 0; /* 2 */
}
button,
input,
select,
textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 2 */
    margin: 0; /* 3 */
}
button,
input {
    line-height: normal;
}
button,
select {
    text-transform: none;
}
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
}
button[disabled],
html input[disabled] {
    cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    margin-right: 10px;
    float: left;
    text-align: left;
}
input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; /* 2 */
    box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0; 
    padding: 0;
}
textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}
label {
    display: block;
}
label.inline{
    &[for="rememberme"]{
        display: block;
        clear: both;
    }
    input[type="checkbox"] {
        float: left;
        display: inline-block;
        width: auto;
        margin-top: 2px;
    }
}

input, textarea {
    padding: 3px 5px;
    max-width: 100%;
}
textarea {
    height: 160px;
    resize: none;
}

/* ==========================================================================
   Tables
   ========================================================================== */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.table > div{
    display: table-cell;
}

.alignleft {
    float: left;
}
.alignright {
    float: right;
}
.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.wp-caption {
    max-width: 100%; /* Keep wide captions from overflowing their container. */
    padding: 4px;
}
.wp-caption .wp-caption-text,
.gallery-caption,
.entry-caption {
    font-style: italic;
    font-size: 12px;
    font-size: 0.857142857rem;
    line-height: 2;
    color: #757575;
}
img.wp-smiley,
.rsswidget img {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}
.entry-content dl.gallery-item {
    margin: 0;
}
.gallery-item a,
.gallery-caption {
    width: 90%;
}
.gallery-item a {
    display: block;
}
.gallery-caption a {
    display: inline;
}
.gallery-columns-1 .gallery-item a {
    max-width: 100%;
    width: auto;
}
.gallery .gallery-icon img {
    height: auto;
    max-width: 90%;
    padding: 5%;
}
.gallery-columns-1 .gallery-icon img {
    padding: 3%;
}

/* Clear Floated Elements
----------------------------------------------------------------------------------------------------*/
.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}
.clearfix, .cf {
    zoom: 1;
    &:before, &:after { content: ""; display: table; }
    &:after { clear: both; }
}

/* Formular
----------------------------------------------------------------------------------------------------*/
form{
    display: block;
    position: relative;
    width: 100%;
    @include box-sizing;

    fieldset{
        position: relative;
        border: none;
        margin: 0 0 15px;
        padding: 0;
        display: block;
    }

    .wpcf7-form-control-wrap {
        position: relative;
        display: block;
    }

    .obligatory{
        position: absolute;
        left: 3px;
        top: 5px;
        display: inline-block;
        color: var(--color-noir);
    }

    label{
        position: relative;
    }

    input,
    textarea{
        display: block;
        width: 100%; 
        border: none;
        color: var(--color-noir);
    }

    .consignes{
        width: 50%;
        display: inline-block;
        float: left;
        color: var(--color-noir);
        text-align: left !important;

        .obligatoire{ color: crimson;}
    }

    .wpcf7-not-valid{ border-color: crimson !important}
    .wpcf7-validation-errors,
    .mauticform-has-error input,
    .mauticform-has-error textarea{
        border-color: crimson !important;
    }
    .wpcf7-response-output{
        position: relative;
        top: inherit;
        display: block;
        width: 100%;
        float: left;
        clear: both;
        margin: 25px 0 0;
        padding: 0;
        color: var(--color-noir);
    }
    .mauticform-errormsg{
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        font-family: var(--typo-font-text);
        font-size: 12px;
        color: crimson;
    }
}