.table{
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #dee2e6;

    th, td{
        padding: 0.75rem;
        vertical-align: top;
        border: 1px solid #dee2e6;
        text-align: inherit;
        text-align: -webkit-match-parent;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
    }
}