.bag{
    @include bloc;
    float: left;

    .bag-product-hidden{
        display: none !important;
        visibility: hidden !important;
    }

    .bag-cart-taxes-show .bag-cart-total{
        display: none !important;
        visibility: hidden !important;
    }
    .bag-cart-total-show .bag-cart-taxes{
        display: none !important;
        visibility: hidden !important;
    }

    .no-items{
        @include bloc;
        float: left;
        padding: 15px;
        border-bottom: 1px solid $system-line;
    }

    .bag-products{
        @include bloc;
        float: left;
        max-height: 280px;
        overflow: auto;

        ul{
            padding: 0;
        }

        /* *** Bag Header *** */
        .bag-product{
            @include bloc;
            float: left;
            margin: 0;
            padding: 15px;
            border-bottom: 1px solid $system-line;

            .row,
            .row > div{
                position: relative;
                display: block;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
                float: left;
                margin: 0;
                padding: 0;
            }

            .row > div{

                &.bag-product-visuel{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 30%;
                }

                &.bag-product-title,
                &.bag-product-content{
                    padding: 0 0 0 40%;
                }
            }


            .product-line-title{

                a{
                    color: var(--color-blanc);

                    &:hover{
                        color: var(--color-main);
                    }
                }
            }

            .product-line-info{
                text-align: left;

                .price{
                    color: var(--color-blanc);
                }

                .qty{
                    margin: 16px 0 0 !important;
                    text-align: left;

                    .input-group.bootstrap-touchspin{

                        .js-cart-line-product-quantity{
                            height: 32px;
                            border: 1px solid rgba(255,255,255,0.5);
                            background: var(--color-noir);
                            color: var(--color-blanc) !important;
                        }

                        .input-group-btn-vertical{

                            .bootstrap-touchspin-up,
                            .bootstrap-touchspin-down{
                                background: rgba(255,255,255,0.5);
                            }
                        }
                    }
                }
            }

            .product-line-remove{
                position: absolute;
                top: -25px;
                right: 10px;

                .licon.licon-close {
                    background-position: -40px -20px;
                }
            }
        }
    }



    /* *** Card block *** */
    .card-block{
        @include bloc;
        float: left;
        margin: 0;
        padding: 0;
    }

    .cart-summary-line{
        @include bloc;
        float: left;
        margin: 0;
        padding: 0;
        line-height: 1.25;

        .label,
        .value{
            display: inline-block;
            color: var(--color-gris);
            @include font-size(1.4);
        }
        .label{
            float: left;
        }
        .value{
            float: right;
        }
    }



    /* *** Recapitulatif *** */
    .bag-subrecap{
        @include bloc;
        float: left;
        padding: 15px;
        border-bottom: 1px solid $system-line;
    }

    .bag-recap{
        @include bloc;
        float: left;
        padding: 15px;

        .value{
            color: var(--color-blanc);
            @include font-size(1.8);
        }
    }



    /* *** Actions *** */
    .bag-actions{
        @include bloc;
        float: left;
        padding: 15px;

        .btn{
            display: inline-block !important;
            width: 50%;
            float: left;
            margin: 0;
            padding: 0;
            border: none;
            border-radius: 0 !important;
            color: var(--color-blanc) !important;
            background: rgba(255,255,255,0.2);
        }

        .btn + .btn{
            background: rgba(255,255,255,0.1);
        }
    }
}