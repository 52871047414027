.bt-customtext{


    h2{
        @include bloc;
        // max-width: 570px;
        margin: 0 0 15px;
    }
}

.bloc-htmlabout{


    .ornement{
        position: absolute;
        left: 50%;

        &.-top{
            top: -40px;
        }
        &.-bottom{
            bottom: -234px;
        }
    }
}

.bloc-htmlprojet{


    .limiter-max{

        #product &{
            background: var(--color-blanc) !important;
        }
    }
    
    .row-1{
        @include responsive(tablet){
            display: none;
        }
    }

    .image{
        @include bloc;
        border-radius: 6px;
        top: inherit;
        left: inherit;
        right: inherit;
        @include embed-calc(100,100);
        margin: 0 0 30px 0;

        @include responsive(mobile-tablet){
            -webkit-transform: none !important;
            transform: none !important;
        }
        @include responsive(at-md){
            position: absolute;
            margin: 0;

            &.image-0{
                @include embed-calc(1,1);
                top: -180px;
            }
            &.image-1{
                @include embed-calc(168,251);
                top: -130px;
            }
            &.image-2{
                @include embed-calc(465,311);
                top: -180px;
            }
            &.image-3{
                @include embed-calc(168,144);
            }
        }
    }

    .color{
        @include bloc;
        margin: 0 0 30px 0;
        border-radius: 6px;

        &.color-1{
            @include embed-calc(100,50);
            background-color: #85A3C2;
            @include responsive(tablet){
                @include embed-calc(100,100);
            }
        }
        &.color-2{
            @include embed-calc(100,100);
            background-color: #B99D9D;
        }

        @include responsive(mobile-tablet){
            -webkit-transform: none !important;
            transform: none !important;
        }
        @include responsive(at-md){
            position: absolute;
            margin: 0;

            &.color-1{
                top: -60px;
                @include embed-calc(366,228);
            }
            &.color-2{
                top: -60px;
                @include embed-calc(267,179);
            }
        }
    }

    .licon-canape{
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        @include translate(-50%,-50%);
    }
}

.bloc-htmlsurmesure{


}