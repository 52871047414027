.bloc.blog-index{
    padding-bottom: 0;
}


.blog-listing-wrapper{

}


.blog-header{
    @include bloc;
    float: left;
}


.blog-container{
    @include bloc;
    float: left;

    .BC_link_all{
        margin: 0 0 25px;

        @include responsive(at-sm){
            position: absolute;
            top: 15px;
            right: 0;
            margin: 0;
        }
    }
}


.blog-archive{
    @include bloc;
    float: left;
}

.blog-miniature{
    @include bloc;
    height: 100%;
    padding: 0 0 40px;


    .blog-miniature-image,
    .blog-miniature-wrapper{
        @include bloc;
        float: left;
    }

    .blog-miniature-wrapper{
        padding: 20px;
    }

    .BC_title{
        min-height: 48px;
        
        @include responsive(tablet){
            min-height: 78px;
        }
    }

    .BC_link{
        position: absolute;
        bottom: 0;
        right: 15px;
        display: inline-block;
        width: auto;
        opacity: 0;
        @include transition;

        @include responsive(mobile-tablet){
            opacity: 1;
        }

        &.left{
            right: initial;
            left: 15px;
        }
    }

    &.has-wrapper{
        padding: 0;

        .BC_link{

        }
    }

    &:hover{

        .BC_link{
            opacity: 1;
        }
    }

    &.blog-a-la-une{


        .BC_link{
            
            @include responsive(mobile){
                position: relative;
                float: right;
                top: 0;
                right: 0;
                left: 0;
            }
        }
    }

    &.blog-plus-vus{


    }
}

.blog-navigation{

    ul{
        @include bloc;
        margin: 0;
        padding: 0;
        text-align: center;

        li{
            list-style: none;
            position: relative;
            display: inline-block;
            float: none;
            vertical-align: middle;
            margin: 0 2px;
            padding: 0;
        }
    }

    a{
        position: relative;
        display: inline-block;
        height: 22px;
        line-height: 22px;
        margin: 0 10px 10px 0;
        padding: 0 10px;
        border-radius: 11px;
        background: #EAEAEA;
        color: var(--color-noir);
        @include font-size(1.4);
        font-family: var(--typo-font-text);
        @include transition;

        .material-icons.idi{
            display: none;
        }

        &:hover{
            background: var(--color-noir);
            color: var(--color-blanc);
        }
        &.current{
            background: var(--color-noir);
            color: var(--color-blanc);
            cursor: default;
            @include pointer-events(none);
        }
    }
}


.blog-pagination{
    @include bloc;
    float: left;
    margin: 0 0 30px;

    @include responsive(at-sm){
        margin: 50px 0 90px;
    }

    .prestablog_pagination{
        margin: 0;

        > a,
        > span{
            display: inline-block;
            min-width: 38px;
            height: 38px;
            line-height: 38px;
            float: none;
            vertical-align: middle;
            padding: 0 !important;
            margin: 0 2px !important;
            border: 1px solid var(--color-noir) !important;
            background: transparent !important;
            color: var(--color-noir) !important;
            @include transition;
        }

        > a{

            &:hover{
                border-color: var(--color-noir) !important;
                background: var(--color-noir) !important;
                color: var(--color-blanc) !important;
            }
        }

        > .current{
            border-color: var(--color-noir) !important;
            background: var(--color-noir) !important;
            color: var(--color-blanc) !important;
        }

        > .disabled{
            opacity: .35 !important;
        }
    }
}

.blog-single{
    @include bloc;
    float: left;

    .embed-responsive{
        padding: 0 0 40% 0;
    }
}

#blog-extra-container{

    .prestablogExtra{
        padding: 0;
        border: none !important;
    }

    .blog-extra{
        @include bloc;
        float: left;
        padding: 0;
        margin: 0;
        border: none !important;
        background: transparent !important;
    }
}

#blog_product_linked{

    .productslinks{
        @include bloc;
        float: left;
        padding: 0;
        margin: 0;

        .titre_product_blog{
            @include font-size(1.6);
            font-family: var(--typo-font-text);
            @include letter-spacing(40);
        }
    }
}