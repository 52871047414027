$selected: var(--color-noir) !default;


.product-single-detail{

    > .limiter > .limiter-inner > .row{

        > div{
            @include transition(all .7s ease);
        }
    }
}


.dyna-visuel-wrapper{
    @include bloc;
    float: left;
    border-radius: var(--webapp-border-radius);
    overflow: hidden;

    .ceiling{
        @include blocAbsolute(0);
        height: 150px;
        background: #fff url('../images/background-ceiling.svg') no-repeat center top;
        @include background-size(auto 100%);
    }
    .wall{
        @include blocAbsolute(0);
    }
    .spacer{
        @include bloc;
        @include embed-calc(564, 668);
    }

    .dyna-visuel-patere{
        z-index: 3;
        position: absolute;
        top: 70px;
        left: 0;
        display: block;
        width: 100%;
        height: 50px;
        background: transparent no-repeat center center;
        @include background-size(auto 100%);
    }

    .dyna-visuel-suspension{
        z-index: 3;
        position: absolute;
        top: 100%;
        left: 50%;
        display: inline-block;
        @include translate(-50%,0);

        .dyna-cordon{
            @include bloc;
            width: 4px;
            height: 150px;
            margin: 0 auto;
            border-radius: 2px;
            background: var(--color-noir);
            @include transition;
        }
    }

    .dyna-visuel{
        z-index: 3;
        position: absolute;
        left: 50%;
        background: transparent no-repeat center center;
        @include background-size(auto 100%);
        @include translate(-50%,0%);

        &.-douille{
            bottom: 100%;
            width: 80px;
            height: 80px;
            margin-bottom: -16px;
        }
        &.-ampoule{
            top: 100%;
            width: 100px;
            height: 100px;
        }
    }
}

.dyna-visuel-controls{
    @include bloc;
    float: left;
}



body .dp_container {
    --accent-color: var(--color-noir);

    .custom-switch{
        width: 44px !important;
        height: 25px !important;

        .slider{
            background: var(--color-gris);

            &::before{
                left: 5px;
                bottom: 5px;
                width: 15px;
                height: 15px;
            }
        }
    }
}


.dp_group_navigation{
    @include bloc;
    float: left;
    margin: 0 0 20px;
    padding: 0 20px;
    border-radius: var(--webapp-border-radius);
    background: var(--color-blanc);

    .inner{
        @include bloc;
        float: left;
        padding-left: 90px;
    }

    strong{
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        @include translate(0,-50%);
        @include font-size(1.4);
        font-weight: var(--webapp-font-weight-semibold);
    }

    ul{

        li{
            position: relative;
            flex: 1 1 auto !important;
            margin: 0;
            padding: 0;

            &::after{
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                display: inline-block;
                width: 1px;
                height: 25px;
                background: var(--color-gris-light);
                @include translate(0,-50%);
            }

            &:last-child{
                &::after{
                    display: none;
                }
            }

            a{
                @include bloc;
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid var(--color-blanc);
                color: var(--color-beige-dark) !important;
                @include font-size(1.4);
                font-weight: var(--webapp-font-weight-semibold);
                text-align: center;

                &::after{
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    display: inline-block;
                    width: 54px;
                    height: 14px;
                    margin-left: -27px;
                    background-color: var(--color-blanc);
                    -webkit-mask: var(--licon-wave);
                    mask: var(--licon-wave);
                    @include translate(0,-10px);
                    opacity: 0;
                }
            }

            @include hover{

                a{
                    color:  var(--color-main) !important;
                }
            }
            &.active{
                a{
                    color:  var(--color-noir) !important;

                    &::after{
                        @include translate(0,0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}


#dp_product{
    @include bloc;
    float: left;
    margin: 0 0 40px;
    padding: 0;


    .dp_link,
    .attribute_label::before{
        color: var(--color-main);
    }

    .dp_group{
        @include bloc;
        float: left;
        margin: 0;
        padding: 0;
        border: none !important;

        > .content{
            @include bloc;
            float: left;
        }
    }

    .dp_step{
        @include bloc;
        float: left;
        margin: 0;
        padding: 0;

        fieldset legend .dp_btn{
            @include bloc;
            margin: 0 0 15px;
            padding: 10px 20px;
            border-radius: 5px;
            background: var(--color-blanc);
            font-weight: var(--webapp-font-weight-bold);
            @include letter-spacing(40);
            text-align: left;
            text-transform: uppercase;
            display: none !important;
        }

        .dp_group_base{
            max-height: 100% !important;
            overflow: auto;
        }


        .dp_group{
            @include transition;

            &.dp_collapsed{
            }

            &.hide-tab{
                max-height: 0;
                overflow: hidden;
            }
            &.show-tab{
                max-height: 1400px;
                overflow: auto;
            }
        }
    }


    .dp_field_container{
        @include bloc;
        float: left;
        margin: 0 0 15px;
        padding: 0;
        border-radius: 0 !important;
        border: none;
        box-shadow: none !important;

        // Structure
        .attribute_label{
            @include bloc;
            float: left;
            margin: 0 0 10px;
            padding: 0;
            color: var(--color-blanc) !important;
        }

        .tooltip{

        }

        .dp_input_container{
            @include bloc;
            float: left;
            margin: 0;

            .form-control{
                color: var(--color-noir);
            }
        }

        // Elements
        .dp_thumb{

            img{
                position: relative;
                display: block;
                width: 100% !important;
                max-width: 100% !important;
                height: auto !important;
                margin: 0;
                padding: 0;
            }
        }

        .dp_thumb_btn{
            @include bloc;
            border-radius: 6px;
            border: 3px solid var(--color-noir);
            background: #fff;
            outline: 0 !important;
            @include transition;

            @include responsive(no-touch){
                &:hover{
                    opacity: .85;
                }
            }
        }
        .dp_selected .dp_thumb_btn{
            border-color: var(--color-selected);
        }

        .thumb-btn {
            @include bloc;
            float: left;
            margin: 0;
            padding: 0;

            .thumb button{
                display: none !important;
                visibility: hidden !important;
            }
        }

        .dp-zoom{
            display: none;
        }

        .ui-spinner{
            position: relative;
            display: inline-block;
            width: auto;
            border: none;
            outline: 0 !important;
            box-shadow: none !important;

            .ui-spinner-input{
                @include bloc;
                height: 46px;
                line-height: 44px;
                padding: 0 5px;
                border: 1px solid var(--color-gris);
            }

            .ui-spinner-button{
                border: none;
                background: var(--color-main);
                text-align: center;

                span{
                    position: relative;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    float: none;
                    vertical-align: middle;
                    line-height: 1;
                    margin: 0 0 0 2px;
                    background: transparent url('../images/licons-20x20.svg') no-repeat left top;
                }
                &.ui-spinner-up span{
                    background-position: 0 -20px;
                }
                &.ui-spinner-down span{
                    background-position: -20px -20px;
                }

                i{
                    display: none !important;
                }

                &:hover{
                    background: var(--color-beige);
                }
            }
        }

        // =====================================================================
        // Product : Conf
        // =====================================================================
        &[data-name="conf_nav"]{
            display: none !important;
            visibility: hidden !important;
        }
        &[data-name="conf_type"]{
            padding: 20px;
            border-radius: 6px !important;
            background: var(--color-main);
            color: var(--color-blanc);
            font-size: 14px;
            font-weight: var(--webapp-font-weight-semibold);
            @include letter-spacing(20);
            overflow: hidden;

            .dp_input_container{
                padding-right: 140px;
            }

            .custom-switch{
                position: absolute;
                top: 50%;
                right: 0;
                width: auto !important;
                height: auto !important;
                @include translate(0,-50%);
            }

            label[for="dp_conf_type"]{

                .slider.round{
                    position: relative;
                    display: block;
                    border-radius: 0;
                    border: none;
                    background: transparent;

                    &::before,
                    &::after{
                        position: relative;
                        left: inherit;
                        bottom: inherit;
                        display: inline-block;
                        width: 62px;
                        height: 42px;
                        line-height: 40px;
                        float: left;
                        padding: 0;
                        vertical-align: top;
                        border-radius: 21px;
                        border: 1px solid #B4B4B4;
                        background: var(--color-blanc);
                        transform: none !important;
                        color: var(--color-noir);
                        text-align: center;
                    }

                    &::before{
                        content: "Oui";
                        background: var(--color-blanc);
                        color: var(--color-noir);
                    }
                    &::after{
                        content: "Non";
                        margin-left: 5px;
                        background: var(--color-noir);
                        color: var(--color-blanc);
                    }
                }

                input:checked + .slider.round{

                    &::before{
                        background: var(--color-noir);
                        color: var(--color-blanc);
                    }
                    &::after{
                        background: var(--color-blanc);
                        color: var(--color-noir);
                    }
                }
            }
        }




        // =====================================================================
        // Product : Main setup
        // =====================================================================
        &[data-name="patere"],
        &[data-name*="corde_title"],
        &[data-name*="douille"],
        &[data-name*="ampoule"]{

            @include responsive(at-sm){
                padding: 0 0 0 40px;
            }

            .attribute_label{
                @include font-size(2);
                font-weight: var(--webapp-font-weight-bold);
                font-family: var(--typo-font-text);

                &::before{
                    content: "";
                    position: absolute;
                    top: -4px;
                    left: -40px;
                    display: inline-block;
                    width: 24px !important;
                    height: 38px !important;
                    background-color: var(--color-blanc) !important;
                }
            }
        }
        &[data-name="patere"]{
            .attribute_label{
                &::before{
                    @include licon(--licon-patere);
                }
            }
            .dp_thumb{
                padding: 25px 0;
            }
        }
        &[data-name*="corde_title"]{
            .attribute_label{
                &::before{
                    @include licon(--licon-corde);
                }
            }
            .dp_input_container{
                display: none !important;
                visibility: hidden !important;
            }
        }
        &[data-name*="douille"]{
            border-top: 1px solid var(--color-gris-dark);
            padding: 10px 0;

            @include responsive(at-sm){
                padding: 10px 0 10px 40px;
            }

            .attribute_label{
                &::before{
                    @include licon(--licon-douille);
                }
            }
        }
        &[data-name*="ampoule"]{
            border-top: 1px solid var(--color-gris-dark);
            padding: 10px 0;

            @include responsive(at-sm){
                padding: 10px 0 10px 40px;
            }

            .attribute_label{
                &::before{
                    @include licon(--licon-ampoule);
                }
            }
        }

        // =====================================================================
        // Product : Sub setup
        // =====================================================================
        &[data-name*="_matiere"],
        &[data-name*="_couleur"],
        &[data-name*="_cdt"]{

            @include responsive(at-md){
                padding-left: 180px;
            }

            .attribute_label{
                @include font-size(1.4);
                font-weight: var(--webapp-font-weight-semibold);
                @include letter-spacing(20);

                @include responsive(at-md){
                    position: absolute;
                    top: 10px;
                    left: 40px;
                }
            }
        }
    }


    .dp_type_image-list,
    .dp_type_radio{

        .attribute_label,
        .dp_input_container{
            @include bloc;
        }

        .dp_input_container{

            ul{
                position: relative;
                display: block;
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                float: none;
                margin: 0;
                padding: 0;

                li{
                    position: relative;
                    width: 100%;
                    flex: 0 0 25%;
                    max-width: 25%;
                    margin: 0;
                    padding: 0;
                    @include transition;

                    @include responsive(at-sm){
                        // flex: 0 0 20%;
                        // max-width: 20%;
                    }
                    @include responsive(at-md){
                        flex: 0 0 16.666667%;
                        max-width: 16.666667%;
                    }

                    // Infobulle
                    .option_label{
                        z-index: 3;
                        @include bloc;
                        float: left;
                        margin: 0;
                        padding: 2px 5px;
                        border-radius: 6px;
                        border: 3px solid var(--color-noir);
                        background: var(--color-gris-dark);
                        color: var(--color-blanc);
                        font-size: 11px;
                        text-align: center;
                        @include transition;
                        @include pointer-events;

                        @include responsive(at-md){
                            position: absolute;
                            bottom: 100%;
                            left: 50%;
                            margin: 0 0 10px;
                            display: inline-block;
                            width: auto;
                            padding: 5px 10px;
                            opacity: 0;
                            white-space: initial;
                            border: 3px solid var(--color-gris-dark);
                            @include translate(-50%,0);
                        }

                        &::after{
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            margin: 0 0 0 -10px;
                            display: inline-block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 10px 10px 0 10px;
                            border-color: var(--color-gris-dark) transparent transparent transparent;

                            @include responsive(mobile-tablet){
                                display: none;
                            }
                        }
                    }

                    @include responsive(no-touch){
                        &:hover{

                            .option_label{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &[data-name*="_douille"]{

            .dp_thumb{

                img{
                    top: -15px;
                }
            }
        }
        &[data-name*="_couleur"]{

            .dp_thumb_btn{
                width: 29px;
                height: 29px;
                border-radius: 15px;
                overflow: hidden;
            }

            .dp_input_container{

                ul{

                    li{
                        -ms-flex: 0 0 29px;
                        flex: 0 0 70px;
                        max-width: 70px;

                        @include responsive(at-sm){
                            flex: 0 0 70px;
                            max-width: 70px;
                        }
                        @include responsive(at-md){
                            flex: 0 0 29px;
                            max-width: 29px;
                        }
                    }
                }
            }
        }
    }


    // =============================================================================
    // Tooltip
    // =============================================================================
    .dp_field_container{
        @include transition;

        span[slot="tooltip"]{
            position: absolute;
            top: 0;
            display: block;
            width: 100%;

            .dp_tooltip_btn{
                // z-index: 5;
                position: absolute;
                left: -30px;
                top: 0;
                display: inline-block;
                width: 20px;
                @include transition;

                @include responsive(mobile){
                    left: 100%;
                    top: 4px;
                    margin: 0 0 0 -46px;
                }

                &::before{
                    content: "";
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9998;
                    background: rgba(0,0,0, .65);
                    opacity: 0;
                    display: none;
                    visibility: hidden;
                    @include transition2(opacity .25s 0s ease, visibility .25s .25s ease);
                }

                &::after{
                    content: "Infos";
                    position: absolute;
                    right: 100%;
                    top: 2px;
                    display: inline-block;
                    color: var(--color-noir);
                    font-size: 10px;
                    opacity: 0;
                    @include transition;
                }

                @include responsive(at-md){

                    &:hover{

                        &::after{
                            opacity: 1;
                        }
                    }
                }
            }

            > div{
                z-index: 9999;
                position: fixed;
                top: 0;
                right: -100%;
                display: table;
                // display: table !important;
                width: 100%;
                max-width: 540px;
                height: 100%;
                max-height: 100%;
                padding: 40px 20px;
                background: var(--color-blanc);
                overflow: auto;
                @include transition;

                @include responsive(mobile){
                    max-width: 280px;
                }

                &::after{
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: -42px;
                    display: inline-block;
                    width: 31px;
                    height: 31px;
                    border-radius: 50%;
                    background: var(--color-main) url('../images/licons-20x20.svg') no-repeat left top;
                    background-position: -35px -15px;
                    cursor: pointer;
                    @include pointer-events(none);

                    @include responsive(mobile){
                        left: -36px;
                    }
                }

                .tooltip-content{
                    display: table-cell;
                    width: 100%;
                    vertical-align: middle;
                }
            }
        }

        &.show-tooltip{

            span[slot="tooltip"]{

                .dp_tooltip_btn{

                    &::before{
                        opacity: 1;
                        display: block;
                        visibility: visible;
                        @include transition2(visibility .25s 0s ease, opacity .25s .25s ease);
                    }
                }

                > div{
                    right: 0;
                }
            }
        }
    }


    // =============================================================================
    // Message champs obgl.
    // =============================================================================
    .dp_field_container .alert.alert-info{
        z-index: 5;
        position: absolute;
        bottom: 0;
        top: -10px;
        left: 0;
        width: auto;
        color: transparent !important;
        padding: 0 !important;
        margin: 0 !important;
        background: transparent;
        border: none !important;
        @include box-shadow(none !important);
        font-size: 10px;

        &::before{
            content: "Champ requis";
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: auto;
            color: crimson !important;
        }
        &::after{
            display: none !important;
        }
    }
}


// =============================================================================
// DP Cart configuration popup / Order details
// =============================================================================
.dp_cart{
    @include bloc;

    &::before{
        content: "Voir la configuration";
        @include bloc;
        color: var(--color-noir) !important;
        @include font-size(1.6);
        font-family: var(--typo-font-text);
        text-align: left;
        cursor: pointer;
        @include transition;
    }

    &::after{
        content: "";
        z-index: 3;
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: transparent url('../images/licons-20x20.svg') no-repeat -40px 0;
        opacity: 0;
        cursor: pointer;
        @include transition;
    }

    .dp_input_div{
        z-index: 5;
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        width: 100%;
        background: var(--color-main);
        margin: 5px 0 0;
        padding: 0 15px;
        max-height: 0;
        border-radius: 0;
        overflow: auto;
        color: var(--color-blanc);
        @include font-size(1.4);
        @include transition;

        .dp_url{
            @include bloc;
            margin: 15px 0 0;
            color: var(--color-blanc);
        }
    }

    &.active{

        &::after{
            opacity: 1;
        }

        .dp_input_div{
            max-height: 340px;
            padding: 15px;
            @include box-shadow(0 3px 9px rgba(0,0,0,.16));
        }
    }
}


// =============================================================================
// Configuration load
// =============================================================================
#dp_core_loader{
    @include blocAbsolute(9999);
    position: fixed;
    background: rgba(0,0,0,.5);
    text-align: center;
    @include transition;

    .core_loader{
        position: relative;
        top: -25px;
        display: inline-block;
        float: none;
        vertical-align: middle;

        .text{
            @include bloc;
            max-width: 250px;
            margin: 0 auto;
            color: var(--color-blanc);
            @include font-size(3);
            font-family: var(--typo-font-text);
            font-weight: normal;
            line-height: 1.2;
        }

        .fa{
            position: absolute;
            top: 100px;
            left: 50%;
            width: 50px;
            height: 50px;
            line-height: 50px;
            margin: -25px 0 0 -25px;
            color: var(--color-blanc);
            @include font-size(2);
        }
    }

    &.loader_done{
        opacity: 0;
        visibility: hidden;
        @include transition2(opacity 500ms 0ms ease, visibility 0ms 500ms ease);
    }
}


body .ui-tooltip.dp-tooltip{
    display: none !important;
    visibility: hidden !important;
}


// =============================================================================
// Configuration notice & add to cart
// =============================================================================
body{


    &.dp_condition_ #product{

        .product-quantity{

            .box-title.control-label,
            .box-inline.qty{
                display: none !important;
                visibility: hidden !important;
            }
        }
    }

    /* .product-add-to-cart .add-to-cart{
         display: inline-block !important;
     }
     #dp-add-to-cart{
         display: none !important;
     }

     &.dp_customisation_product{

         .product-add-to-cart .add-to-cart{
             display: none !important;
         }
         #dp-add-to-cart{
             display: inline-block !important;
         }
     }*/



    .product-add-to-cart{

        .add-to-cart{

            .text{
                @include transition;
            }

            .spinner{
                position: absolute;
                top: 0;
                left: 50%;
                margin: 0 0 0 -10px;
                opacity: 0;
                @include transition;
            }
        }
    }
}



body #dp-add-to-cart{
    position: relative;
    display: inline-block;
    width: auto;
    min-width: 1px;
    height: 50px;
    line-height: 48px;
    margin: 0;
    padding: 0 15px;
    border: 1px solid var(--color-main);
    border-radius: 0 !important;
    background: var(--color-main);
    overflow: hidden;
    color: var(--color-blanc);
    @include font-size(1.8);
    font-family: var(--typo-font-text);
    text-align: center;
    @include transition;

    &::after{
        content: "";
    }

    .text{
        z-index: 1;
        position: relative;
        top: initial;
        bottom: initial;
        display: inline-block;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    .spinner{
        @include blocAbsolute;
        color: var(--color-blanc);
        background: var(--color-noir);
        opacity: 0;
        font-size: 18px;
        @include transition;
    }

    &.adding{

        .spinner{
            opacity: 1;
        }
    }

    &:hover{
        border-color: var(--color-main) !important;
        background-color: var(--color-main) !important;
        color: var(--color-blanc) !important;
    }
}

.dp-field-notice-error{
    position: absolute;
    top: -20px;
    left: 0;
    display: inline-block;
    white-space: nowrap;
    color: crimson;
    font-size: 10px;
    font-family: var(--typo-font-text);
    font-weight: normal !important;

    @include responsive(mobile){
        top: -3px;
        left: 15px;
    }

    &::before, &::after{
        display: none !important;
    }
}

.dp-add-to-cart-error{
    @include bloc;
    float: left;
    margin: 0 0 5px;
    font-size: 14px;
    color: var(--color-main);
    font-family: var(--typo-font-text);
}


// Popup color selector
#glightbox-body{


    .gslide-media{

    }

    .gslide-description{
        padding: 10px;
        text-align: center;

        .gdesc-inner{
            margin: 0;
            padding: 0;
        }

        .gslide-title{

        }

        .btn{

            margin: 10px 0 0;
        }
    }
}


// =============================================================================
// BC_gallery_configurator
// =============================================================================
.BC_gallery_configurator{
    @include bloc;
}

.DYNA_visuels{
    @include bloc;
    float: left;

    @include responsive(at-sm){
    }

    .BC_title{
        z-index: 5;
        margin-bottom: 10px;
        //position: absolute;
        //padding: 15px;
    }
}
