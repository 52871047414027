body{


    #search_widget{
        min-width: 1px;

        @include responsive(at-sm){
            width: 100%;
        }

        form{

            .bt-search-input{
                padding: 0 40px 0 20px;
                border-radius: 30px;
                background: var(--color-blanc);
            }
        }
    }

}