.bandeau{
    @include bloc;
    overflow: hidden;

    [class*="bandeau"]{
        @include bloc;
    }
}


.bandeau-mask{
    @include blocAbsolute;
    background: transparent no-repeat left top;
    @include background-size(100% auto);
}