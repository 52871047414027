.tabs{
    @include bloc;
    float: left;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: none;

    .nav{
        @include bloc;
        float: left;

        .nav-item{
            list-style: none;
            text-align: center;

            @include responsive(mobile){
                @include bloc;
            }

            .nav-link{
                @include bloc;
                opacity: .5;

                @include hover{
                    opacity: 1;
                }
                &.active{
                    opacity: 1;
                }
            }
        }
    }

    .tab-content{
        @include bloc;
        float: left;
        margin: 0;
        padding: 0;

        .tab-pane{
            @include bloc;
            display: none;

            &.active{
                display: block;
            }

            .tab-content-title{
                display: none;
            }
        }
    }
}


.tab-panel-information{
    @include bloc;
    float: left;
    margin: 0 0 30px;
    padding: 0;

    @include responsive(mobile){
        padding: 0;
    }

    .tab-panel-inner{
        @include bloc;
        float: left;
        background: var(--color-beige);
        padding: 40px;
    }

    .tab-content-title{
        @include bloc;
        margin: 0 0 15px;
    }
}