.showroom-trigger{
    z-index: 7;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 24px;
    height: 24px;


    &::before{
        content: "";
        z-index: 5;
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--color-main);
        @include translate(-50%,-50%);
    }

    .showroom-product{
        z-index: 7;
        position: absolute;
        top: 12px;
        display: inline-block;
        width: 180px;
        padding: 0 0 20px;
        border-radius: 6px;
        background: var(--color-blanc);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        @include box-shadow(0 3px 10px rgba(0,0,0,.16));
        @include transition(all 400ms ease);

        &::before{
            content: "";
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
        }

        &.-right{
            left: 0;
            margin-left: 30px;
            border-top-left-radius: 0;

            @include responsive(at-md){
                margin-left: 6px;
            }

            &::before{
                right: 100%;
                border-width: 0 18px 18px 0;
                border-color: transparent #ffffff transparent transparent;
            }
        }
        &.-left{
            right: 0;
            margin-right: 36px;
            border-top-right-radius: 0;

            @include responsive(at-md){
                margin-right: 6px;
            }

            &::before{
                left: 100%;
                border-width: 18px 18px 0 0;
                border-color: #ffffff transparent transparent transparent;
            }
        }

        @include responsive(at-md){

            &.-right{
                left: 100%;
            }
            &.-left{
                right: 100%;
            }
        }

        .product-add-btn{
            opacity: 1;
            @include translate(0,0);
        }
    }

    @include responsive(mobile-tablet){
        &.active{
            z-index: 10;

            .showroom-product{
                opacity: 1;
                visibility: visible;
                pointer-events: inherit;
            }
        }
    }
    @include responsive(at-md){
        &:hover{
            z-index: 10;

            .showroom-product{
                opacity: 1;
                visibility: visible;
                pointer-events: inherit;
            }
        }
    }
}

.showroom-detail{
    margin: 0 0 250px;

    .mask{
        position: absolute;
        bottom: -160px;
        left: -100%;
        display: block;
        width: 300%;
        height: 270px;

        @include responsive(at-sm){
            height: 340px;
        }
    }
}