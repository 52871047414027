.layout-head{
    // background: var(--color-blanc);
    @include transition;

    &.sticky{
        z-index: 70;
        position: absolute;
        top: 0;
        left: 0;

        @include responsive(at-md){
            position: fixed;
        }
    }

    @include responsive(at-md){

        .layout-head-lowernav .limiter-inner{
            border-bottom: 1px solid var(--color-noir-15);
        }
        #index.is-top &{

            .layout-head-lowernav .limiter-inner{
                border-bottom: transparent;
            }
        }
    }

    .is-top &{
        top: 0;
    }
    .is-scrolling &{
        top: -108px;
    }
    .scrolling-up &{
        top: 0;
    }
}

.layout-head-spacer{
    height: 114px;

    @include responsive(at-sm){
        height: 100px;
    }
    @include responsive(at-md){
        height: 168px;
    }

    &.-small{
        height: 70px;
    }
    
    #cart &, #checkout &{
        height: 78px;
    }
}

.layout-head-uppernav{

}

.layout-head-nav{

}

.layout-breadcrumb{

    .page-index &{
        display: none !important;
        visibility: hidden !important;
    }
    
    ul{
        
        li{
            
        }
        
        li:first-child,
        li:first-child + li .sep{
            display: none;
        }
        
        a[href*="par-type"],
        a[href*="par-style"]{
            display: none;
        }
    }
}

.bt-head{
    @include bloc;
}
