body{


    .gformbuilderpro_form{
        @include bloc;
        float: left;
        margin: 0;
        padding: 80px 0;
        background: #f7f7f7;

        .form_using_ajax{
            @include bloc;
            margin: 0 !important;
        }

        .formbuilder_group,
        .formbuilder_column{
            @include bloc;
            float: left;
            margin: 0 0 40px;
            padding: 0;
        }
        .formbuilder_group{

            &:last-child{
                margin-bottom: 0;
            }
        }
        .formbuilder_column{
            max-width: 370px;
            float: none;
            margin: 0 auto;

            &.form-group-checks{
                max-width: 690px;

                .form-group{

                    > label{
                        margin: 0;
                    }
                }
            }
        }

        .form-group{
            @include bloc;
            float: left;
            margin: 0;
            padding: 0;

            > label[for*="projet_type"],
            > label[for="projet_fichier"]{
                @include bloc;
                float: left;
                margin: 0 0 15px;
                padding: 0;
                font-size: var(--webapp-h3-font-size);
                font-family: var(--typo-font-title);
                font-weight: var(--webapp-font-weight-bold);
                text-align: center;
            }

            > label[for="projet_nom"]::before{
                content: "Complétez vos coordonnées :";
                @include bloc;
                float: left;
                margin: 0 0 15px;
                padding: 0;
                font-size: var(--webapp-h3-font-size);
                font-family: var(--typo-font-title);
                font-weight: var(--webapp-font-weight-bold);
                text-align: center;
            }

            label[for*="-hide-label"]{
                height: 0;
                overflow: hidden;
            }

            .checkbox_item_wp{
                @include bloc;
                float: left;
                margin: 0 0 25px;
                padding: 0;

                > .row{
                    justify-content: center !important;
                    margin: 0 -8px;
                    padding: 0;

                    > *{
                        flex: 0 0 138px;
                        max-width: 138px;
                        margin: 15px 0 0;
                        padding: 0 8px;

                        input{
                            position: absolute;
                            opacity: 0;
                        }

                        label{
                            z-index: 3;
                            @include bloc;
                            @include font-size(1.4);
                            font-family: var(--typo-font-title);
                            font-weight: var(--webapp-font-weight-semibold);
                            text-align: center;

                            &::before{
                                content: "";
                                @include bloc;
                                width: 122px;
                                height: 98px;
                                margin: 0 0 10px;
                                border-radius: var(--webapp-border-radius);
                                border-radius: var(--webapp-border-radius);
                                border: 3px solid var(--color-blanc);
                                background: var(--color-blanc) no-repeat center center;
                                cursor: pointer;
                                @include transition;
                            }

                            @include hover{

                                &::before{
                                    background-color: var(--color-beige-light);
                                }
                            }
                        }

                        input.-has-checkbox + label{
                            height: 42px;
                            line-height: 42px;
                            padding: 0 21px;
                            cursor: pointer;

                            &::before{
                                @include blocAbsolute(-1);
                                margin: 0;
                                border-radius: 21px !important;
                            }

                            span{
                                @include inlinebloc;
                                top: -1px;
                                line-height: 1;

                                @include responsive(at-md){
                                    top: -3px;
                                }
                            }
                        }
                        input.-has-colors + label{

                            &::before{
                                border-color: transparent;
                            }
                        }

                        input:checked + label{

                            &::before{
                                border-color: var(--color-beige);
                                background-color: var(--color-beige-light);
                            }
                        }
                        input.-has-checkbox:checked + label{
                            color: var(--color-blanc);

                            &::before{
                                border-color: var(--color-beige);
                                background-color: var(--color-noir);
                            }
                        }

                        // Types de pièces
                        input[value="Cuisine"] + label::before{
                            background-image: var(--licon-type-piece-cuisine);
                        }
                        input[value="Chambre"] + label::before{
                            background-image: var(--licon-type-piece-chambre);
                        }
                        input[value="Couloir"] + label::before{
                            background-image: var(--licon-type-piece-couloir);
                        }
                        input[value="Entrée"] + label::before{
                            background-image: var(--licon-type-piece-entree);
                        }
                        input[value="Extérieur"] + label::before{
                            background-image: var(--licon-type-piece-exterieur);
                        }
                        input[value="Garage"] + label::before{
                            background-image: var(--licon-type-piece-garage);
                        }
                        input[value="Salon"] + label::before{
                            background-image: var(--licon-type-piece-salon);
                        }

                        // Types de lampe
                        input[value="Abat-jour"] + label::before{
                            background-image: var(--licon-type-lampe-abat-jour);
                        }
                        input[value="Applique"] + label::before{
                            background-image: var(--licon-type-lampe-applique);
                        }
                        input[value="Lampadaire"] + label::before{
                            background-image: var(--licon-type-lampe-lampadaire);
                        }
                        input[value="Lampe à poser"] + label::before{
                            background-image: var(--licon-type-lampe-lampe-a-poser);
                        }
                        input[value="Plafonnier"] + label::before{
                            background-image: var(--licon-type-lampe-plafonnier);
                        }
                        input[value="Spot"] + label::before{
                            background-image: var(--licon-type-lampe-spot);
                        }

                        // Couleurs
                        input.-has-colors[value="Froid"] + label::before{
                            background: #B6CEE6 !important;
                        }
                        input.-has-colors[value="Blanc"] + label::before{
                            background: #FFFFFF !important;
                        }
                        input.-has-colors[value="Chaud"] + label::before{
                            background: #EFCD8E !important;
                        }
                    }
                }
            }

            &.multifileupload_box{

                .bootstrap-filestyle.input-group{
                    @include bloc;
                    text-align: center;
                }

                .btn{
                    border-color: var(--color-main);
                    background-color: var(--color-main);
                }
            }
        }
    }

    #gformbuilderpro_form_2{
        max-width: var(--webapp-limiter-max);
        padding-left: var(--webapp-limiter-max-padding);
        padding-right: var(--webapp-limiter-max-padding);

        .formbuilder_group:first-child{

            .formbuilder_column{

                /*&::before{
                    content: "Vous souhaitez réaliser une lampe sur-mesure ? Contactez-nous";
                    @include bloc;
                    margin: 0 0 30px;
                    font-size: var(--webapp-h2-font-size);
                    font-weight: var(--webapp-font-weight-bold);
                }*/
            }
        }

        .btn{
            margin: 10px 0 0;
            border: 1px solid var(--color-main);
            background: var(--color-main);
        }
    }

    .mega-upload-files{

        > div{
            @include bloc;
            max-height: 176px;
            box-sizing: border-box;
            overflow: hidden;
            @include transition;
        }

        .licon-toggle{
            z-index: 3;
            @include bloc;
            width: 32px;
            height: 32px;
            line-height: 32px;
            float: none;
            margin: 20px auto 0;
            border-radius: 50%;
            background: var(--color-beige);
            color: var(--color-noir);
            cursor: pointer;
            @include rotate(90deg);
            @include transition;

            @include hover{
                background: var(--color-noir);
                color: var(--color-beige);
            }

            &::before{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: -10px 0 0 -10px;
                background-color: currentColor;
                -webkit-mask: var(--licon-plus);
                mask: var(--licon-plus);
                @include rotate(-90deg);
                // @include transition;
            }
        }

        &.active{

            > div{
                max-height: 700px;
                overflow: auto;
            }

            .licon-toggle{
                @include rotate(-45deg);
            }
        }
    }

    #thankyou-page{

        button.close{
            display: none;
        }
    }
}