html {
    font-size: 62.5%;

    &.csstransforms3d,
    &.js,
    &.no-js{
        margin-top: 0 !important;
    }
}


html.lenis {
    height: auto;
}
.lenis.lenis-smooth {
    scroll-behavior: auto;

    [data-lenis-prevent] {
        overscroll-behavior: contain;
    }
}
.lenis.lenis-stopped {
    overflow: hidden;
}


body {
    background: var(--color-blanc);
    color: var(--color-noir);
    font-size: var(--webapp-p-font-size);
    font-family: var(--typo-font-text);
    font-weight: 400;
    line-height: var(--webapp-p-line-height);
    
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        overflow: initial !important;
    }

    &#tinymce{
        padding-top: 0;
    }

    #wpadminbar{
        display: none !important;
    }

    .row{

        > div{

        }
    }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

#page{
    display: block;
    width: 100%;
    overflow: hidden;
}

.limiter-max,
.limiter{
    @include bloc;
    margin-left: auto !important;
    margin-right: auto !important;
    @include box-sizing;

    > div{
        @include bloc;
        margin: 0;
        padding: 0;
        @include box-sizing;
    }
}

.limiter-max{
    max-width: var(--webapp-limiter-max);
    padding-left: var(--webapp-limiter-max-padding);
    padding-right: var(--webapp-limiter-max-padding);
}

.limiter{
    max-width: var(--webapp-limiter);
    padding-left: var(--webapp-limiter-padding);
    padding-right: var(--webapp-limiter-padding);
}

.relative{
    position: relative;
    display: block;
    width: 100%;
}

.pagination {
    @include bloc;
    float: left;
    margin: 0;
    padding: 0;
    text-align: center;

    a,
    span {
        display: inline-block;
        margin: 0;
        padding: 5px 15px;
        color: var(--color-main);
        @include transition(all 250ms $cubic-bezier);

        &.current {
            opacity: .5;
        }
        @include responsive(no-touch){
            &:hover {
                color: var(--color-noir);
                opacity: 1;
            }
        }

        &.prev, &.next{
            display: inline-block;
            width: auto;
            height: 50px;
            line-height: 48px;
            clear: both;
            margin: 0;
            padding: 0 15px;
            color: var(--color-noir);
            @include font-size(1.4);
            font-family: var(--typo-font-text);
            text-align: center;
            text-transform: uppercase;
            @include transition(all 250ms $cubic-bezier);

            @include responsive(no-touch){
                &:hover, &:focus {
                    color: var(--color-main);
                }
            }
        }

        &.prev .fa{
            margin: 0 7px 0 0;
        }
        &.next .fa{
            margin: 0 0 0 7px;
        }
    }
}

.align-vertical-container {
    display: table;
    width: 100%;
    height: 100%;
}
.align-vertical {
    display: table-cell;
    vertical-align: middle;
}

.fadeIn{
    opacity: 1 !important;
}


body .grecaptcha-badge,
body #status_dev,
body .otgs-development-site-front-end,
body #wp-admin-bar-otgs-wpml-development{
    display: none !important;
    visibility: hidden !important;
}


