.layout-pre-footer{

}


.layout-footer{

}


.layout-footer-top{


}


.footer-box-container{
    @include bloc;
    float: left;
    margin: 0;
    padding: 0;

    .footer-box{
        @include bloc;
        width: auto;
        float: left;
        margin: 20px 0 0;
        padding: 20px 0 0;

        @include responsive(at-sm){
            min-height: 170px;
        }

        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: var(--color-beige-dark);
        }

        &.-no-border{
            &::before{
                display: none;
            }
        }

        &:first-child{
            @include responsive(at-md){
                margin: 0;
                padding: 0;

                &::before{
                    display: none;
                }
            }
        }
    }
}


.bt-menu-footer{
    @include bloc;
    float: left;
    margin: 0;
    padding: 0;
}


.bt-scrolltop{
    position: fixed;
    bottom: 20px;
    right: 20px;
}


$copyrightSize: 26px;
$copyrightAlign: -13px;

.bt-copyright{
    @include inlinebloc;
    height: $copyrightSize;
    line-height: $copyrightSize;
    width: auto;
    padding: 0 0 0 36px;
    font-size: 12px;
    color: var(--color-noir);
    opacity: 1;

    svg{
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        width: $copyrightSize;
        height: $copyrightSize;
        margin: $copyrightAlign 0 0 0;
    }
}
