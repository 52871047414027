body#checkout{

    .fancybox-wrap{

        .fancybox-skin{
            max-width: 1080px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}