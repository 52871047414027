body{


    .price-sr{
        display: none;
        @include font-size(1.0);
        font-family: var(--typo-font-text);

        @include responsive(mobile){
            @include font-size(0.7);
        }
    }


    .info-pro{
        display: none !important;
    }

    .for-pro,
    .for-pro-inline{
        display: none !important;
    }

    .is-visible-client,
    .is-visible-pro{
        display: none !important;
    }

    &.customer-pro{
        // PRO is logged

        &::after{
            content: "";
            @include transition;
        }

        .for-pro{
            display: block !important;
        }
        .for-pro-inline{
            display: inline !important;
        }

        .cardbox-checkout-recap .card.cart-summary{
            padding-bottom: 50px;
        }

        &.show-pro{

            .no-pro{
                display: none !important;
            }

            .is-visible-pro{
                display: block !important;
            }
        }

        &.show-client{

            &::after{
                @include blocAbsolute(9999);
                position: fixed;
                @include box-shadow(inset 0 0 0 5px var(--color-main-active));
                @include pointer-events(none);
            }

            .only-pro{
                display: none !important;
            }

            .is-visible-client{
                display: block !important;
            }
        }
    }


    // =========================================================================
    // PRO interrupteur
    // =========================================================================
    #clientPriceTrigger{
        z-index: 9999;
        position: fixed;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 44px;
        height: 44px;
        cursor: default;
    }

    .client-price{
        @include bloc;
        position: absolute;
        top: -20px;
        color: var(--color-noir);
        @include font-size(2.1);
        font-family: var(--typo-font-text);

        @include responsive(mobile-tablet){
            @include font-size(1.8);
        }
    }

    &.customer-pro .price-sr{
        display: inline;
    }

    .product-miniature{

        .client-price{
        }
    }


    .product-line-grid{

        &.has-customization{

            .price-sr{

                .hidden{
                    display: inline !important;
                    visibility: visible !important;
                    margin-left: 2px;
                }
            }
        }
    }

    // =========================================================================
    // Devis actions
    // =========================================================================
    #js-product-list-filters{

        .opartDevisCartToQuotationLink{
            display: none !important;
            visibility: hidden !important;
        }
    }

    .bt-devis-create{
        position: absolute;
        bottom: 15px;
        left: 0;
        display: block;
        width: 100%;
        text-align: center;

        .btn-inline{
            padding-left: 25px;

            .fa{
                left: 0;
                margin: 0;
                padding: 0;
            }
        }
    }


    // =========================================================================
    // Devis list
    // =========================================================================
    .devis-list{

        li{

        }
    }


    // =========================================================================
    // Devis création
    // =========================================================================
    &#module-opartdevis-createquotation{


    }

    #opartDevisForm{
        @include bloc;
        margin: 40px 0 0;

        .BC_title{

        }

        .devis-table-wrapper{
            @include bloc;

            @include responsive(mobile){
                overflow: auto;

                .devis-table{
                    width: 700px !important;
                }
            }

            .devis-table{
                float: left;
                margin: 40px 0 0;

                thead{
                    background: var(--color-beige);
                    color: var(--color-noir);
                }
            }
        }

        .card,
        .cart_navigation{
            @include bloc;
            float: left;
            margin: 40px 0 0;
        }
    }
}