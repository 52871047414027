.bt-socials{


    a{
        color: var(--color-main);

        .licon{
            @include inlinebloc;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 50%;
            background: var(--color-main);
            color: var(--color-blanc);
            @include transition;
        }

        .text{
            @include inlinebloc;
            @include transition;
        }

        @include responsive(no-touch){
            &:hover{
                color: var(--color-main);

                .licon{
                    background: var(--color-main);
                }
            }
        }
    }
}