body .alert-danger,
body .alert{
    z-index: 99999;
    position: fixed !important;
    bottom: 15px;
    left: 50%;
    width: 460px;
    margin: 0 0 0 -230px !important;
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 0;
    line-height: 1.25;
    text-align: left;
    border-radius: 5px;
    border: 1px solid var(--color-noir) !important;
    background: var(--color-blanc);
    @include box-shadow(0 3px 6px rgba(0,0,0,0.15));

    @include responsive(mobile){
        left: 4%;
        width: 92%;
        margin: 0 !important;
    }

    &::after{
        content: "";
        z-index: 3;
        position: absolute;
        top: 3px;
        right: 3px;
        display: inline-block;
        width: 32px;
        height: 32px;
        background: transparent url('../images/licon-close.svg') no-repeat center center;
        cursor: pointer;
    }

    ul{
        @include bloc;
        margin: 0;
        padding: 0;
    }

    li{
        list-style: none;
        @include bloc;
    }

    a{
        color: inherit !important;
        text-decoration: underline !important;
    }

    &.lucyan-product-gift-alert{
        padding-left: 100px;

        .licon.licon-cadeau{
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 105px;
            height: 105px;
            background: transparent url('../images/licon-cadeau.svg') no-repeat center center;
            @include background-size(50px auto);
        }

        .licon.licon-close{
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}


body#contact .contact-form .alert-success{
    position: relative !important;
    bottom: inherit;
    left: inherit;
    width: 100%;
    max-width: 100%;
    margin: 0 !important;
    @include box-shadow(none);
}


body .growl.growl-notice{
    z-index: 9999999;
    position: fixed;
    bottom: 15px;
    left: 50%;
    width: 460px;
    margin: 0 0 0 -230px !important;
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 0;
    line-height: 1.25;
    text-align: left;
    border-radius: 5px;
    border: 1px solid var(--color-noir) !important;
    background: var(--color-noir);
    color: var(--color-blanc);
    @include box-shadow(0 3px 6px rgba(0,0,0,0.15));
    opacity: 1 !important;
    cursor: pointer;

    @include responsive(mobile){
        left: 4%;
        width: 92%;
        margin: 0 !important;
    }

    .growl-close{
        pointer-events: none;
    }
}