body{


    &#cart,
    &#checkout{
        padding-top: 0 !important;

        .bt-head{
            top: 0 !important;
        }

        .bt-section{
            min-height: 100vh;
            padding: 100px 0 100px;
            overflow: auto;

            @include responsive(mobile){
                height: auto;
                min-height: 100vh;
            }
        }

        .bt-footer{
            /*position: absolute;
            bottom: 0;*/
        }
    }

    &#cart{


        .cart-container{


            .cart-item{

                @include responsive(mobile){
                    border-bottom: 1px solid rgba(0,0,0,.07);
                }
            }
        }
    }
}


.tunnel-step{
    @include bloc;
    float: left;
    margin: 0;
    padding: 0;

    .tunnel-content{
        display: none;
    }

    &.-current{

        .tunnel-content{
            display: block;
        }
    }
}


.tunnel-title{
    @include bloc;
    float: left;
    margin: 0;
    padding: 15px 0;
    cursor: pointer;

    .tunnel-number,
    .tunnel-check{
        position: absolute;
        top: 10px;
        left: -45px;
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 50%;
        overflow: hidden;
        color: var(--color-blanc);
        @include font-size(1.4);
        text-align: center;
        @include transition;
    }

    .step-edit{

    }
}

.tunnel-content{
    @include bloc;
    padding: 25px 0 0;

    .modal{
        display: none;
    }
}

.tunnel-continue{
    z-index: 5;
    @include bloc;
}



.identity,
.identity-logout{

    a{
        color: var(--color-main);

        &:hover{
            color: var(--color-main);
        }
    }
}



.tunnel-adresse-container{


    .tunnel-adresse-content{

        .indicator{
            display: none;
        }

        &.-shipping{

            .indicator{
                display: block;
            }
        }
        &.-invoice{

            .indicator{
                display: block;
            }
        }
    }

    .tunnel-adresse-differente{
        @include bloc;
    }
}



.tunnel-adresse-selector{

    .col-6{
        margin: 0 0 30px;
    }

    .address-item{
        opacity: 0.5;

        address{
            font-style: normal;
        }

        &:hover{
            opacity: 1;
            cursor: pointer;
        }
        &.selected{
            background: var(--color-beige);
            opacity: 1;
            color: var(--color-noir);
            @include box-shadow-selector(var(--color-blanc), var(--color-noir));

            .radio-block .licon-check{
                opacity: 1;
            }
        }
    }
}


.delivery-options-list{
    > .form-fields{
        > *{
            @include bloc;
            float: left;
        }
    }
    
    .carrier-extra-content{
        padding: 0 15px;
    }
}

.tunnel-delivery-options {
    @include bloc;
    background: var(--color-beige);
    margin: 0 0 15px;
    padding: 5px 0;

    .delivery-option{
        @include bloc;
        margin: 0;
        padding: 5px 15px;

        > label{
            @include bloc;

            > .row{
                display: flex;
                flex-wrap: nowrap;
                margin-right: 0;
                margin-left: 0;

                > div{
                    padding-right: 0;
                    padding-left: 0;
                    flex: 0 0 40px;
                    max-width: 40px;

                    &:last-child{
                        flex: 1 1 auto !important;
                        max-width: 100% !important;
                    }
                }
            }
        }

        .carrier-title{
            @include bloc;

            &.has-icon{
                padding-left: 55px;

                img{
                    position: absolute;
                    top: 17px;
                    left: 0;
                    display: block;
                    width: 40px;
                    margin: -20px 0 0;
                }
            }
        }
    }
}



.tunnel-payment-options {
    @include bloc;
    margin: 0 0 15px;
    padding: 0;

    .title{
        margin: 0 0 15px;
    }

    .payment-option,
    .payment-option-detail{
        @include bloc;
        margin: 0;
    }
    .payment-option{
        padding: 20px 15px 15px;
        background: var(--color-beige);
    }
    .payment-option-detail{
        background: var(--color-beige-light);

        section,
        .additional-information{
            padding: 10px 0;
        }
    }

    .optionpay-wrapper{
        @include bloc;

        &.opartdevis{
            display: none !important;
            visibility: hidden !important;
        }
    }

    .optionpay-item{
        @include bloc;
        padding-left: 30px;

        .optionpay-logo{
        }
    }
}

/*.ps-hidden{
    display: none !important;
    visibility: hidden !important;
}*/

#conditions-to-approve{

    ul {
        margin: 0;
        padding: 0;

        li{
            list-style: none;
            margin: 0 0 10px;
            padding: 0;
        }
    }
}



#payment-confirmation{


    .js-alert-payment-conditions{
        display: none !important;
        visibility: hidden !important;
        max-width: 490px;
        margin: 15px auto 0;
    }
}


#order-summary-content{


    .tunnel-recap{
        @include bloc;
        margin: 0 0 25px;

        .h4, .h5{
            margin: 0 0 15px;
        }

        .card{
            padding: 5px;
        }
    }

    .tunnel-recap-header{
        margin-top: 25px;
    }
}



#order-items,
#order-details{
    @include bloc;
    margin: 0 0 25px;
    padding: 15px;
    background: var(--color-beige-light);

    &::before,
    &::after{
        content: "";
        display: block;
        position: absolute;
        top: -16px;
        left: 0;
        width: 100%;
        height: 16px;
        background: -webkit-linear-gradient(transparent 0,transparent 0),
            -webkit-linear-gradient(135deg,--color-beige 33.33%,transparent 33.33%),
            -webkit-linear-gradient(45deg,--color-beige 33.33%,transparent 33.33%);
        background-size: 0 100%, 16px 16px, 16px 16px !important;
    }
    &::after{
        top: auto;
        bottom: -16px;
        @include rotate(180deg);
    }

    a{
        color: var(--color-noir);
    }

    table {
        width: 100%;

        tr {
            height: 30px;

            td:last-child {
                text-align: right;
            }
        }
    }

    .order-confirmation-table{

        hr{

            @include responsive(mobile){
                display: none;
            }
        }

        .order-line{
            margin-bottom: 15px;

            @include responsive(mobile){
                padding-bottom: 15px;
                border-bottom: 1px solid rgba(0,0,0,.16);
            }
        }

        .order-details{

            @include responsive(mobile){
                margin-bottom: 15px;
            }
        }
    }
}



.tunnel-confirmation{
    @include bloc;
    margin-bottom: 40px;

    #order-items{
        margin-bottom: 40px;
    }
}


#checkout #pay-with-payment-option-1-form{
    display: none !important;
}