// =============================================================================
// Gsap
// =============================================================================
[gsap]{
    @include responsive(mobile-tablet){
        translate: none !important;
        rotate: none !important;
        scale: none !important;
        opacity: 1 !important;
        -webkit-transform: none !important;
        transform: none !important;
    }
}

// =============================================================================
// Lucyan Animate
// =============================================================================
.has-animate{

    &.fadeInY,
    &.fadeInUp{
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-transition: opacity .5s ease, transform 1s ease, -webkit-transform 1s ease;
        transition: opacity .5s ease, transform 1s ease, -webkit-transform 1s ease;
    }
    &.fadeInX,
    &.fadeInSide{
        opacity: 0;
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        -webkit-transition: opacity .5s ease, -webkit-transform 1s ease;
        -webkit-transition: opacity .5s ease, transform 1s ease, -webkit-transform 1s ease;
        transition: opacity .5s ease, transform 1s ease, -webkit-transform 1s ease;
    }

    &.fadeCurtain{
        background: transparent;

        &::after{
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 0;
            height: 500px;
            background: var(--color-main2);
            -webkit-transition: width 1s ease;
            transition: width 1s ease;
        }
    }

    &.line{
        @include bloc;
        overflow: hidden;

        .line-text{
            @include bloc;
            opacity: 0;
            @include transition(all 500ms ease);
            @include translate(0,101%);
        }

        .line-shadow{
            @include blocAbsolute(1);
            background: var(--color-noir);
            @include transition(all 700ms ease);
            @include translate(0,101%);
        }

        &.-line-0{
            .line-text, .line-shadow{
                -webkit-transition-delay: 100ms;
                transition-delay: 100ms;
            }
        }
        &.-line-1{
            .line-text, .line-shadow{
                -webkit-transition-delay: 250ms;
                transition-delay: 250ms;
            }
        }
        &.-line-2{
            .line-text, .line-shadow{
                -webkit-transition-delay: 400ms;
                transition-delay: 400ms;
            }
        }
        &.-line-3{
            .line-text, .line-shadow{
                -webkit-transition-delay: 550ms;
                transition-delay: 550ms;
            }
        }
        &.-line-4{
            .line-text, .line-shadow{
                -webkit-transition-delay: 700ms;
                transition-delay: 700ms;
            }
        }
    }

    &.animate{

        &.fadeInY,
        &.fadeInUp{
            opacity: 1 !important;
            -webkit-transform: translateY(0) !important;
            transform: translateY(0) !important;
        }

        &.fadeInX,
        &.fadeInSide{
            opacity: 1 !important;
            -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
        }

        &.fadeCurtain{

            &::after{
                width: 100%;
            }
        }

        &.line{

            .line-text{
                opacity: 1;
                @include translate(0,0);
            }
            .line-shadow{
                @include translate(0,-101%);
            }
        }
    }
}

.-hover-jsbackground-zoomIn{

    .js-background{
        @include transition(all 1s ease-in-out);
    }

    @include hover{

        .js-background{
            @include scale(1.1,1.1);
        }
    }
}

// =============================================================================
// Lucyan Marquee
// =============================================================================
.marquee{
    z-index: 3;
    position: absolute;
    top: var(--module-marquee-top);
    left: 0;
    display: block;
    width: 100%;
    height: auto;
    @include translate(0,-50%);

    .marquee-inner {
        position: absolute;
        display: block;
        width: var(--module-marquee-width);
        overflow: hidden;
        @include animation(marquee var(--module-marquee-speed) linear infinite);

        .d-flex > div{
            @include responsive(mobile){
                max-width: 400px;
            }
        }
    }
}

@include keyframes(marquee){
    0% {
        left: 0;
    }
    100% {
        left: var(--module-marquee-translate);
    }
}