body{


    .swiper-mask{
        z-index: 1;
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;

        &.-left{
            right: 100%;
        }
        &.-right{
            left: 100%;
        }
    }

    .swiper-controls{
        z-index: 10;
        @include bloc;
        margin: 0;
        text-align: center;
    }

    // =========================================================================
    // Swiper Navigation
    // =========================================================================
    .swiper-btns-wrapper{
        position: absolute;
    }

    .swiper-button-prev,
    .swiper-button-next{
        z-index: 5;
        display: none;
        width: var(--swiper-button-width);
        height: var(--swiper-button-height);
        line-height: var(--swiper-button-height);
        margin: 0;
        padding: 0;
        border-radius: var(--swiper-button-radius);
        border: var(--swiper-button-border);
        background: var(--color-noir);
        color: var(--color-blanc);
        font-size: var(--swiper-button-font);
        text-align: center;
        opacity: 1 !important;
        @include translate(0,-50%);

        @include responsive(no-touch){
            &:hover{
                background: var(--color-main);
            }
        }

        svg{
            @include bloc;
            width: 37px;
            height: auto;
        }

        &::after{
            display: none;
        }
    }

    .swiper-button-prev{
        left: var(--swiper-button-offset);
        border-radius: calc(var(--swiper-button-height) / 2) 0 0 calc(var(--swiper-button-height) / 2);
    }
    .swiper-button-next{
        right: var(--swiper-button-offset);
        border-radius: 0 calc(var(--swiper-button-height) / 2) calc(var(--swiper-button-height) / 2) 0;
    }

    .swiper-button-disabled{
        background: #DEDEDE !important;
    }

    // Make swiper-button visible
    .-has-navigation{

        .swiper-button-prev,
        .swiper-button-next{
            display: inline-block;
        }
    }
    @include responsive(at-sm){
        .-has-navigation-sm{

            .swiper-button-prev,
            .swiper-button-next{
                display: inline-block;
            }
        }
    }
    @include responsive(at-md){
        .-has-navigation-md{

            .swiper-button-prev,
            .swiper-button-next{
                display: inline-block;
            }
        }
    }
    .-navigation-top{

        .swiper-button-prev{
            top: 45px;
            left: -60px;
        }
        .swiper-button-next{
            top: 45px;
            right: -60px;
        }
    }

    // =========================================================================
    // Swiper Pagination
    // =========================================================================
    .swiper-pagination-wrapper{
        @include bloc;
    }
    .-pagination-bottom .swiper-pagination-wrapper{
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .-pagination-vertical.-right .swiper-pagination-wrapper{
        z-index: 5;
        position: absolute;
        top: 50%;
        right: 2%;
        width: auto;
        @include translate(0,-50%);
    }


    .swiper-pagination{
        @include inlinebloc;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .swiper-pagination-bullet{
        @include inlinebloc;
        width: var(--swiper-pagination-width);
        height: var(--swiper-pagination-height);
        margin: 0 var(--swiper-pagination-margin);
        border-radius: var(--swiper-pagination-radius);
        border: 0 solid var(--color-beige);
        background: var(--color-beige);
        opacity: 1 !important;

        @include hover{
            background: var(--color-main-active);
            cursor: pointer;
        }
        &.swiper-pagination-bullet-active{
            width: var(--swiper-pagination-width-active);
            background: var(--color-main-active);
            cursor: default;
        }
    }

    .swiper-button-disable,
    .swiper-pagination-lock{
        display: none;
    }

    // no swiper-pagination
    .-no-pagination{

        .swiper-pagination{
            display: none !important;
        }
    }
    @include responsive(at-sm){
        .-no-pagination-sm{

            .swiper-pagination{
                display: none !important;
            }
        }
    }
    @include responsive(at-md){
        .-no-pagination-md{

            .swiper-pagination{
                display: none !important;
            }
        }
    }
    // make swiper-pagination vertical
    .has-pagination-vertical{
        margin: 0 !important;

        .swiper-pagination{
            z-index: 10;
            position: absolute;
            bottom: 40px;
            right: 0;

            @include responsive(at-lg){
                bottom: 100px;
            }

            .swiper-pagination-bullet{
                width: 4px;
                height: 20px;
                margin: var(--swiper-pagination-margin) 0;
                clear: both;
                float: left;
            }
        }
    }
}


.swiper-controls{

    .no-controls &{
        display: none !important;
        visibility: hidden !important;
    }

    .-controls-bottom &{
        position: absolute;
        bottom: -55px;
        left: 0;
    }
    .-controls-bottom-centered &{
        position: absolute;
        bottom: -55px;
        left: 50%;
        width: auto;
        @include translate(-50%,0);

        @include responsive(at-md){
            bottom: 30px;
        }

        .swiper-button-container{
            position: relative;
            display: inline-block;
            width: auto;
        }

        .swiper-button-prev,
        .swiper-button-next{
            position: relative;
            left: inherit;
            right: inherit;
            margin: 0;
            bottom: inherit;
            top: inherit;
            -webkit-transform: none;
            transform: none;
        }
    }

    .-controls-top &{
        position: absolute;
        top: -70px;
        display: inline-block;
        width: auto;

        .swiper-button-all{
            display: inline-block;
            float: right;
            margin-left: 15px;
        }
        .swiper-button-container{
            display: inline-block;
            float: left;

            .swiper-button-prev,
            .swiper-button-next{
                position: relative;
                left: inherit;
                right: inherit;
                float: left;
                @include translate(0,0);
            }
        }
    }
    .-controls-side &{

        .swiper-button-container{
            position: absolute;
            bottom: 20px;
            right: -10px;

            @include responsive(at-md){
                bottom: 50px;
                right: -22px;
            }

            .swiper-button-prev,
            .swiper-button-next{
                position: relative;
                left: inherit;
                right: inherit;
                float: left;
                @include translate(0,0);
            }
        }
    }
    .-left &{
        left: 0;
    }
    .-right &{
        right: 0;
    }
}


@mixin swiperDisableAutoHeight{
    .swiper-container .swiper-wrapper {
        height: 100% !important;
        align-items: inherit;
    }
}

.-disable-auto-height{
    @include swiperDisableAutoHeight;
}
.-disable-auto-height-sm{
    @include responsive(at-sm){
        @include swiperDisableAutoHeight;
    }
}
.-disable-auto-height-md{
    @include responsive(at-md){
        @include swiperDisableAutoHeight;
    }
}



.BC_swiper{


    .swiper-slide{
        border-radius: var(--webapp-border-radius);
        // overflow: hidden;

        .embed-responsive, img{
        }
    }

    .swiper-thumbs{

        .swiper-slide{
            padding: 5px;

            .embed-responsive{
                @include transition;
                -webkit-box-shadow: 0 0 0 1px var(--color-beige-light);
                box-shadow: 0 0 0 1px var(--color-beige-light);
            }

            &.swiper-slide-thumb-active{
                .embed-responsive{
                    -webkit-box-shadow: 0 0 0 5px var(--color-beige-light);
                    box-shadow: 0 0 0 5px var(--color-beige-light);
                }
            }
        }
    }

    &.swiper-home{


        .BC_swiper_content{
            margin: 20px 0 0;

            @include responsive(mobile){
                margin: -10px 0 0;
            }
        }

        @include responsive(at-md){
            .swiper-wrapper{
                -webkit-transition-delay: .5s;
                transition-delay: .5s;
            }

            .swiper-slide{

                .BC_swiper_image{
                    // @include scale(.95,.95);
                    @include transition(all .9s ease);
                }

                .BC_swiper_content{
                    max-width: 465px;
                    margin: 0;
                }

                .BC_item{
                    // opacity: 0;
                    @include translate(-15px,0);
                    @include transition(all .9s ease);
                }

                &.swiper-slide-active{

                    .BC_swiper_image{
                        // @include scale(1,1);
                        -webkit-transition-delay: .7s;
                        transition-delay: .7s;
                    }

                    .BC_item{
                        // opacity: 1;
                        @include translate(0,0);
                        -webkit-transition-delay: .5s;
                        transition-delay: .5s;

                        &.-item2{
                            -webkit-transition-delay: .7s;
                            transition-delay: .7s;

                        }
                    }
                }
            }
        }
    }
}