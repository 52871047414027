
// =============================================================================
// Cardbox
// =============================================================================
.cardbox-wrapper{
    @include bloc;
    max-width: 710px;
    float: none;
    margin: 0 auto 40px;

    &.full-width{
        max-width: 100%;
    }

    #cart &, #checkout &{
        max-width: 100%;
    }

    .cardbox-before{
        @include bloc;
        margin: 0 0 25px;

        .cardbox-side{
            @include bloc;

            @include responsive(at-sm){
                position: absolute;
                top: 3px;
                right: 0;
                display: inline-block;
                width: auto;
            }
        }
    }

    .cardbox{
        @include bloc;
        margin: 0;
        padding: 25px 15px;
        background: var(--color-beige-light);

        @include responsive(at-md){
            padding: 25px 50px;
        }

        .cardbox-title{
            margin: 0 0 25px;
        }
    }

    .cardbox-title{
        @include bloc;
        margin: 0;
        padding: 0;
    }
    h2.cardbox-title{
        // @include font-size(3);
    }
    h3.cardbox-title{
        // @include font-size(2);
    }

    .cardbox-content{
        @include bloc;

        @include responsive(at-sm){
            // padding: 0 25px;
        }
        @include responsive(at-md){
            // padding: 0 70px;
        }
    }
}


.cardbox-checkout-navigation{
    z-index: 1;
    @include bloc;
    margin: 40px 0 -1px;
    padding: 0;

    @include responsive(mobile){
        overflow: auto;
    }

    &::after{
        content: "";
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        border-bottom: 1px solid var(--color-beige-dark);

        #cart &, #checkout &{
            right: -30px;
        }
    }

    ul{

        @include responsive(mobile){
            min-width: 325px;
        }

        li{
            margin: 0;
            text-align: center;

            a{
                @include bloc;
                color: var(--color-noir);
                margin: 0;
                padding: 5px 0;
                text-align: center;
                
                @include responsive(mobile){
                    font-size: 12px;
                }

                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: var(--color-beige);
                    @include scale(0,1);
                    @include transform-origin(left top);
                    @include transition;
                }
            }
            &.active{
                a{
                    font-family: var(--typo-font-text);

                    &::after{
                        background: var(--color-noir);
                        @include scale(1,1);
                    }
                }
            }
            &.done{
                a{

                    &::after{
                        background: var(--color-noir);
                        @include scale(1,1);
                    }
                }
            }
        }
    }
}

.cardbox-checkout-content{
    @include bloc;
    padding: 15px 0;
}

.cardbox-checkout-recap{
    @include bloc;
    padding: 0;

    .cardbox-checkout-title,
    .cart-grid-checkout,
    .card.cart-summary{
        @include bloc;
        padding: 15px;
        background: var(--color-beige-light);
    }
}


.cardboxtab-wrapper{
    @include bloc;

    .cardboxtab-pane{
        @include bloc;
        display: none;

        &.active{
            display: block;
        }
    }
}

form{

    .true-false-wrapper{
        @include bloc;
        padding: 2px 0 0 48px;
        cursor: pointer;
    }

    .true-false-checkbox{


        input{
            position: absolute;
            top: 0;
            opacity: 0;
        }

        span{
            cursor: pointer;

            &::before, &::after{
                content: "";
                position: absolute;
                display: inline-block;
            }
            &::before{
                top: 4px;
                left: 4px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: var(--color-noir);
                opacity: .5;
                @include transition;
            }
            &::after{
                top: 0;
                left: 0;
                width: 38px;
                height: 24px;
                border-radius: 12px;
                border: 1px solid var(--color-noir);
            }
        }

        input:checked + span{

            &::before{
                left: 18px;
                opacity: 1;
            }
        }
    }
}
