body .modal{
    z-index: 1050;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(0,0,0,.25);

    .modal-dialog{
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        max-width: 300px;
        height: 100%;
        margin: 0;
        background: var(--color-blanc);
        @include box-shadow(0 3px 10px rgba(0,0,0,.16));
        @include transition;

        @include responsive(at-sm){
            max-width: 400px;
        }
    }

    .modal-content{
        @include bloc;
        max-width: 100%;
        height: 100%;
        border: none;
        margin: 0;
        padding: 0;
        border-radius: 0;
        overflow: auto;

        .modal-header,
        .modal-body,
        .modal-footer{
            @include bloc;
            max-width: 100%;
            float: left;
            margin: 0;
            border-radius: 0;
        }

        .modal-header{
            padding: 0 10px;
            border: none;
            background: var(--color-beige-light);
            text-align: left;

            .modal-title{
                @include bloc;
                height: 50px;
                line-height: 54px;
                margin: 0;
                padding: 0 0 0 35px;
                color: var(--color-noir);

                .licon{
                    position: absolute;
                    top: 0;
                    left: 0;
                    top: 50%;
                    margin-top: -16px;
                }
            }

            .close,
            .modal-close{
                z-index: 3;
                position: absolute;
                top: 0;
                right: 30px;
                display: inline-block;
                height: 50px;
                line-height: 50px;
                margin: 0;
                padding: 0;
                -webkit-appearance: inherit;
                appearance: inherit;

                .licon{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -10px;
                }
            }
        }
        .modal-body{
            padding: 15px;
            border: none;
        }

        .modal-separartor{
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 1px;
            height: 100%;
            background: $system-line;
        }

        .modal-footer{
            display: none;
            padding: 15px;
        }
    }

    .product-flags{
        left: initial;
        right: 0;
        padding: 0 10px;
        text-align: right;

        .product-flag.discount{
            display: none;
        }
    }

    &.fade .modal-dialog{
        @include translate(50px, 0);
    }
    &.fade.show .modal-dialog{
        -webkit-transform: none !important;
        transform: none !important;
    }
}




body#checkout{

    .modal{

        .modal-dialog{

            .modal-content{
                overflow: auto;
            }
        }
    }
}