#customer-form{

}

#guest-transformation-form{
    @include bloc;
    margin-top: 40px;
}


.bt-account-navigation{
    @include bloc;
    margin: 0 0 40px;
    padding: 15px;
    border: 1px solid $system-gris;

    .account-navigation-title{
        position: relative;
        display: inline-block !important;
        width: auto !important;
        float: left;
        margin: -30px 0 0;
        padding: 0 15px;
        background: var(--color-blanc);
        color: var(--color-main);
        @include font-size(1.8);
        font-family: var(--typo-font-text);
        @include letter-spacing(100);
        text-align: left;
        text-transform: capitalize;
    }

    .account-navigation-list{
        @include bloc;

        .menu-item{
            @include bloc;

            a{
                @include bloc;
                padding: 0 0 0 25px;
                color: var(--color-noir);
                @include font-size(1.6);

                .fa{
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    width: 20px;
                    text-align: center;
                    line-height: 24px;
                }
            }

            &.current-menu-item{

                a{
                    color: var(--color-main);
                }
            }
        }
    }
}


.bt-account-links{
    @include bloc;
}

.account-link{
    margin: 0 15px 0 0;
}


.js-customer-form{

}


.no-account{
    @include bloc;
    margin: 0 0 25px;
}

.has-account{
    @include bloc;
    margin: 0 0 25px;
}