.bt-cta{
    @include inlinebloc;

    &.search{
        
        .page-not-found &{
            float: left !important; 
        }

        .sub-form{

            @include responsive(mobile){
                z-index: -1 !important;
                position: fixed;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                padding: 120px 20px 20px;
                background: var(--color-beige-light);
                -webkit-transform: translate(0px, -200px);
                transform: translate(0px, -200px);
            }
        }
    }

    &.panier{

        .cart-products-count{
            z-index: 3;
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            width: 14px;
            height: 14px;
            line-height: 14px;
            border-radius: 50%;
            background: var(--color-main);
            color: var(--color-blanc);
            font-size: 8px;
            font-family: var(--typo-font-title);
            font-weight: var(--webapp-font-weight-bold);
            text-align: center;
        }
    }
}


.bt-social-share{
    @include bloc;
    float: left;
}


.bt-scroll-top-container{

    .bt-scroll-top{
        z-index: 100;
        position: fixed;
        bottom: 15px;
        right: 15px;
        display: inline-block;
        width: auto;
        margin-top: -50px;
        @include transition;

        @include responsive(at-1600){
            right: 50%;
            margin-right: -720px;
        }

        .is-top &{
            opacity: 0;
            bottom: -100px;
        }

        .BC_link{
            display: inline-block;
            width: auto;
            clear: both;
        }

        .btn-pill{


            .licon-svg{
                background: var(--color-blanc);
            }
        }
    }
}