.bt-section-middle{
    @include bloc;
    float: left;
    margin: 0;

    #index &{
        // margin: 0 0 40px;
    }
    #product &{
        // margin: 0 0 0;
    }

    .page-header{
        @include bloc;
        float: left;
        margin: 0 0 40px;

        .page-product &{
            margin: 0;
        }
    }
    .page-content{
        @include bloc;
        float: left;
        margin: 0 0 40px;

        .page-product &{
            margin: 0;
        }
    }
    .page-footer{
        @include bloc;
        float: left;
    }
}



.bt-brands{
    margin: 50px 0 0;

    .brand{

        .visuel{
            @include bloc;
            height: 90px;
            line-height: 90px;

            span{
                display: inline-block;
                float: none;
                vertical-align: middle;
            }

            img{
                max-width: 140px;
            }
        }

        .content{
            @include bloc;
            margin: 15px 0 0;
        }
    }
}



.bt-contact{
    @include bloc;

    .info-box{
        @include bloc;
        margin: 0 0 15px;

        .icon{
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
        }

        .data{
            @include bloc;
            padding: 0 0 0 30px;
        }
    }
}



.page-cms-7,
.page-cms-8{


    ul{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 40px -15px 0;

        li{
            list-style: none;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            margin: 0 0 30px;
            padding: 0 15px;

            @include responsive(mobile){
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                margin: 0 0 15px;
            }

            a{
                position: relative;
                display: block;
                width: 100%;
                overflow: hidden;
                @include transition;

                strong{
                    z-index: 3;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    margin: 0;
                    padding: 25px;
                    color: var(--color-noir);
                    @include font-size(2.2);
                    font-family: var(--typo-font-text);

                    &::after{
                        content: "Découvrir";
                        position: relative;
                        display: block;
                        color: var(--color-main);
                        @include font-size(1.5);
                        font-family: var(--typo-font-text);
                        letter-spacing: 0.08em;
                        text-align: left;
                        @include transition;
                    }
                }

                img{

                }

                &:hover{

                    @include responsive(at-md){
                        @include scale(1.05, 1.05);
                    }
                }
            }
        }
    }
}

#history,
#order-detail,
#identity,
#addresses,
#address,
#module-opartdevis-createquotation,
#module-nxtalmultiwishlist-list{
    
    
    .bt-newsletter,
    .bt-content-sliderbox,
    .bt-customtext{
        display: none !important;
        visibility: hidden !important;
    }
}