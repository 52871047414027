.bt-languages-switcher{
    @include inlinebloc;
    margin: 5px 25px 5px 0;

    .switcher-trigger{

    }

    .switcher-backdrop{
        @include blocAbsolute(9995);
        position: fixed;
        background: rgba(#000, .25);
        @include animateHide;
    }

    .bt-switcher-open &{

        .switcher-backdrop{
            @include animateShow;
        }
    }
}

body{


    .switcher{


        .selected{
            display: none;
        }

        .option{
            z-index: 9998;
            position: fixed;
            top: 50%;
            left: 50%;
            width: 300px;
            height: 80vh;
            max-height: 100%;
            margin: -40vh 0 0 -150px;
            padding: 30px 15px;
            border-radius: 6px;
            border: none;
            background: var(--color-noir);
            color: var(--color-blanc);
            overflow: auto;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            @include animateHide;

            @include responsive(at-sm){
                width: 740px;
                height: 300px;
                margin: -150px 0 0 -320px;
            }

            .nturl{
                @include bloc;
                height: 40px;
                line-height: 40px;
                float: left;
                vertical-align: top;
                margin: 0;
                padding: 0 15px;
                color: inherit;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                @include responsive(at-sm){
                    display: inline-block;
                    width: 33.3334%;
                }

                &:hover{
                    background: rgba(#fff, .2) !important;
                }
                &.selected{
                    background: transparent !important;
                }
            }

            &[style*="display: block"],
            &[style*="display:block"]{
                @include animateShow;
            }
        }
    }
}