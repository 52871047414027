/* *** cart structure *** */
.cart-grid{



    .cart-grid-body{

    }
    .cart-grid-right{

    }

    .card{
        @include bloc;
        float: left;
        margin: 0 0 20px;
        padding: 0; 
        border-radius: 0;
        border: none;
        background-color: transparent;   
    }

    .card-block{
        @include bloc;
        margin: 0;
        padding: 15px 0;

        > *{

        }
    }

    &.cart-grid-list{

        .card > ul{
            @include bloc;
            margin: 0;
            padding: 0; 

            li{
                list-style: none;
                @include bloc;
                margin: 0;
                padding: 15px;
                border-bottom: 1px solid $system-gris;
            }
        }
    }

    .separator{
        margin: 0;
        background: $system-line;
    }

    .cart-overview {
        @include bloc;
        margin: 0;
        padding: 15px;
    }
}



/* *** cart items *** */
.cart-items{
    @include bloc;
    margin: 0;
    padding: 0;

    li{
        list-style: none;
        @include bloc;
        margin: 0;
        padding: 15px 0;
    }
}



/* *** Customizations *** */
.product-customization-line{
    margin: 0 0 15px;
    @include font-size(1.6);

    .label{
        font-family: var(--typo-font-text);   
    }

    &[data-label="Tailles"],
    &[data-label="Couleurs"],
    &[data-label=" Couleurs"]{
        display: none !important; 
    }
}


/* *** cart summary *** */
#js-checkout-summary{

    .cart-summary-products{
        @include bloc;
    }

    .cart-summary-products-title{
        @include bloc;
        margin: 0 0 15px;
        color: var(--color-noir);
        @include font-size(1.8);
        font-family: var(--typo-font-text);
        text-align: left;
    }

    .card-block{
        @include bloc;
        margin: 0 0 15px;
        padding: 0;
    }

    #cart-summary-product-list{
        @include bloc;
        margin: 0 0 15px;
        padding: 0;

        ul{
            @include bloc;
            margin: 0;
            padding: 0;

            li{
                list-style: none;
                @include bloc;
                margin: 5px 0;
                padding: 0;
            }
        }

        .media{
            @include bloc;
            padding: 15px 0 15px 100px;
            border-bottom: 1px solid var(--color-noir);

            .media-left{
                position: absolute;
                top: 50%;
                left: 0;
                margin: -40px 0 0;
                display: block;
                width: 80px;
                height: auto;
            }

            .media-body{
                @include bloc;
                text-align: left;
            }
        }
    }
}

.cart-summary-line{
    @include bloc;
    margin: 0 0 5px;
    padding: 0;
    color: var(--color-noir);
    @include font-size(1.4);
    line-height: 1.25;

    .label{
        font-family: var(--typo-font-text);
    }
    .value{

        .cart-grid-right &{
            float: right;
        }
    }

    &#cart-subtotal-discount{
        color: var(--color-gris);
        font-style: italic;
    }
    &#cart-subtotal-shipping{
        margin-top: 20px;
    }
}

.cart-voucher{

    .promo-name{
        @include bloc;
        margin: 0 0 15px;

        .cart-summary-line{
            padding: 0 0 0 25px;

            .fa{
                position: absolute;
                top: 0;
                left: 0;
                font-size: 20px;
            }
        }
    }

    .promo-code-button{
        @include bloc;
    }

    .promo-code{


        .alert{
            display: none;
        }
    }
}

.cart-content-btn{
    @include bloc;
    margin: 30px 0 0;

    @include responsive(mobile){
        text-align: center;

        .btn-ghost{
            margin: 0 0 15px;
        }
    }
}