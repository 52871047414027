.address-item{
    @include bloc;
    margin: 0 0 15px;
    padding: 15px;
    border: 1px solid var(--color-gris);
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    opacity: 1;
    @include transition;

    .radio-block,
    .address-body{
        @include bloc;
    }

    address{
        font-style: normal;
    }
    
    .licon-check{
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        @include transition;
    }

    .address-footer{
        position: absolute;
        bottom: 5px;
        right: 5px;

        a{
            display: inline-block;
            margin: 0 0 0 5px;
            color: var(--color-noir);
            font-size: 10px;
            line-height: 1;

            span{
                position: relative;
                top: 5px;
                display: inline-block;
                margin-right: 3px;
                float: left;
            }
        }
    }

    &:hover{
        opacity: 1;
        cursor: pointer;
    }
    &.selected{
        background: var(--color-beige);
        opacity: 1;
        color: var(--color-noir);
        border-color: var(--color-noir);

        label .licon-check{
            opacity: 1;
        }
    }
}



.addresses-footer{
    @include bloc;

    &.margin{
        margin: 40px 0 0;
    }
}