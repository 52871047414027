body{

}

[class^="layout-"]{
    @include bloc;
    float: left;
}

.layout-page-section{
    @include transition;

    .bloc{

        &:first-child{

            > .limiter-max{

            }
        }

        &:last-child{

            > .limiter-max{

            }
        }
    }
}

// =============================================================================
// Layout
// =============================================================================
.bloc{
    @include bloc;
    float: left;
    margin: 0;
    padding: 0;

    > .limiter-max{
        padding: 40px 0;
    }
}
.bloc.has-bg + .bloc.has-bg{

    > .limiter-max{
    }
}

// =============================================================================
// Content
// =============================================================================
[class^="BC_"]{
    @include bloc;
    float: left;
    margin: 0;
    padding: 0;
}

.-has-padding-s{

    @include responsive(at-sm){
        padding-top: var(--webapp-space-s);
        padding-bottom: var(--webapp-space-s);
    }
}
.-has-padding-m{

    @include responsive(at-sm){
        padding-top: var(--webapp-space-m);
        padding-bottom: var(--webapp-space-m);
    }
}
.-has-padding-l{

    @include responsive(at-sm){
        padding-top: var(--webapp-space-l);
        padding-bottom: var(--webapp-space-l);
    }
}
.-has-padding-xl{

    @include responsive(at-sm){
        padding-top: var(--webapp-space-xl);
        padding-bottom: var(--webapp-space-xl);
    }
}

.BC_visuel{
    margin-bottom: 15px;
    overflow: hidden;

    @include responsive(at-md){
        margin-bottom: 0px;
    }
}

.BC_overtitle{
    margin-bottom: 15px !important;
    color: var(--color-main);
    @include font-size(1.2);
    font-family: var(--typo-font-title);
    font-weight: var(--webapp-font-weight-bold);
    @include letter-spacing(40);
    text-transform: uppercase;
}

.BC_title{
}

.BC_content{
    margin-top: 15px;

    p{
        margin: 0 0 20px;
    }
    &.p-no-margin p{
        margin: 0;
    }

    > *{

        &:first-child{
            margin-top: 0;
        }
    }

    a:not(.btn){
        color: var(--color-main);
        font-weight: var(--webapp-font-weight-bold);

        @include responsive(no-touch){
            &:hover{
                color: var(--color-main-active);
            }
        }
    }

    ul{
        margin: 20px 0 0;
        padding: 0;

        li{
            list-style: none;
            position: relative;
            padding: 0 0 0 20px;
            font-family: var(--typo-font-text);

            &::before{
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--color-main-active);
            }

            &:last-child{
                margin: 0;
            }

            strong{
                color: var(--color-main-active);
            }
        }
    }

    &.-has-list{

        ul{
            @include bloc;

            li{
                counter-increment: rowNumber;
                @include bloc;
                max-width: 100%;
                padding-left: 50px;

                @include responsive(mobile){
                    padding-right: 0;
                }
                @include responsive(at-sm){
                    padding-left: 100px;
                }

                &::before{
                    content: counter(rowNumber);
                    position: absolute;
                    top: -5px;
                    left: 0;
                    display: inline-block;
                    width: auto;
                    float: none;
                    vertical-align: middle;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 50%;
                    background: var(--color-blanc);
                    color: var(--color-noir);
                    font-size: var(--webapp-h3-font-size);
                    font-weight: var(--webapp-font-weight-bold);
                    text-align: center;

                    @include responsive(at-sm){
                        top: -10px;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                    }
                }

                strong{

                    &:first-of-type{
                        @include bloc;
                        color: var(--color-blanc);
                        font-size: var(--webapp-h3-font-size);
                        font-weight: var(--webapp-font-weight-bold);
                    }
                }
            }
        }
    }
}

.BC_link{
    margin-top: 15px;
}

.BC_swiper,
.BC_repeater,
.BC_form{
    margin-top: 15px;
}


.miniature{
    @include bloc;
    @include transition;

    [class^="miniature-"]{
        @include transition;
    }
}


.detail{
    @include bloc;
}


.js-background{

}


body .embed-responsive.embed-responsive-noparallax,
body .embed-responsive.embed-responsive-parallax{

    @include responsive(mobile){

        .js-background{
            height: 100% !important;
            top: 0% !important;
        }
    }
}

body .page-cms,
.js-modal-content.-modal-terms-and-conditions{

    h2:not(.BC_title){
        margin: 60px 0 20px;
    }
    h3:not(.BC_title){
        margin: 40px 0 20px;
    }

    h2:not(.BC_title) + h3:not(.BC_title){
        margin-top: 0
    }
}

body .bloc-panier{

    @include responsive(mobile){
        > .limiter-max > div  > .limiter{
            padding-left: 0;
            padding-right: 0;
        }
    }
}