.header-nav #gsr{
    display: none !important;
    visibility: hidden !important;
}



body{


    .fancybox-inner{
        width: auto !important;
        margin: 0 auto !important;
    }


    // =========================================================================
    // Affichage des notes
    // =========================================================================
    #gsr-review-list{
        z-index: 5;
        position: absolute;
        display: inline-block;
        width: auto !important;

        @include responsive(mobile){
            position: relative;
            display: block;
            width: 100% !important;
            float: left;
            text-align: left !important;
        }

        .gsr-text-center{
            @include bloc;
            text-align: left !important;
        }

        .gsr-review-count-padding-1611{
            display: none !important;
        }

        .bg-white{
            background: transparent !important;
        }
    }

    &#category #gsr-review-list{

        @include responsive(mobile){
            display: none !important;
        }

        @include responsive(at-sm){
            top: 5px;
            left: 10px;
        }
    }

    &#product #gsr-review-list{

        @include responsive(at-sm){
            top: 5px;
            left: 10px;
        }
    }


    // =========================================================================
    // Ajout de note
    // =========================================================================
    #gsr.productReassurance{
        @include bloc;
        float: left;

        .block-review{
            @include bloc;
            float: left;
            background: transparent;
        }

        .block-review-item{
            @include bloc;
            float: left;
            padding: 0;
            margin: 25px 0;

            .display-distribution{
                @include bloc;
                padding: 0;
                margin: 0;
                border: none;

                > div{
                    @include bloc;
                    padding: 0;
                    margin: 0;
                }
            }
        }

        .rating-wrapper{
            @include bloc;
            height: 25px;
            line-height: 25px;
            float: left;
        }

        .bg-white{
            background: transparent !important;
        }
    }

    // =========================================================================
    // Popup de note
    // =========================================================================
    .fancybox-container.fancyReviews{

        .fancybox-bg{
            opacity: 1 !important;
            background: rgba(0,0,0,0.5) !important;
        }

        .fancybox-navigation{
            display: none !important;
        }

        #gsr{
            max-width: 620px !important;
            padding: 20px !important;
            background: #fff !important;
        }

        .fancybox-button.fancybox-close-small{
            display: none !important;
        }
    }
}