.bt-cta-keytext{
    @include bloc;
    text-align: var(--module-keytext-textalign);

    @include responsive(mobile){
        padding: 0;
    }

    .cta-keytext-wrapper{
        @include bloc;
        height: var(--module-keytext-height);
        overflow: hidden;
    }

    .cta-keytext-inner{
        @include bloc;
        height: calc(var(--module-keytext-height) * 2);
        @include animation(ctaKeyText 8s linear infinite);
    }

    .key-text{
        @include bloc;
        height: var(--module-keytext-height);
        line-height: var(--module-keytext-height);
        
        color: var(--color-blanc);
        font-size: 10px;
        font-weight: var(--webapp-font-weight-medium);

        > span{
            @include inlinebloc;
            line-height: 1.25;
        }
    }
}


@include keyframes(ctaKeyText){
    0%{
        top: 0;
    }
    45%{
        top: 0;
    }
    50%{
        top: -var(--module-keytext-height);
    }
    95%{
        top: -var(--module-keytext-height);
    }
    100%{
        top: 0;
    }
}