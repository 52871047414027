.page-maintenance{
    @include bloc;
    padding: 0 20px;

    .logo{
        @include bloc;

        img{
            @include bloc;
            max-width: 100%;
            height: auto;
        }
    }
}