.js-readmore-container{


    .js-readmore-wrapper{
        @include bloc;
        max-height: 0;
        overflow: hidden;
        @include transition(all .7s ease-out);
    }

    > .btn-inline{

    }

    &.active{

        .js-readmore-wrapper{
            max-height: 1000px;
            overflow: auto;
            @include transition(all .7s ease-in);
        }

        > .btn-inline{

        }
    }
}

.collapse{
    max-height: 0;
    overflow: hidden;
    @include transition(all .7s ease-out);


    &.active,
    &.show{
        max-height: 500px;
        overflow: auto;
        @include transition(all .7s ease-in);
    }
}

.dropdown{
    @include inlinebloc;

    button{
        @include bloc;

        @include responsive(no-touch){
            &:hover{
                i{
                    @include translate(0,3px);
                }
            }
        }
    }

    &.show{
        button{
            i{
                @include rotate(-180deg);
                &.licon-wish{
                    @include rotate(0deg);
                }
            }
        }

        .dropdown-menu{
            max-height: 500px;
            overflow: auto;
            border: none;
            -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.16);
            box-shadow: 0 3px 9px rgba(0,0,0,.16);
        }
    }
}

.dropdown-menu{
    z-index: 99;
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    margin-right: 100%;
    margin: 5px 0 0;
    padding: 0;
    border-radius: 6px;
    border: none;
    background: var(--color-blanc);
    max-height: 0;
    overflow: hidden;
    @include transition(all .7s ease);

    ul{
        @include bloc;
        margin: 0;
        padding: 7px;

        li{
            list-style: none;
            @include bloc;
            margin: 0 0 5px;
            padding: 0;
            border-bottom: 1px solid rgba(0, 0, 0, .25) !important;
            text-align: left;

            &:last-child{
                margin: 0;
                border-bottom: none !important;
            }

            > *{
                @include bloc;
                margin: 0;
                padding: 2px 0;
                color: var(--color-noir);
                @include font-size(1.4);
                font-family: var(--typo-font-text);
                text-align: left;
            }
        }
    }

    .dropdown-item{
        &.added-to-list{

            .licon{
                -webkit-mask: var(--licon-heart-full);
                mask: var(--licon-heart-full);
            }

            @include hover{
                .licon{
                    -webkit-mask: var(--licon-heart-remove);
                    mask: var(--licon-heart-remove);
                }
            }
        }
    }
}

.postcard{


    .postcard-visuel{
        @include bloc;
    }

    .postcard-content{
        @include blocAbsolute;
        margin: 0;
        text-align: center;
        @include transition;
    }

    .postcard-inner{
        @include bloc;

        @include responsive(at-sm){
            opacity: 0;
            @include translate(0,15px);
            @include transition;
        }
    }

    .postcard-background{
        @include blocAbsolute;
        background: var(--color-noir);
        opacity: .5;
        @include transition;

        @include responsive(at-sm){
            opacity: 0;
        }
    }

    a[href="javascript:;"]{
        cursor: default !important;
    }

    @include responsive(no-touch){
        &:hover{

            .postcard-inner{
                opacity: 1;
                @include translate(0,0);
                @include transition(all .4s .25s ease);
            }
            .postcard-background{
                opacity: .5;
            }
        }
    }
}


.question{
    @include bloc;
    border-bottom: 1px solid var(--color-noir);

    [class*="BC_"]{
        margin: 0;
    }

    .question-trigger{
        @include bloc;
        padding: 25px 0;


        .fa{
            z-index: 3;
            position: absolute;
            top: 50%;
            right: 20px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin: -20px -20px 0 0;
            font-size: 24px;
            text-align: center;
            @include transition;
        }
    }
    .question-content{
        @include bloc;
        max-height: 0;
        overflow: hidden;
        @include transition;
    }

    &.active{

        .question-trigger{

            .fa{
                @include rotate(-180deg);
            }
        }
        .question-content{
            max-height: 500px;
            padding: 0 0 20px;
            overflow: auto;
        }
    }
}


.onglets-container{
    @include bloc;
    float: left;

    .onglets-navigation{

        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: transparent;
        }
    }

    .pin-spacer{
        width: 0 !important;

        .onglets-navigation{

            &[style*="position:fixed"],
            &[style*="position: fixed"]{

                &::after{
                    background: var(--color-noir);
                }
            }
        }
    }
}

.onglets-navigation{
    @include bloc;
    float: left;

    ul{
        margin-left: -20px;
        margin-right: -20px;
    }

    li{
        padding-left: 20px;
        padding-right: 20px;

        a{
            @include bloc;
            float: left;
            color: var(--color-main);

            .title, .content{
                color: currentColor;
            }

            &::after{
                content: "";
                @include bloc;
                width: 0;
                height: 1px;
                @include transition;
            }
        }

        @include responsive(no-touch){
            &:hover a{
                color: var(--color-main-active);

                &::after{
                }
            }
        }
        &.open-this a{
            color: var(--color-main-active);

            &::after{
                width: 100%;
            }
        }
    }
}

.onglets-wrapper{
    @include bloc;
    float: left;
}

.onglet{
    @include bloc;
    float: left;
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &.open-this{
        position: relative;
        opacity: 1;
        visibility: inherit;
    }
}


.acf-map-container{
    @include bloc;
    height: 0;
    float: left;
    padding: 0 0 100% 0;

    @include responsive(at-md){
        padding: 0 0 47.7777777% 0;
    }

    .acf-map{
        @include blocAbsolute;

        @include responsive(at-sm){
            left: -30%;
            width: 130%;
        }
    }

    .acf-map-infos{
        @include bloc;

        @include responsive(at-sm){
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }

        .map-info{
            @include bloc;
            @include animateHide;

            @include responsive(at-sm){
                max-width: 392px;
                float: right;
            }

            &.open-this{
                @include animateShow;
            }
        }
    }
}


.short-lire-container{
    @include transition;

    .short-open{
        display: inline-block;
    }
    .short-close{
        display: none;
    }

    .short-lire{
        max-height: 0;
        overflow: hidden;
        @include transition;

        /*p:empty{
            display: none;
        }*/
    }

    &.is-reading{

        .short-open{
            display: none;
        }
        .short-close{
            display: inline-block;
        }

        .short-lire{
            max-height: 700px;
            overflow: auto;
        }
    }


    // Disable
    &.mobile{
        @include responsive(mobile){
            .short-open,
            .short-close{
                display: none !important;
            }
            .short-lire{
                max-height: 100% !important;
                overflow: initial !important;
            }
        }
    }
    &.tablet{
        @include responsive(tablet){
            .short-open,
            .short-close{
                display: none !important;
            }
            .short-lire{
                max-height: 100% !important;
                overflow: initial !important;
            }
        }
    }
    &.laptop{
        // Disable on mobile && at laptop
        @include responsive(laptop){
            .short-open,
            .short-close{
                display: none !important;
            }
            .short-lire{
                max-height: 100% !important;
                overflow: initial !important;
            }
        }
    }
    &.mdpi{
        // Disable on mobile && at laptop
        @include responsive(mdpi){
            .short-open,
            .short-close{
                display: none !important;
            }
            .short-lire{
                max-height: 100% !important;
                overflow: initial !important;
            }
        }
    }

    // Disable at screen
    &.at-sm{
        @include responsive(at-sm){
            .short-open,
            .short-close{
                display: none !important;
            }
            .short-lire{
                max-height: 100% !important;
                overflow: initial !important;
            }
        }
    }
    &.at-md{
        // Disable on mobile && at laptop
        @include responsive(at-md){
            .short-open,
            .short-close{
                display: none !important;
            }
            .short-lire{
                max-height: 100% !important;
                overflow: initial !important;
            }
        }
    }
    &.at-lg{
        // Disable on mobile && at laptop
        @include responsive(at-lg){
            .short-open,
            .short-close{
                display: none !important;
            }
            .short-lire{
                max-height: 100% !important;
                overflow: initial !important;
            }
        }
    }
}


.-has-menu-indicator{
    @include inlinebloc;
    border-radius: 25px;
    background: var(--color-beige);
    overflow: hidden;

    li{
        z-index: 3;
        @include inlinebloc;
        margin: 0;
        padding: 0;

        a{
            @include bloc;
            min-width: 130px;
            height: 50px;
            line-height: 50px;
            padding: 0 25px;
            border-radius: 25px;
            border: none;
            color: var(--color-noir);
            font-size: 14px;
            font-weight: var(--webapp-font-weight-semibold);
            text-align: center;
            text-transform: uppercase;
            opacity: .5;
            -webkit-transition-delay: 250ms;
            transition-delay: 250ms;
        }

        @include responsive(no-touch){
            &:hover{
                > a{
                    color: var(--color-blanc);
                    opacity: 1;
                }
            }
        }

        &.active,
        &.open-this{

            > a{
                background: var(--color-main-active);
                color: var(--color-blanc);
                opacity: 1;
            }
        }
    }

    .indicator{
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 0;
        height: 100%;
        border-radius: 25px;
        border: none;
        background: var(--color-main-active);
        @include transition;
    }
}


.gallery-thumb-wrapper{
    @include bloc;
    float: left;

    .gallery-image{
        @include bloc;
        float: left;
        @include transition(all 500ms ease);
    }

    .gallery-curtain{
        @include blocAbsolute;
    }

    .gallery-thumbs{
        @include bloc;
        float: left;

        a{
            @include bloc;
            float: left;
            border: 1px solid var(--color-gris-light);

            @include responsive(no-touch){
                &:hover{
                    border-color: var(--color-main-active);
                }
            }
            &.active{
                border-color: var(--color-main-active);
            }
        }
    }
}


.toolbox-wrapper{
    @include bloc;
    float: left;

    .toolbox-trigger{
        @include inlinebloc;
        height: 38px;
        line-height: 38px;

        .circle{
            @include inlinebloc;
            width: 38px;
            height: 38px;
            line-height: 38px;
        }

        .text{
            @include inlinebloc;
            line-height: 1.25;
        }

        @include responsive(no-touch){
            &:hover{
                opacity: .85;
            }
        }
    }

    .toolbox{
        @include inlinebloc;
        max-height: 0;
        overflow: hidden;
    }

    &.active{

        .toolbox{
            max-height: 500px;
            overflow: auto;
        }
    }
}