// =============================================================================
// CATEGORIES Filters 
// =============================================================================
#search_filters{
    @include bloc;
}



#search_filter_controls{
    @include bloc;


}

@include responsive(mobile-tablet){

    body.bt-categories-filter-open{
        overflow: hidden !important;
    }
}

.bt-categories-filter-trigger{
    z-index: 49;
    position: fixed;
    bottom: 15px;
    left: 0;
    display: none;
    width: 110px;
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: var(--color-noir);
    overflow: hidden;
    color: var(--color-blanc) !important;
    text-align: center;
    @include transition;

    .licon{
        float: none;
        vertical-align: middle;
        display: none;
    }

    .text-open{
        display: inline;
    }
    .text-close{
        display: none;
    }

    @include responsive(mobile-tablet){
        display: block;

        .bt-categories-filter-open &{
            background: var(--color-noir);

            .text-open{
                display: none;
            }
            .text-close{
                display: inline;
            }
        }
    }
}

.bt-categories-filter{
    @include blocAbsolute(47);
    position: fixed;
    top: 0;
    left: -100%;
    max-width: 280px;
    margin: 0;
    padding: 0;
    background: var(--color-noir);
    @include transition(all .7s ease-in-out);

    .categories-filter-scroll{
        @include bloc;
        height: 100%;
        margin: 0;
        padding: 80px 15px 80px;
        overflow: auto;
    }

    .limiter{
        max-width: 100% !important;
        height: 100%;
        padding: 0 !important;
        overflow: auto;
    }

    .licon{
        float: none;
        vertical-align: middle;
    }

    .bt-categories-filter-open &{
        left: 0;
    }

    @include responsive(at-md){
        position: relative;
        left: 0 !important;
        max-width: 100%;
        height: auto;

        .categories-filter-scroll{
            padding: 30px 0;
        }

        &::before{
            content: "";
            z-index: 0;
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            // width: 1000px;
            width: calc(100% + 40px);
            max-width: 1000px;
            height: 100%;
            background: var(--color-noir);
        }
    }

    .facet-filterby{
        @include bloc;
        margin: 0 0 40px;
    }

    .facet{
        @include bloc;
        margin: 0 0 20px;
        padding: 0;

        @include responsive(at-md){
            padding: 0 10px 0 0;
        }
        @include responsive(at-xl){
            padding: 0 40px 0 0;
        }

        &::after{
            content: "";
            @include bloc;
            border-bottom: 1px solid var(--color-gris-dark);
        }

        .facet-title.trigger{
            position: relative;
            display: block !important;
            width: 100% !important;
            margin: 0 0 20px;
            padding: 0 0;
            cursor: pointer !important;
            overflow: visible !important;
            @include transition;

            .text {
                @include bloc;
                width: 100% !important;
                margin: 0;
                padding: 0;
                color: var(--color-blanc);
                @include font-size(1.4);
                font-family: var(--typo-font-text);
                font-weight: var(--webapp-font-weight-semibold);
                @include letter-spacing(20);
                text-align: left;
            }

            .num{
                position: absolute;
                top: 50%;
                right: 50px;
                display: inline-block;
                @include translate(0, -50%);
                color: var(--color-blanc);
                @include font-size(1.4);
                font-family: var(--typo-font-text);
                font-weight: var(--webapp-font-weight-regular);
            }

            &::after{
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                display: inline-block;
                width: 20px;
                height: 20px;
                -webkit-transform: translate(0,-50%);
                transform: translate(0,-50%);
                @include licon(--licon-caret, 20px, var(--color-blanc));
                @include transition;
            }

            .add,
            .remove,
            .collapse-icons{
                display: none !important;
                visibility: hidden !important;
            }

            @include hover{
                &::after{
                }
            }

            &.collapsed{

                &::after{
                    -webkit-transform: translate(0,-50%) rotate(-180deg);
                    transform: translate(0,-50%) rotate(-180deg);
                }
            }
        }

        .facet-list{
            @include bloc;
            // height: 0;
            margin: 0;
            padding: 0;
            max-height: 0;
            overflow: hidden;
            // @include transition(all .7s linear);

            li{
                @include bloc;
                margin: 10px 0;
                padding: 0;

                .facet-label{
                    @include bloc;
                    margin: 0;
                    padding: 0 0 0 30px;

                    .custom-checkbox,
                    .custom-radio{
                    }
                    .color{
                        margin-left: 3px !important;
                    }

                    a{
                        display: inline-block;
                        width: auto !important;
                        float: none;
                        vertical-align: middle;
                        text-align: left;
                        margin: 0 0 0 5px;
                        padding: 0;
                        color: var(--color-blanc);
                        font-size: var(--webapp-p-font-size);
                        font-family: var(--typo-font-text);
                    }

                    &.active{

                    }
                }
            }

            /*&.collapsing,
            &.show{
                max-height: 320px;
                padding-bottom: 20px;
                overflow: inherit;
            }*/
        }

        .faceted-slider{

            li{
                padding: 0 20px 0 6px;

                p{
                    color: var(--color-blanc);
                    font-size: var(--typo-font-text);
                }
            }
        }
    }

    .facet-clear{

        @include responsive(mobile-tablet){
            position: fixed;
            bottom: 24px;
            left: 160px;
            display: inline-block;
            @include animateHide(400ms);

            .bt-categories-filter-open &{
                @include animateShow(400ms);
            }
        }

        @include responsive(at-md){
            z-index: 5;
            position: relative;
            top: 67px;
            right: -100px;
            display: inline-block;
        }

        .btn-inline{
            font-size: 8px;

            .licon{
                position: relative;
                top: 0;
            }
        }
    }
}

.bt-categories-filter-overlay{
    @include blocAbsolute(45);
    position: fixed;
    top: 0;
    left: -100%;
    display: none;
    margin: 0;
    padding: 0;
    background: rgba(0,0,0,.25);
    @include transition(all .7s ease-in-out);

    @include responsive(mobile-tablet){
        display: block;

        .bt-categories-filter-open &{
            left: 0;
        }
    }
}


body .ui-slider{
    @include bloc;
    height: auto !important;
    border: none;
    margin: 0;
    padding: 10px 0;
    border-radius: 0;
    background: transparent !important;
    font-size: var(--webapp-p-font-size);
    color: var(--color-blanc);
    box-sizing: border-box;

    .ui-slider-range {
        z-index: 1;
        position: relative;
        top: 0;
        display: block;
        width: 100%;
        height: 3px;
        margin: 0;
        padding: 0;
        float: left;
        border: none;
        border-radius: 1.5px;
        background-color: var(--color-blanc) !important;
        box-sizing: border-box;
    }

    .ui-slider-handle {
        z-index: 2;
        position: absolute;
        top: 50% !important;
        width: 10px !important;
        height: 10px !important;
        margin: -5px 0 0 0 !important;
        border-radius: 5px !important;
        background: var(--color-noir) !important;
        cursor: ew-resize;
        outline: 0;
        @include box-shadow(0px 0px 0 6px var(--color-blanc) !important);
    }
}
