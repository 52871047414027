// =============================================================================
// Product Containers
// =============================================================================
#js-product-list-header{
    @include bloc;
    float: left;
}

#products{
    @include bloc;
    float: left;

    > div{
        @include bloc;
        float: left;
    }

    .product_list_top{

    }

    .product_list_active_filters{

    }

    .product_list{

    }

    .product_list_bottom{

    }
}


// =============================================================================
// Product Listing Container
// =============================================================================
#js-product-list{

    .products{

        > .row{
            margin-left: -10px;
            margin-right: -10px;

            &::before,
            &::after{
                @include bloc;
                clear: both;
            }

            > div{
                margin: 0 0 50px;
                padding-left: 10px;
                padding-right: 10px;

                @include responsive(at-sm){
                }

                @include responsive(at-lg){
                    margin: 0 0 70px;
                }
            }
        }
    }
}


// =============================================================================
// Product Content
// =============================================================================
.product-title{
    @include bloc;
    min-height: 42px;
    color: var(--color-noir);
    @include font-size(1.4);
    font-family: var(--typo-font-text);
    font-weight: var(--webapp-font-weight-semibold);

    a{
        @include bloc;
        color: inherit !important;
        font-size: inherit !important;
        font-family: inherit !important;
        letter-spacing: inherit !important;
    }

    .product-miniature &{
        text-align: center;
    }
}

.product-description-short{
    @include bloc;
    @include font-size(1.4);
    font-family: var(--typo-font-text);

    .product-miniature &{
        text-align: center;
    }

    em{
        color: var(--color-beige-dark);
    }

    img{
        display: inline-block;
        width: 50px;
        max-width: 100%;
        height: auto;
        float: none;
        vertical-align: middle;
    }
}


// =============================================================================
// Product thumbnails
// =============================================================================
.product-images{
    @include bloc;
    margin: 0 0 20px;

    .embed-responsive{
        border-radius: 6px;
        overflow: hidden;
    }

    .product-link{
        @include blocAbsolute(3);
    }

    .product-thumbnail{
        z-index: 1;
        @include bloc;

        &.-hover{
            @include blocAbsolute(2);
            opacity: 0;
            @include transition;
        }
    }

    @include hover{
        .product-thumbnail{
            &.-hover{
                opacity: 1;
            }
        }
    }
}


// =============================================================================
// Product price
// =============================================================================
.product-discount,
.product-price,
.product-price-and-shipping{
    @include bloc;
    margin: 0 0 40px;
    padding: 0;

    .product-miniature &{
        text-align: center;
    }

    .current-price,
    .price,
    .regular-price{
        @include bloc;
        color: var(--color-noir);
        @include font-size(1.6);
        font-family: var(--typo-font-title);
        font-weight: var(--webapp-font-weight-semibold);

        .product-single-detail &{
            @include font-size(2.9);
            // color: #A8A8A8;
        }
    }

    // SI PRIX RÉDUIT
    .regular-price{
        // PRIX BARRÉ
        @include font-size(1.4);
        color: var(--color-gris);
        text-decoration: line-through;
    }

    .regular-price ~ .current-price,
    .regular-price ~ .price,
    &.has-discount .current-price,
    &.has-discount .price{
        // NOUVEAU PRIX
    }

    // DISCOUNT
    .discount{
        display: none;
    }
}
.product-price-and-shipping{
    margin: 15px 0 0;
}

.product-line-offer{
    position: relative;
    display: inline-block !important;
    width: auto;
    margin: 0 5px 5px 0;
    padding: 3px 7px !important;
    border-radius: 3px;
    border: 1px solid #ff9a52 !important;
    background: #ff9a52 !important;
    color: #FFFFFF !important;
    font-size: 14px !important;
}


// =============================================================================
// Product Flags
// =============================================================================
.product-flags{
    z-index: 7;
    position: absolute;
    top: 10px;
    left: 10px;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-align: right;
    pointer-events: none;
    @include translate(0,-50%);

    .product-miniature &{
    }

    #product &{
    }

    li{
        list-style: none;
    }

    .product-flag{
        display: inline-block;
        width: 42px;
        height: 42px;
        line-height: 42px;
        margin: 0 0 5px;
        border-radius: 50%;
        background: var(--color-blanc);
        color: var(--color-noir);
        @include font-size(1.4);
        font-family: var(--typo-font-text);
        text-align: center;
        text-indent: 50px;
        white-space: nowrap;
        overflow: hidden;

        &.on-sale{
            display: none;
        }
        &.new{
            display: none;
        }
        &.discount{
            background: $system-rouge;
        }
        &.out_of_stock{
            display: none;
        }
    }
}


// =============================================================================
// Product Reviews
// =============================================================================
.product-reviews{
    @include bloc;
    margin: 0 0 15px;
}


// =============================================================================
// Product Miniature
// =============================================================================
.product-miniature{
    @include bloc;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: left;
    border-radius: 8px;
    border: 1px solid transparent;
    @include transition;

    .product-add-btn{
        @include transition;

        @include responsive(at-md){
            opacity: 0;
            @include translate(0,10px);
        }

        .btn{
            @include responsive(mobile-tablet){
                height: 32px !important;
                line-height: 30px !important;
                padding: 0 15px;
            }
        }
    }

    @include hover{
        border-color: var(--color-beige);

        &:hover{
            .product-add-btn{
                opacity: 1;
                @include translate(0,-15px);
            }
        }
    }
}


// =============================================================================
// Product Detail page
// =============================================================================
.product-single-detail{
    @include bloc;
    float: left;

    .page-header h1{
    }

    .product-description-short,
    .product-information,
    .product-actions{
        @include bloc;
        float: left;
    }

    .BC_gallery_slider{

        .swiper-button-prev,
        .swiper-button-next {
            margin-bottom: 5px !important;
        }
    }

    .product-quantities{

        > .d-flex{

            svg{
                position: relative;
                display: block;
                width: 24px;
                max-width: 100%;
                height: auto;
            }
        }
    }

    .add-to-cart-or-refresh{

        .product-quantities{
            display: none;
        }
    }
}

.product-single-infos{
    @include bloc;
    float: left;
}

.product-wrapper-images{
    @include bloc;
    float: left;
}

.product-wrapper-content{
    @include bloc;
    float: left;
}

.product-wrapper-background{
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: -140px;
    bottom: -50px;
    display: block;
}

.product-wrapper-extra{
    @include bloc;
    float: left;
}

.product-images-container{
    @include bloc;

    .product-cover{
        @include bloc;
        margin: 0 0 -1px;
        padding: 0;
    }

    .images-container{
        @include bloc;
        height: 100%;
        padding: 0 30px 0 0;

        @include responsive(mobile){
            padding: 0;
        }

        .swiper-controls{
            top: inherit;
            bottom: 0;
        }
    }
}

#product .fancybox-container{

    .fancybox-bg{
        background: var(--color-blanc);
        opacity: 1;
    }

    .fancybox-button--zoom,
    .fancybox-button--play{
        display: none !important;
        visibility: hidden !important;
    }

    .fancybox-navigation .fancybox-button,
    .fancybox-toolbar{
        opacity: 1 !important;
        visibility: visible !important;
    }

    .fancybox-button--close{
        top: 5px;
        right: 5px;
        background: var(--color-noir);
    }
    .fancybox-navigation .fancybox-button{

        div{
            background: var(--color-noir);
        }
    }
}


.product-description.long-description{

    p, h2, h3, h4, span{
        margin-left: inherit !important;
        font: inherit !important;
        font-size: inherit !important;
        text-indent: inherit !important;
    }
}


// =============================================================================
// Product Variants
// =============================================================================
.product-variants{

    .product-variants-item{
        @include bloc;
        margin: 0 0 20px;
    }

    select{
        margin-top: 0;

        @include responsive(at-md){
            margin-top: -12px;
        }
    }

    ul{
        @include bloc;
        margin: 0;
        padding: 0;

        li{
            @include bloc;
            float: left;
            margin: 0 0 8px;

            @include responsive(mobile){
                margin: 0 0 10px;
                font-size: 14px;
            }

            .checkbox-label{
                @include responsive(mobile-tablet){
                    display: block;
                    padding: 0 0 0 30px;
                }
            }
        }
    }

    .groupeTypeRadio{
        @include bloc;

        li{
            @include inlinebloc;
            margin: 0;
        }

        .custom-radio-label{
            @include inlinebloc;
            margin: 0 8px 8px 0;
            padding: 0;

            .custom-radio{
                position: relative !important;
                top: 0 !important;
                @include translate(0,0);
            }

            input{
                position: absolute;
                opacity: 0;
            }

            .ps-shown-by-js{
                display: none !important;
                visibility: hidden !important;
            }

            .radio-label{
                @include inlinebloc;
                height: 32px;
                line-height: 32px;
                padding: 0 21px;
                border-radius: 16px;
                border: 1px solid var(--color-beige);
                background: var(--color-blanc);
                color: var(--color-noir);
                font-weight: var(--webapp-font-weight-semibold);

                &::after{
                    display: none !important;
                    visibility: hidden !important;
                }
            }

            input:checked + .radio-label{
                border-color: var(--color-noir);
                background: var(--color-noir);
                color: var(--color-blanc);
            }
        }
    }

    @include responsive(at-md){

        .box{
            @include bloc;

            > ul{
                @include bloc;
            }
        }

        .groupeTypeColor{

            li{
                position: relative;
                display: inline-block;
                width: auto;
                float: left;
                margin: 0 10px 5px 0;

                .custom-radio-label,
                .custom-checkbox-label{
                    padding: 0;
                }

                .custom-radio,
                .custom-checkbox{
                    @include bloc;
                    top: 0 !important;
                    @include translate(0,0);
                }

                .checkbox-label{
                    z-index: 3;
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    display: inline-block;
                    width: auto;
                    height: 22px;
                    line-height: 20px;
                    padding: 0 7px;
                    margin: 0 0 5px;
                    border-radius: 4px;
                    border: 1px solid #EAE9E7;
                    background: var(--color-blanc);
                    color: var(--color-noir);
                    @include font-size(1.4);
                    text-align: center;
                    @include transition;
                    @include translate(-50%,0);
                    @include pointer-events(none);
                    white-space: nowrap;
                    opacity: 0;

                    &::after{
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin: 0 0 0 -10px;
                        display: none;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 10px 0 10px;
                        border-color: #EAE9E7 transparent transparent transparent;
                    }
                }

                &:hover{

                    .checkbox-label{
                        opacity: 1;
                    }
                }
            }
        }
    }
}


#product-details{


    .name{
        @include font-size(1.3);
        font-family: var(--typo-font-text) !important;
        font-weight: normal !important;
    }
    .btn-inline,
    .value{
        @include font-size(1.4);
        font-family: var(--typo-font-text);
        font-weight: normal !important;
    }

    .product-variants{


        .box > .box-title,
        .box > ul{
            @include bloc;
            top: 0;
            padding: 0;
        }

        .box > ul{


            li{
                width: 60px;
                text-align: center;
                margin: 0 5px 5px 0;

                .custom-radio-label,
                .custom-checkbox-label{
                    padding: 0;
                }

                .custom-radio,
                .custom-checkbox{
                    position: relative;
                    top: 0;

                    span{
                        width: 40px;
                        height: 40px;
                        margin: 0 auto;
                    }

                    input:checked + span{
                        @include box-shadow(none !important);
                    }
                }

                .checkbox-label{
                    z-index: 3;
                    @include bloc;
                    bottom: 0;
                    left: 0;
                    padding: 0;
                    border: none;
                    background: transparent;
                    opacity: 1;
                    @include font-size(1.4);
                    font-family: var(--typo-font-text);
                    line-height: 1.1;
                    @include translate(0,0);
                    white-space: normal;

                    @include responsive(mobile-tablet){
                        min-height: 32px;
                        @include font-size(1.0);
                    }

                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
}


// =============================================================================
// Product Discount
// =============================================================================
.product-discounts{

    .table-product-discounts{
        position: relative;
        width: 100%;

        thead, tbody{
            width: 100%;
        }

        th, td{
            padding: 5px 0;
        }

        th{
            color: var(--color-noir);
        }
    }
}


// =============================================================================
// Product Add To Cart
// =============================================================================
.product-add-to-cart-wrapper{
    @include bloc;
    height: auto;

    &.-banner{
        z-index: 99;
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 0 !important;

        > .limiter-max{
            border-top: 1px solid var(--color-gris-dark);
        }
    }
}

.product-add-to-cart{
    @include bloc;
    float: none;

    .product-quantity{
        @include bloc;

        .qty{

            .control-label{
                position: absolute;
                top: -22px;
            }
        }
    }
}


// =============================================================================
// Product TouchSpin
// =============================================================================
.js-cart-line-product-quantity,
#quantity_wanted{
    display: inline-block !important;
    width: 128px !important;
    min-width: 1px !important;
    height: 42px;
    line-height: 42px;
    margin: 0 !important;
    padding: 0 0 !important;
    border-radius: 0 !important;
    border: none;
    background: var(--color-blanc);
    color: var(--color-noir);
    font-size: 15px;
    font-size: 1.5rem;
    text-align: center !important;
    padding-left: 10px;
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
    appearance: none !important;

    .dp_customisation_product &{
        background: var(--color-noir);
        color: var(--color-blanc);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.input-group.bootstrap-touchspin {
    @include inlinebloc;
    margin: 0 10px 0 0;
    padding: 0;

    @include responsive(at-md){
        margin: 0 30px 0 0;
    }

    .input-group-btn-vertical {
        position: static;
        display: block;
        width: inherit;
        white-space: initial;
        vertical-align: middle;

        .btn{
            z-index: 3;
            position: absolute;
            top: 0;
            width: 42px;
            min-width: 1px;
            height: 42px;
            line-height: 42px;
            margin: 0;
            padding: 0;

            .text{
                display: none;
                visibility: hidden;
            }

            &::before{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: currentColor;
                @include translate(-50%,-50%);
            }

            &.bootstrap-touchspin-up{
                right: 0;
                border: 2px solid var(--color-noir);
                background-color: var(--color-noir);
                color: var(--color-blanc);

                .dp_customisation_product &{
                    border: 2px solid var(--color-blanc);
                    background-color: var(--color-blanc);
                    color: var(--color-noir);
                }

                &::before{
                    -webkit-mask: var(--licon-plus);
                    mask: var(--licon-plus);
                }

            }
            &.bootstrap-touchspin-down{
                left: 0;
                border: 2px solid var(--color-beige);
                background-color: transparent;
                color: var(--color-noir);

                .dp_customisation_product &{
                    border: 2px solid var(--color-gris);
                    background-color: transparent;
                    color: var(--color-gris);
                }

                &::before{
                    -webkit-mask: var(--licon-minus);
                    mask: var(--licon-minus);
                }
            }

            @include hover{
                border-color: var(--color-main) !important;
                background-color: var(--color-main) !important;
                color: var(--color-blanc) !important;
            }
        }
    }
}
.bootstrap-touchspin .input-group-btn-vertical .touchspin-up:after,
.bootstrap-touchspin .input-group-btn-vertical .touchspin-down:after{
    display: none !important;
    visibility: hidden !important;
}


.input-group.input-group-customization {
    display: inline-block;
    width: auto;
    padding: 0 30px;

    input{
        display: inline-block;
        width: 70px;
        min-width: 70px;
        height: 50px;
        padding: 5px 0;
        border: 1px solid var(--color-noir);
        background-color: var(--color-blanc);
        color: var(--color-noir);
        @include font-size(1.5);
        text-align: center !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        @include pointer-events(none);
    }
}


.product-availability{
    @include bloc;
    margin: 10px 0 0;
}

.product-additional-info{

}


// =============================================================================
// Product Cart lines
// =============================================================================
.product-line-info{
    @include bloc;
    margin: 0 0 5px;
    padding: 0;
    color: var(--color-noir);
    @include font-size(1.4);
    line-height: 1.25;

    .label{
        font-family: var(--typo-font-text);
    }
    .value{

    }

    a{
        color: var(--color-noir);
        @include font-size(1.4);

        &:hover{
            color: var(--color-main);
        }
    }
}

.product-line-remove{
    display: inline-block;
    float: right;

    a{
        display: inline-block;
        width: 24px;
        height: 24px;
        float: right;
        color: var(--color-noir);
        text-align: center;
    }

    .ndk-rowcustomization{
        display: none;
    }
}