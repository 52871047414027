//THEME PRINCIPAL
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;
$legacy-support-for-ie: true !default;
$legacy-support-for-mozilla: true !default;
$print-stylesheet: true !default;

@mixin responsive($responsiveSize) {
    @if $responsiveSize == mobile {
        @media screen and (max-width: 767px) { @content; }
    }
    @else if $responsiveSize == mobile-xs {
        @media screen and (max-width: 374px) { @content; }
    }
    @else if $responsiveSize == mobile-xl {
        @media screen and (min-width: 375px) and (max-width: 767px) { @content; }
    }
    @else if $responsiveSize == tablet {
        @media screen and (min-width: 768px) and (max-width: 1024px) { @content; }
    }
    @else if $responsiveSize == tablet-xs {
        @media screen and (min-width: 768px) and (max-width: 991px) { @content; }
    }
    @else if $responsiveSize == tablet-xl {
        @media screen and (min-width: 992px) and (max-width: 1024px) { @content; }
    }
    @else if $responsiveSize == laptop {
        @media screen and (min-width: 1025px) and (max-width: 1279px) { @content; }
    }
    @else if $responsiveSize == mdpi {
        @media screen and (min-width: 1280px) and (max-width: 1439px) { @content; }
    }
    @else if $responsiveSize == hdpi {
        @media screen and (min-width: 1440px) and (max-width: 1679px) { @content; }
    }

    @else if $responsiveSize == mobile-tablet-xs {
        @media screen and (max-width: 991px) { @content; }
    }
    @else if $responsiveSize == mobile-tablet {
        @media screen and (max-width: 1024px) { @content; }
    }
    @else if $responsiveSize == tablet-laptop {
        @media screen and (min-width: 768px) and (max-width: 1279px) { @content; }
    }
    @else if $responsiveSize == mobile-tablet-laptop {
        @media screen and (max-width: 1279px) { @content; }
    }

    @else if $responsiveSize == at-mobile {
        @media screen and (min-width: 320px) { @content; }
    }
    @else if $responsiveSize == at-mobile-xl {
        @media screen and (min-width: 375px) { @content; }
    }
    @else if $responsiveSize == at-sm {
        @media screen and (min-width: 768px) { @content; }
    }
    @else if $responsiveSize == at-sm-xl {
        @media screen and (min-width: 992px) { @content; }
    }
    @else if $responsiveSize == at-md {
        @media screen and (min-width: 1025px) { @content; }
    }
    @else if $responsiveSize == at-lg {
        @media screen and (min-width: 1280px) { @content; }
    }
    @else if $responsiveSize == at-xl {
        @media screen and (min-width: 1440px) { @content; }
    }
    @else if $responsiveSize == at-1600 {
        @media screen and (min-width: 1600px) { @content; }
    }
    @else if $responsiveSize == at-1920 {
        @media screen and (min-width: 1920px) { @content; }
    }
    @else if $responsiveSize == at-2560 {
        @media screen and (min-width: 2560px) { @content; }
    }

    @else if $responsiveSize == no-touch {
        @media screen and (min-width: 1025px) { @content; }
    }
}

@mixin print(){
    @media print{
        @content;
    }
}

@mixin hover(){
    @include responsive(no-touch){
        &:hover{
            @content;
        }
    }
}

@mixin inline-block($alignment: none) {

    @if $legacy-support-for-mozilla {
        display: -moz-inline-stack;
    }

    display: inline-block;

    @if $alignment and $alignment != none {
        vertical-align: $alignment;
    }

    @if $legacy-support-for-ie {
        *vertical-align: auto;
        zoom: 1;
        *display: inline;
    }
}

@mixin font-size($sizeValue: 1.6) {
    font-size: calc($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
}

@mixin font-size-responsive($sizeValue: 1.6) {
    font-size: calc($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
    font-size: calc($sizeValue / 1.6) + vw;
}

@mixin letter-spacing($spacing) {
    letter-spacing: calc($spacing / 1000 * 1) + em;
}

@mixin rwdImg($value){
    height: auto;
    max-width: $value;
    width: auto;
}

@mixin lienHoverContent  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    background: #fff;
    opacity: 0;
    filter:alpha(opacity=0);
    filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

@mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}

@mixin rgba-background($color){
    @include filter-gradient($color, $color);
    @if $legacy-support-for-ie6 or $legacy-support-for-ie7 or $legacy-support-for-ie8 {
        background: transparent;
        // set filter as none for IE9+, because IE9+ supprot RGBa
        :root & {
            filter: none\0/IE9;}
    }
    background: $color;
}

@mixin background-size($backgroundSize: cover){
    -moz-background-size: $backgroundSize;
    -webkit-background-size: $backgroundSize;
    -o-background-size: $backgroundSize;
    -ms-background-size: $backgroundSize;
    background-size: $backgroundSize;
}

@mixin licon($name, $size: 32px, $color: var(--color-noir)){
    width: $size;
    height: $size;
    background-color: $color;
    -webkit-mask: var($name);
    mask: var($name);
    -webkit-mask-size: contain !important;
    mask-size: contain !important;
}

@mixin gradient($color1: var(--color-blanc),$color2: var(--color-beige)){
    background: $color1;
    background: -moz-linear-gradient(left, $color1 0%, $color2 100%);
    background: -webkit-linear-gradient(left, $color1 0%, $color2 100%);
    background: linear-gradient(to right, $color1 0%, $color2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$color1, endColorstr=$color2, GradientType=1);
}

@mixin gradientDegre($color1: var(--color-blanc),$color2: var(--color-beige), $degre: 0deg){
    background: $color1;
    background: -moz-linear-gradient($degre, $color1 0%, $color2 100%);
    background: -webkit-linear-gradient($degre, $color1 0%, $color2 100%);
    background: linear-gradient($degre, $color1 0%, $color2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$color1, endColorstr=$color2, GradientType=1);
}

@mixin pointer-events($pointerEvents: none){
    -moz-pointer-events: $pointerEvents;
    -webkit-pointer-events: $pointerEvents;
    -o-pointer-events: $pointerEvents;
    -ms-pointer-events: $pointerEvents;
    pointer-events: $pointerEvents;
}

@mixin border-radius($border-radius: 3px){
    -moz-border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    -o-border-radius: $border-radius;
    -ms-border-radius: $border-radius;
    border-radius: $border-radius;
}

@mixin box-sizing($type: border-box) {
    -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
    -ms-box-sizing: $type;
    -o-box-sizing: $type;
    box-sizing: $type;
}

@mixin transform-origin($transformOrigin: left top){
    -moz-transform-origin: $transformOrigin;
    -webkit-transform-origin: $transformOrigin;
    -o-transform-origin: $transformOrigin;
    -ms-transform-origin: $transformOrigin;
    transform-origin: $transformOrigin;
}

@mixin rotate($rotate: 0) {
    -webkit-transform: rotate($rotate);
    -moz-transform: rotate($rotate);
    -ms-transform: rotate($rotate);
    -o-transform: rotate($rotate);
    transform: rotate($rotate);
}

@mixin rotateY($rotateY){
    -webkit-transform: rotateY($rotateY);
    -moz-transform: rotateY($rotateY);
    -ms-transform: rotateY($rotateY);
    -o-transform: rotateY($rotateY);
    transform: rotateY($rotateY);
}

@mixin box-shadow($boxShadow){
    -moz-box-shadow: $boxShadow;
    -webkit-box-shadow: $boxShadow;
    -o-box-shadow: $boxShadow;
    -ms-box-shadow: $boxShadow;
    box-shadow: $boxShadow;
}
@mixin box-shadow-selector($boxShadow1, $boxShadow2, $boxShadowWidth: 4px){
    -moz-box-shadow: 0 0 0 2px $boxShadow1, 0 0 0 $boxShadowWidth $boxShadow2;
    -webkit-box-shadow: 0 0 0 2px $boxShadow1, 0 0 0 $boxShadowWidth $boxShadow2;
    -o-box-shadow: 0 0 0 2px $boxShadow1, 0 0 0 $boxShadowWidth $boxShadow2;
    -ms-box-shadow: 0 0 0 2px $boxShadow1, 0 0 0 $boxShadowWidth $boxShadow2;
    box-shadow: 0 0 0 2px $boxShadow1, 0 0 0 $boxShadowWidth $boxShadow2;
}

@mixin transition($transition: all .5s ease-out){
    -moz-transition: $transition;
    -webkit-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    transition: $transition;
}
@mixin transition2($transition2_first, $transition2_second){
    -moz-transition: $transition2_first, $transition2_second;
    -webkit-transition: $transition2_first, $transition2_second;
    -o-transition: $transition2_first, $transition2_second;
    -ms-transition: $transition2_first, $transition2_second;
    transition: $transition2_first, $transition2_second;
}

@mixin transition-delay($transitionDelay: 0.1s){
    -moz-transition-delay: $transitionDelay;
    -webkit-transition-delay: $transitionDelay;
    -o-transition-delay: $transitionDelay;
    -ms-transition-delay: $transitionDelay;
    transition-delay: $transitionDelay;
}

@mixin animateHide($speed: 400ms){
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity $speed 0s ease, visibility $speed $speed ease;
    transition: opacity $speed 0s ease, visibility $speed $speed ease;
}
@mixin animateShow($speed: 400ms){
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s 0s ease, opacity $speed 0ms ease;
    transition: visibility 0s 0s ease, opacity $speed 0ms ease;
}

@mixin opacity($opacity){
    -moz-opacity: $opacity;
    -webkit-opacity: $opacity;
    -o-opacity: $opacity;
    -ms-opacity: $opacity;
    opacity: $opacity;
}

@mixin scale($scaleX,$scaleY){
    -moz-transform: scale($scaleX,$scaleY);
    -webkit-transform: scale($scaleX,$scaleY);
    -o-transform: scale($scaleX,$scaleY);
    -ms-transform: scale($scaleX,$scaleY);
    transform: scale($scaleX,$scaleY);
}
@mixin translate($translateX,$translateY){
    -moz-transform: translate($translateX,$translateY);
    -webkit-transform: translate($translateX,$translateY);
    -o-transform: translate($translateX,$translateY);
    -ms-transform: translate($translateX,$translateY);
    transform: translate($translateX,$translateY);
}

@mixin translate3d($translate3dX,$translate3dY,$translate3dZ){
    -webkit-transform: translate3d($translate3dX,$translate3dY,$translate3dZ);
    -moz-transform: translate3d($translate3dX,$translate3dY,$translate3dZ);
    -ms-transform: translate3d($translate3dX,$translate3dY,$translate3dZ);
    -o-transform: translate3d($translate3dX,$translate3dY,$translate3dZ);
    transform: translate3d($translate3dX,$translate3dY,$translate3dZ);
}

@mixin grayscale($grayScale: 100%){
    -webkit-filter: grayscale($grayScale);
    -moz-filter: grayscale($grayScale);
    -ms-filter: grayscale($grayScale);
    -o-filter: grayscale($grayScale);
    filter: grayscale($grayScale);
    filter: gray; /* IE 6-9 */
}

@mixin blur($blur: 0px){
    -moz-filter: blur($blur);
    -webkit-filter: blur($blur);
    -o-filter: blur($blur);
    -ms-filter: blur($blur);
    filter: blur($blur);
}

@mixin bloc(){
    position: relative;
    display: block;
    width: 100%;
}
@mixin inlinebloc(){
    position: relative;
    display: inline-block;
    width: auto;
    float: none;
    vertical-align: middle;
}
@mixin blocAbsolute($ba_index: 3){
    z-index: $ba_index;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}
@mixin blocAbsoluteToRelative(){
    position: relative;
    top: initial;
    left: initial;
    height: auto;
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} { @content;}
    @-moz-keyframes #{$animationName} { @content;}
    @-o-keyframes #{$animationName} { @content;}
    @keyframes #{$animationName} { @content;}
}

@mixin clip-polygon($topleft: 38% 0%, $topright: 100% 0%, $bottomright: 62% 100%, $bottomleft: 0% 100%){
    clip-path: polygon($topleft, $topright, $bottomright, $bottomleft);
}

@mixin embed-calc($width, $height){
    $ratio: calc(100 * $height / $width);
    height: 0;
    padding: 0 0 #{$ratio + "%"} 0;
}
